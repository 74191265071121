import React from "react";

import * as classes from "./Overview.module.css";

export const Overview = () => {
  return (
    <section className="grid">
      <TextCard title="Overview">
        <p>
          In an effort to further food science and expand the horizons of our
          tastebuds, the Coincidence team has embarked on a mission to consume
          and rate as many gummies as we can stand. These are the results so
          far.
        </p>

        <p>
          We have developed the following fine-tuned scale for evaluating each
          gummy candy:
        </p>
        <Ratings />
      </TextCard>
    </section>
  );
};

function TextCard({
  title,
  children,
  className,
}: {
  title?: string;
  children: any;
  className?: string;
}) {
  return (
    <div className={className}>
      {title && <h3>{title}</h3>}
      {children}
    </div>
  );
}

function Ratings() {
  return (
    <div className={classes.Ratings}>
      <h4 className={classes.RatingsTitle}>The Gummy Scale</h4>
      <Rating
        value={1}
        description="Abysmal, an incredibly awful gummy, digusting;
            very few gummies achieve such a low score"
      />
      <Rating
        value={2}
        description="Mediocre, not a gummy you would eat even if it
            were freely available"
      />
      <Rating
        value={3}
        description="Average, a gummy that is not bad, but not
            great; a gummy you would eat if it was available, but are unlikely
            to buy"
      />
      <Rating
        value={4}
        description="Good, a gummy that is enjoyable to eat and 
            would buy occasionally, when the mood strikes"
      />
      <Rating
        value={5}
        description="Excellent, a gummy that is delicious and you 
            would absolutely buy; a classic or favorite; an S-tier gummy"
      />
    </div>
  );
}
function Rating({
  value,
  description,
}: {
  value: number;
  description: string;
}) {
  return (
    <div className={classes.Rating}>
      <div className={classes.RatingValue}>{value}</div>
      <div className={classes.RatingDescription}>{description}</div>
    </div>
  );
}
