import { Gummy, Header } from "../sheet";
import React, { ReactElement, useEffect, useState } from "react";

import * as classes from "./DataTable.module.css";

function TableHeader({
  value,
  onClickHandler,
  reversed,
}: {
  value: string;
  onClickHandler: Function;
  reversed: boolean;
}) {
  return (
    <th>
      <span>
        {value}
        <span
          className={classes.SortOrder}
          onPointerDown={() => onClickHandler()}
        >
          {reversed ? "▼" : "▲"}
        </span>
      </span>
    </th>
  );
}

export function DataTable({
  headers,
  gummies: initialGummies,
}: {
  headers: Header[];
  gummies: Gummy[];
}) {
  const [gummies, setGummies] = useState(initialGummies);
  const [sortBy, setSortBy] = useState<string>("date");
  const [reversed, setReversed] = useState(false);

  const excludedHeaders = ["Color", "Flavor", "Shape", "Notes", "Review"];

  const headerElements: ReactElement[] = [];
  headers.forEach((header, i) => {
    if (header?.value && !excludedHeaders.includes(header.value)) {
      const { value } = header;

      const th = (
        <TableHeader
          value={value}
          onClickHandler={() => {
            if (value !== sortBy) {
              setSortBy(value);
              setReversed(reversed);
            } else {
              setReversed(!reversed);
            }
          }}
          key={`${value}-${i}`}
          reversed={sortBy === value && reversed}
        />
      );
      headerElements.push(th);
    }
  });

  useEffect(() => {
    sort(sortBy, reversed);
  }, [sortBy, reversed]);

  const trs: ReactElement[] = [];
  gummies.forEach((g, i) => {
    const tds: ReactElement[] = [];
    for (const [key, value] of Object.entries(g)) {
      // skip variants and guestName
      if (key === "variants" || key === "guestName" || key === "review")
        continue;
      const str = Array.isArray(value) ? value.join(", ") : value + "";
      const td = (
        <td key={`${g.brand?.toLowerCase().replace("s", "-")}-${key}`}>
          {str}
        </td>
      );
      tds.push(td);
    }
    trs.push(
      <tr key={`${g.brand?.toLowerCase().replace("s", "-")}-${i}`}>{tds}</tr>
    );
  });

  function sort(h: string, reversed: boolean = false) {
    if (h === undefined) return;

    const header = h.toLowerCase();

    const newGummies = [...gummies].sort((a, b) => {
      // Convert to uppercase to ignore case
      let itemA: any = (a[header as keyof Gummy] + "").toUpperCase();
      let itemB: any = (b[header as keyof Gummy] + "").toUpperCase();
      // Create date objects if sorting by date
      if (sortBy.toLowerCase() === "date") {
        itemA = new Date(itemA);
        itemB = new Date(itemB);
      }
      // Swap if reversed
      if (reversed) {
        const t = itemA;
        itemA = itemB;
        itemB = t;
      }
      if (itemA < itemB) {
        return -1;
      }
      if (itemA > itemB) {
        return 1;
      }
      return 0;
    });
    setGummies(newGummies);
  }

  return (
    <div id="dataTable" className={classes.Table}>
      <h3>Table</h3>
      <table style={{ overflowX: "auto" }}>
        <tbody style={{ width: "100%" }}>
          <tr>{headerElements}</tr>
          {trs}
        </tbody>
      </table>
    </div>
  );
}
