export const IMAGES: { [id: string]: URL } = {
  "airheads_xtremes_bites.png": new URL("./img/airheads_xtremes_bites.png?as=webp&height=165", import.meta.url),
  "airheads_xtremes_sourfuls.png": new URL("./img/airheads_xtremes_sourfuls.png?as=webp&height=165", import.meta.url),
  "albanese_gummi_bears.png": new URL("./img/albanese_gummi_bears.png?as=webp&height=165", import.meta.url),
  "albanese_gummi_worms.png": new URL("./img/albanese_gummi_worms.png?as=webp&height=165", import.meta.url),
  "albanese_peach_rings.png": new URL("./img/albanese_peach_rings.png?as=webp&height=165", import.meta.url),
  "amaguri_sweet_chestnut.png": new URL("./img/amaguri_sweet_chestnut.png?as=webp&height=165", import.meta.url),
  "amos_4d_juicy_rush.png": new URL("./img/amos_4d_juicy_rush.png?as=webp&height=165", import.meta.url),
  "annie's_organic_fruit_snack_building_blocks.png": new URL("./img/annie's_organic_fruit_snack_building_blocks.png?as=webp&height=165", import.meta.url),
  "arizona_fruit_snacks.png": new URL("./img/arizona_fruit_snacks.png?as=webp&height=165", import.meta.url),
  "bandai_wine_gummy_rose_of_versailles.png": new URL("./img/bandai_wine_gummy_rose_of_versailles.png?as=webp&height=165", import.meta.url),
  "betty_crocker_minions.png": new URL("./img/betty_crocker_minions.png?as=webp&height=165", import.meta.url),
  "black_forest_gummy_bears.png": new URL("./img/black_forest_gummy_bears.png?as=webp&height=165", import.meta.url),
  "bourbon_fettucine_gummi.png": new URL("./img/bourbon_fettucine_gummi.png?as=webp&height=165", import.meta.url),
  "bourbon_sharimoni_gummy.jpg": new URL("./img/bourbon_sharimoni_gummy.jpg?as=webp&height=165", import.meta.url),
  "bourbon_sharimoni_gummy.png": new URL("./img/bourbon_sharimoni_gummy.png?as=webp&height=165", import.meta.url),
  "candemina_super_best.png": new URL("./img/candemina_super_best.png?as=webp&height=165", import.meta.url),
  "carnaby_sour_gummy_rings.png": new URL("./img/carnaby_sour_gummy_rings.png?as=webp&height=165", import.meta.url),
  "cocoa_land_industries_lot_100.png": new URL("./img/cocoa_land_industries_lot_100.png?as=webp&height=165", import.meta.url),
  "dare_real_fruit_tropical.png": new URL("./img/dare_real_fruit_tropical.png?as=webp&height=165", import.meta.url),
  "dare_realfruit_medley.png": new URL("./img/dare_realfruit_medley.png?as=webp&height=165", import.meta.url),
  "dare_realfruit_sours_citrus_burst.png": new URL("./img/dare_realfruit_sours_citrus_burst.png?as=webp&height=165", import.meta.url),
  "dare_realfruit_sours_summerfruit.png": new URL("./img/dare_realfruit_sours_summerfruit.png?as=webp&height=165", import.meta.url),
  "dare_realfruit_superfruits.png": new URL("./img/dare_realfruit_superfruits.png?as=webp&height=165", import.meta.url),
  "essential_everyday_gummi_peach_rings.png": new URL("./img/essential_everyday_gummi_peach_rings.png?as=webp&height=165", import.meta.url),
  "ferrara_nerds_gummy_clusters.png": new URL("./img/ferrara_nerds_gummy_clusters.png?as=webp&height=165", import.meta.url),
  "ferrera_funables_fruit_snacks.png": new URL("./img/ferrera_funables_fruit_snacks.png?as=webp&height=165", import.meta.url),
  "foreign_candy_company_rips.png": new URL("./img/foreign_candy_company_rips.png?as=webp&height=165", import.meta.url),
  "gurley's_gummy_blue_sharks.png": new URL("./img/gurley's_gummy_blue_sharks.png?as=webp&height=165", import.meta.url),
  "gushers_flavor_mixers.png": new URL("./img/gushers_flavor_mixers.png?as=webp&height=165", import.meta.url),
  "gustaf's_jelly_babies.png": new URL("./img/gustaf's_jelly_babies.png?as=webp&height=165", import.meta.url),
  "gustaf's_wine_gums.png": new URL("./img/gustaf's_wine_gums.png?as=webp&height=165", import.meta.url),
  "haribo_almedudler.png": new URL("./img/haribo_almedudler.png?as=webp&height=165", import.meta.url),
  "haribo_berry_clouds.png": new URL("./img/haribo_berry_clouds.png?as=webp&height=165", import.meta.url),
  "haribo_bunte_schnecken.png": new URL("./img/haribo_bunte_schnecken.png?as=webp&height=165", import.meta.url),
  "haribo_color-rado.png": new URL("./img/haribo_color-rado.png?as=webp&height=165", import.meta.url),
  "haribo_frogs.png": new URL("./img/haribo_frogs.png?as=webp&height=165", import.meta.url),
  "haribo_fruit_salad.png": new URL("./img/haribo_fruit_salad.png?as=webp&height=165", import.meta.url),
  "haribo_fruitmania_berry.png": new URL("./img/haribo_fruitmania_berry.png?as=webp&height=165", import.meta.url),
  "haribo_funtastic_mix.png": new URL("./img/haribo_funtastic_mix.png?as=webp&height=165", import.meta.url),
  "haribo_ginger_lemon.png": new URL("./img/haribo_ginger_lemon.png?as=webp&height=165", import.meta.url),
  "haribo_goldbears.png": new URL("./img/haribo_goldbears.png?as=webp&height=165", import.meta.url),
  "haribo_goldbears_sour.png": new URL("./img/haribo_goldbears_sour.png?as=webp&height=165", import.meta.url),
  "haribo_gruene_krokodile.png": new URL("./img/haribo_gruene_krokodile.png?as=webp&height=165", import.meta.url),
  "haribo_happy_cherries.png": new URL("./img/haribo_happy_cherries.png?as=webp&height=165", import.meta.url),
  "haribo_happy_cola.png": new URL("./img/haribo_happy_cola.png?as=webp&height=165", import.meta.url),
  "haribo_hey_kakao.png": new URL("./img/haribo_hey_kakao.png?as=webp&height=165", import.meta.url),
  "haribo_kirsch-cola.png": new URL("./img/haribo_kirsch-cola.png?as=webp&height=165", import.meta.url),
  "haribo_orangina_pik.png": new URL("./img/haribo_orangina_pik.png?as=webp&height=165", import.meta.url),
  "haribo_peaches.png": new URL("./img/haribo_peaches.png?as=webp&height=165", import.meta.url),
  "haribo_pico-balla.png": new URL("./img/haribo_pico-balla.png?as=webp&height=165", import.meta.url),
  "haribo_pinkie_&_lilly.png": new URL("./img/haribo_pinkie_&_lilly.png?as=webp&height=165", import.meta.url),
  "haribo_primavera_erdbereen.png": new URL("./img/haribo_primavera_erdbereen.png?as=webp&height=165", import.meta.url),
  "haribo_sour_kicks.png": new URL("./img/haribo_sour_kicks.png?as=webp&height=165", import.meta.url),
  "haribo_sour_s'ghetti.png": new URL("./img/haribo_sour_s'ghetti.png?as=webp&height=165", import.meta.url),
  "haribo_sour_streamers.png": new URL("./img/haribo_sour_streamers.png?as=webp&height=165", import.meta.url),
  "haribo_starmix.png": new URL("./img/haribo_starmix.png?as=webp&height=165", import.meta.url),
  "haribo_super_gurken.png": new URL("./img/haribo_super_gurken.png?as=webp&height=165", import.meta.url),
  "haribo_super_mario.png": new URL("./img/haribo_super_mario.png?as=webp&height=165", import.meta.url),
  "haribo_the_smurfs.png": new URL("./img/haribo_the_smurfs.png?as=webp&height=165", import.meta.url),
  "haribo_the_smurfs_sour.png": new URL("./img/haribo_the_smurfs_sour.png?as=webp&height=165", import.meta.url),
  "haribo_twin_snakes.png": new URL("./img/haribo_twin_snakes.png?as=webp&height=165", import.meta.url),
  "haribo_watermelon.png": new URL("./img/haribo_watermelon.png?as=webp&height=165", import.meta.url),
  "haribo_wine_gums.png": new URL("./img/haribo_wine_gums.png?as=webp&height=165", import.meta.url),
  "huer_sour_brats.png": new URL("./img/huer_sour_brats.png?as=webp&height=165", import.meta.url),
  "huer_sour_peach_slices.png": new URL("./img/huer_sour_peach_slices.png?as=webp&height=165", import.meta.url),
  "ikea_lordagsgodis_sweet_and_sour_mix.png": new URL("./img/ikea_lordagsgodis_sweet_and_sour_mix.png?as=webp&height=165", import.meta.url),
  "jello_gummies.png": new URL("./img/jello_gummies.png?as=webp&height=165", import.meta.url),
  "jolly_rancher_gummies_sours.png": new URL("./img/jolly_rancher_gummies_sours.png?as=webp&height=165", import.meta.url),
  "jolly_rancher_misfit_gummies.png": new URL("./img/jolly_rancher_misfit_gummies.png?as=webp&height=165", import.meta.url),
  "kabaya_peanuts_gummy.png": new URL("./img/kabaya_peanuts_gummy.png?as=webp&height=165", import.meta.url),
  "kabaya_tough_gummy.png": new URL("./img/kabaya_tough_gummy.png?as=webp&height=165", import.meta.url),
  "kabaya_tough_gummy_dual_grape_punch.png": new URL("./img/kabaya_tough_gummy_dual_grape_punch.png?as=webp&height=165", import.meta.url),
  "kabaya_tough_gummy_neo.png": new URL("./img/kabaya_tough_gummy_neo.png?as=webp&height=165", import.meta.url),
  "kabaya_tough_gummy_orange_vibes.png": new URL("./img/kabaya_tough_gummy_orange_vibes.png?as=webp&height=165", import.meta.url),
  "kanro_pure_grape_gummy.png": new URL("./img/kanro_pure_grape_gummy.png?as=webp&height=165", import.meta.url),
  "kanro_pure_muscat_grape.png": new URL("./img/kanro_pure_muscat_grape.png?as=webp&height=165", import.meta.url),
  "kanro_pure_ring.png": new URL("./img/kanro_pure_ring.png?as=webp&height=165", import.meta.url),
  "kanro_pure_sparkling_muscat_soda.png": new URL("./img/kanro_pure_sparkling_muscat_soda.png?as=webp&height=165", import.meta.url),
  "kasugai_fruitia_fruits_gummy_candy.png": new URL("./img/kasugai_fruitia_fruits_gummy_candy.png?as=webp&height=165", import.meta.url),
  "kasugai_fruitia_gummy_candy.png": new URL("./img/kasugai_fruitia_gummy_candy.png?as=webp&height=165", import.meta.url),
  "kasugai_kiwi_gummy_candy.png": new URL("./img/kasugai_kiwi_gummy_candy.png?as=webp&height=165", import.meta.url),
  "kasugai_lipton_peach_tea_gummy.png": new URL("./img/kasugai_lipton_peach_tea_gummy.png?as=webp&height=165", import.meta.url),
  "kasugai_tsubugumi.png": new URL("./img/kasugai_tsubugumi.png?as=webp&height=165", import.meta.url),
  "kasugai_yuzu_gummy_candy.png": new URL("./img/kasugai_yuzu_gummy_candy.png?as=webp&height=165", import.meta.url),
  "kroger_gummi_bears.png": new URL("./img/kroger_gummi_bears.png?as=webp&height=165", import.meta.url),
  "kroger_juju_fish_candy.png": new URL("./img/kroger_juju_fish_candy.png?as=webp&height=165", import.meta.url),
  "kroger_orange_slices.png": new URL("./img/kroger_orange_slices.png?as=webp&height=165", import.meta.url),
  "lifesavers_collisions.png": new URL("./img/lifesavers_collisions.png?as=webp&height=165", import.meta.url),
  "lifesavers_gummies.png": new URL("./img/lifesavers_gummies.png?as=webp&height=165", import.meta.url),
  "lifesavers_gummies_neons.png": new URL("./img/lifesavers_gummies_neons.png?as=webp&height=165", import.meta.url),
  "lifesavers_gummies_sours.png": new URL("./img/lifesavers_gummies_sours.png?as=webp&height=165", import.meta.url),
  "lifesavers_gummies_wild_berries.png": new URL("./img/lifesavers_gummies_wild_berries.png?as=webp&height=165", import.meta.url),
  "lily's_gummy_bears.png": new URL("./img/lily's_gummy_bears.png?as=webp&height=165", import.meta.url),
  "little_jasmine_fruit_jelly_candies.png": new URL("./img/little_jasmine_fruit_jelly_candies.png?as=webp&height=165", import.meta.url),
  "lotte_little_fit's_big_gumi.png": new URL("./img/lotte_little_fit's_big_gumi.png?as=webp&height=165", import.meta.url),
  "maynard's_fuzzy_peach.png": new URL("./img/maynard's_fuzzy_peach.png?as=webp&height=165", import.meta.url),
  "maynard's_swedish_berries.png": new URL("./img/maynard's_swedish_berries.png?as=webp&height=165", import.meta.url),
  "maynard's_wine_gums.png": new URL("./img/maynard's_wine_gums.png?as=webp&height=165", import.meta.url),
  "meiji_boost_bites.png": new URL("./img/meiji_boost_bites.png?as=webp&height=165", import.meta.url),
  "meiji_cola_up.png": new URL("./img/meiji_cola_up.png?as=webp&height=165", import.meta.url),
  "meiji_fruit_juice_gummy.png": new URL("./img/meiji_fruit_juice_gummy.png?as=webp&height=165", import.meta.url),
  "meiji_ramune_up.png": new URL("./img/meiji_ramune_up.png?as=webp&height=165", import.meta.url),
  "meiji_yoglet.png": new URL("./img/meiji_yoglet.png?as=webp&height=165", import.meta.url),
  "mondelez_international_swedish_fish_and_friends.png": new URL("./img/mondelez_international_swedish_fish_and_friends.png?as=webp&height=165", import.meta.url),
  "mondelez_international_swedish_fish_classic.png": new URL("./img/mondelez_international_swedish_fish_classic.png?as=webp&height=165", import.meta.url),
  "mondelez_international_swedish_fish_tails.png": new URL("./img/mondelez_international_swedish_fish_tails.png?as=webp&height=165", import.meta.url),
  "nippon_eeru_melon_gummy.png": new URL("./img/nippon_eeru_melon_gummy.png?as=webp&height=165", import.meta.url),
  "nobel_chibi_sours.png": new URL("./img/nobel_chibi_sours.png?as=webp&height=165", import.meta.url),
  "nobel_kame_kame_sours.png": new URL("./img/nobel_kame_kame_sours.png?as=webp&height=165", import.meta.url),
  "nobel_otoko_ume.png": new URL("./img/nobel_otoko_ume.png?as=webp&height=165", import.meta.url),
  "okiko_shikuwasa_gumminchu.png": new URL("./img/okiko_shikuwasa_gumminchu.png?as=webp&height=165", import.meta.url),
  "peko_gummy_purple_grape.png": new URL("./img/peko_gummy_purple_grape.png?as=webp&height=165", import.meta.url),
  "promotion_in_motion_sour_jacks.png": new URL("./img/promotion_in_motion_sour_jacks.png?as=webp&height=165", import.meta.url),
  "puchao_bubbly_soda.png": new URL("./img/puchao_bubbly_soda.png?as=webp&height=165", import.meta.url),
  "red_band_wine_gums.png": new URL("./img/red_band_wine_gums.png?as=webp&height=165", import.meta.url),
  "safeway_signature_select_gummy_sharks.png": new URL("./img/safeway_signature_select_gummy_sharks.png?as=webp&height=165", import.meta.url),
  "sathers_blue_sharks.png": new URL("./img/sathers_blue_sharks.png?as=webp&height=165", import.meta.url),
  "shodoshima_olive_gummy.png": new URL("./img/shodoshima_olive_gummy.png?as=webp&height=165", import.meta.url),
  "skittles_gummies_original.png": new URL("./img/skittles_gummies_original.png?as=webp&height=165", import.meta.url),
  "skittles_gummies_wild_berry.png": new URL("./img/skittles_gummies_wild_berry.png?as=webp&height=165", import.meta.url),
  "snak_club_tajin_peach_rings.png": new URL("./img/snak_club_tajin_peach_rings.png?as=webp&height=165", import.meta.url),
  "sour_patch_kids.png": new URL("./img/sour_patch_kids.png?as=webp&height=165", import.meta.url),
  "sour_patch_kids_berries.png": new URL("./img/sour_patch_kids_berries.png?as=webp&height=165", import.meta.url),
  "sour_patch_kids_cherry.png": new URL("./img/sour_patch_kids_cherry.png?as=webp&height=165", import.meta.url),
  "sour_patch_kids_lemonade.png": new URL("./img/sour_patch_kids_lemonade.png?as=webp&height=165", import.meta.url),
  "sour_patch_kids_tropical.png": new URL("./img/sour_patch_kids_tropical.png?as=webp&height=165", import.meta.url),
  "sour_patch_peaches.png": new URL("./img/sour_patch_peaches.png?as=webp&height=165", import.meta.url),
  "sour_patch_watermelon.png": new URL("./img/sour_patch_watermelon.png?as=webp&height=165", import.meta.url),
  "sour_punch_bites.png": new URL("./img/sour_punch_bites.png?as=webp&height=165", import.meta.url),
  "sour_punch_rainbow_straws.png": new URL("./img/sour_punch_rainbow_straws.png?as=webp&height=165", import.meta.url),
  "starburst_airs.png": new URL("./img/starburst_airs.png?as=webp&height=165", import.meta.url),
  "sweet_tarts_gummies_fruity_splitz.png": new URL("./img/sweet_tarts_gummies_fruity_splitz.png?as=webp&height=165", import.meta.url),
  "tough_gummy_neo.png": new URL("./img/tough_gummy_neo.png?as=webp&height=165", import.meta.url),
  "trader_joe's_cats_and_bats.png": new URL("./img/trader_joe's_cats_and_bats.png?as=webp&height=165", import.meta.url),
  "trader_joe's_cinnadragons.png": new URL("./img/trader_joe's_cinnadragons.png?as=webp&height=165", import.meta.url),
  "trader_joe's_fruity_gummy_candies.png": new URL("./img/trader_joe's_fruity_gummy_candies.png?as=webp&height=165", import.meta.url),
  "trader_joe's_scandinavian_tidings.png": new URL("./img/trader_joe's_scandinavian_tidings.png?as=webp&height=165", import.meta.url),
  "trader_joe's_super_sour_scandinavian_swimmers.png": new URL("./img/trader_joe's_super_sour_scandinavian_swimmers.png?as=webp&height=165", import.meta.url),
  "trader_joe's_tangy_turtles.png": new URL("./img/trader_joe's_tangy_turtles.png?as=webp&height=165", import.meta.url),
  "trolli_evil_twins.png": new URL("./img/trolli_evil_twins.png?as=webp&height=165", import.meta.url),
  "trolli_sour_brite_crawlers.png": new URL("./img/trolli_sour_brite_crawlers.png?as=webp&height=165", import.meta.url),
  "trolli_sour_brite_llamas.png": new URL("./img/trolli_sour_brite_llamas.png?as=webp&height=165", import.meta.url),
  "trolli_sour_brite_octopus.png": new URL("./img/trolli_sour_brite_octopus.png?as=webp&height=165", import.meta.url),
  "trolli_sour_brite_trees.png": new URL("./img/trolli_sour_brite_trees.png?as=webp&height=165", import.meta.url),
  "trolli_sour_bursting_crawlers.png": new URL("./img/trolli_sour_bursting_crawlers.png?as=webp&height=165", import.meta.url),
  "trolli_sour_crunchy_crawlers.png": new URL("./img/trolli_sour_crunchy_crawlers.png?as=webp&height=165", import.meta.url),
  "trolli_sour_duo_crawlers.png": new URL("./img/trolli_sour_duo_crawlers.png?as=webp&height=165", import.meta.url),
  "trolli_sour_gummi_creations.png": new URL("./img/trolli_sour_gummi_creations.png?as=webp&height=165", import.meta.url),
  "trolli_sour_reindeer_poops.png": new URL("./img/trolli_sour_reindeer_poops.png?as=webp&height=165", import.meta.url),
  "trolli_sour_watermelon_sharks.png": new URL("./img/trolli_sour_watermelon_sharks.png?as=webp&height=165", import.meta.url),
  "trolli_sweet_peachie_os.png": new URL("./img/trolli_sweet_peachie_os.png?as=webp&height=165", import.meta.url),
  "trolli_sweet_squiggles.png": new URL("./img/trolli_sweet_squiggles.png?as=webp&height=165", import.meta.url),
  "twizzler_press'd.png": new URL("./img/twizzler_press'd.png?as=webp&height=165", import.meta.url),
  "twizzlers_gummies_happy_hour_peach_spritzer.png": new URL("./img/twizzlers_gummies_happy_hour_peach_spritzer.png?as=webp&height=165", import.meta.url),
  "uha_kogumi.png": new URL("./img/uha_kogumi.png?as=webp&height=165", import.meta.url),
  "uha_kororo.png": new URL("./img/uha_kororo.png?as=webp&height=165", import.meta.url),
  "uha_mizugumi.png": new URL("./img/uha_mizugumi.png?as=webp&height=165", import.meta.url),
  "uha_nagano_shine_muscat.png": new URL("./img/uha_nagano_shine_muscat.png?as=webp&height=165", import.meta.url),
  "uha_reset.png": new URL("./img/uha_reset.png?as=webp&height=165", import.meta.url),
  "uha_tsugummi_blocks.png": new URL("./img/uha_tsugummi_blocks.png?as=webp&height=165", import.meta.url),
  "vidal_sour_kiwi_slices.png": new URL("./img/vidal_sour_kiwi_slices.png?as=webp&height=165", import.meta.url),
  "warheads_galactic_mix_cubes.png": new URL("./img/warheads_galactic_mix_cubes.png?as=webp&height=165", import.meta.url),
  "warheads_wally_cubes.png": new URL("./img/warheads_wally_cubes.png?as=webp&height=165", import.meta.url),
  "welch's_fruit_snacks.png": new URL("./img/welch's_fruit_snacks.png?as=webp&height=165", import.meta.url),
  "welch's_island_fruit_snacks.png": new URL("./img/welch's_island_fruit_snacks.png?as=webp&height=165", import.meta.url),
  "werner's_raspberry_sour_belts.png": new URL("./img/werner's_raspberry_sour_belts.png?as=webp&height=165", import.meta.url),
  "werner_apple_rings.png": new URL("./img/werner_apple_rings.png?as=webp&height=165", import.meta.url),
  "werner_gummy_sharks.png": new URL("./img/werner_gummy_sharks.png?as=webp&height=165", import.meta.url),
  "yupo_ayicik.png": new URL("./img/yupo_ayicik.png?as=webp&height=165", import.meta.url),
  "yupo_supper_jeli.png": new URL("./img/yupo_supper_jeli.png?as=webp&height=165", import.meta.url),
}
