.Ratings {
  background-color: var(--gray-lightest);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 2rem;
}
.RatingsTitle {
  font-family: Sigmar, cursive;
}
.Rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--gray-lighter);
  margin: 0.25rem 0;
  padding: 0.25rem 0;
}

.Rating:last-of-type {
  border-bottom: none;
}

.RatingValue {
  font-family: Sigmar, cursive;
  font-size: 1.7rem;
  padding-right: 1rem;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.25), 1px 1px 0 rgba(255, 255, 255, 1);
}

.Rating:nth-of-type(n + 1) .RatingValue {
  color: var(--red);
}

.Rating:nth-of-type(n + 2) .RatingValue {
  color: var(--orange);
}
.Rating:nth-of-type(3n) .RatingValue {
  color: var(--pink);
}
.Rating:nth-of-type(4n) .RatingValue {
  color: var(--blue);
}
.Rating:nth-of-type(5n) .RatingValue {
  color: var(--green-dark);
}
.RatingDescription {
  padding: 0.125rem;
}
