import "chart.js/auto";
import React from "react";
import * as classes from "./Charts.module.css";

import { Radar } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js/auto";

export function RadarChart({
  labels,
  datasets,
}: {
  labels: string[];
  datasets: object[];
}) {
  const data: ChartData<"radar"> = {
    labels: labels,
    // @ts-ignore
    datasets: datasets,
  };
  const options: ChartOptions<"radar"> = {
    // responsive: false,
    scales: {
      radial: {
        beginAtZero: true,
        max: 5,
        pointLabels: {
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className={classes.Chart}>
      <Radar data={data} options={options} className={classes.Chart} />
    </div>
  );
}
