[
    {
        "title": "Haribo Happy Cherries",
        "data": "Haribo Happy Cherries? More like Haribo Mediocre Cherries! These tough, tennis ball-sized candies reek of a strong medicinal smell and taste like mildly flavored cough drops. With their unwelcome chewy texture, they'll have you wishing for an actual cherry instead. Pass!"
    },
    {
        "title": "Haribo Sour Streamers",
        "data": "Well, honey, Haribo Sour Streamers are a sad excuse for a tasty snack. Sure, they're coated in sour goodness but with their weak flavors and fruit leather fakery? Yawn! The gummy-to-sour ratio needed some work too—green didn't look so green with envy. Overall, these flat imitations gave our taste buds the blues... and reds and oranges. An edible disappointment at best!"
    },
    {
        "title": "Haribo Goldbears",
        "data": "Haribo Goldbears? More like Haribo Chewybears with their tough texture. The flavors, though mild, are the saving grace - hello strawberry green! Orange is a citrus classic, red raspberry plays it cool, lemon packs a zesty punch and pineapple keeps things tropically chill. Let's not forget blue party hat: a tentacle-shaped ode to blue raspberry – looks weird & tastes just right! So get ready for flavor shocks amidst some serious jaw workout!"
    },
    {
        "title": "Haribo Goldbears Sour",
        "data": "Haribo Goldbears Sour: a chew-tastic adventure with underwhelming sourness. Tougher than their classic counterparts, they tease your taste buds with hints of strawberry, orange, raspberry, lemon, and pineapple wrapped in sugar-coated sassiness. Unleash your inner Jay and dive into these mildly tangy treats that'll have you questioning if you're actually eating candy or just doing the cha-cha slide on Pina Colada Boulevard. 10/10 for sassy sweetness; 5/10 for pucker-up power."
    },
    {
        "title": "Haribo Sour Kicks",
        "data": "Haribo Sour Kicks: a bizarre, overly large \"shoe\" form with offensively sour flavors that'll have you cringing like drinking OJ post teeth-brushing. Hillary might think they're perfect in their weirdness but the rest of us struggle to find pleasure amongst the inconceivable grape and outrageously sour orange. For those who love an explosion of taste on their tongue, enjoy the wild ride through strawberry, raspberry, and lemon – reminiscent of a Jolly Rancher adventure. Proceed with caution; these sassy kicks aren't for everyone!"
    },
    {
        "title": "Haribo Peaches",
        "data": "Those Haribo Peaches sure smell peachy, but honey, they just don't deliver on texture. If you're into soft blobs the size of Kyle's ego and a flavor that's one step away from TJ's kettle jellies, feel free to indulge yourself in this squishy ordeal. But let me tell you: it ain't my cup of tea!  💁"
    },
    {
        "title": "Snak Club Tajin Peach Rings",
        "data": "Behold Snak Club Tajin Peach Rings: the catastrophic calamari of candy. So atrocious, even Keith and Hillary won't eat them! Visual disaster? Check. Stomach-churning smell? You betcha. Taste like dog poop on a bad day? Zach's imagination confirms it. It's a race for rock-bottom – is this a solid 1 or zero effort gone wrong? Avoid at all costs; your taste buds will thank you!"
    },
    {
        "title": "Gustaf's Wine Gums",
        "data": "Gustaf's Wine Gums, a tough (and I mean REAL tough) imported UK treat that'll give your jaw muscles a legit workout. Expect an explosion of good, yet mild flavors like weak lemon, raspberry redness and the divisive black currant. Some sassy standouts? Hillary adored the yellow and Jay couldn't get enough purple - though Zach wasn't feelin' it. Green gives you apple tenacity while white is delicately peachy. Enjoy these waxy wonders at your own risk!"
    },
    {
        "title": "Haribo Happy Cola",
        "data": "\"Haribo Happy Cola comes in big, bold bites that tantalize your senses with a strong cola scent, mysterious whiffs of peppermint, and carbonated flavor that pops with each chew. Expect a spicy kick of cinnamon and vanilla wrapped up in the ideal texture - all without shedding any tears for cavities. Cheers to this slightly medicinal but oh-so-addictive gummy treat!\""
    },
    {
        "title": "Sourpatch Watermelon ",
        "data": "Buckle up, sweeties, 'cause Sourpatch Watermelon is not here to play! Jay's personal fav brings a lusciously soft texture and sassy sourness that'll leave you hankering for more. Sure, Zach may whine about its looks, but with an unapologetically lip-smacking watermelon flavor like this one – who even cares? Give your taste buds the kick they deserve; it's time to pucker up!"
    },
    {
        "title": "Haribo Watermelon ",
        "data": "Darling, Haribo Watermelon is giving us chic rind-melon realness with its bubblegum flair. However, this diva's size screams EXTRA—like half the glam would do! Matthew and Zach adore these tasty complexities, but someone call security: a forbidden green apple gum-trespasser snuck in. Buckle up for this jumbo fruity ride!"
    },
    {
        "title": "Trolli Sour Watermelon Sharks",
        "data": "Trolli Sour Watermelon Sharks? More like a chewy watermelon bubblegum disaster! \"It's bad\" says Zach, and I have to agree. These too-big taffy imposters are coated with sass but lacking in the addictive gummy department. Pass on this shark attack, honey – we ain't got time for fake gummies!"
    },
    {
        "title": "Haribo Twin Snakes",
        "data": "Buckle up, sweet-tooths! Haribo's Twin Snakes are a sour, big and gimmicky rollercoaster that left us feeling underwhelmed. With mediocre flavors like apple/lemon or blueberry/black currant swirling together, we found ourselves preferring the sweeter snakes to their tangy counterparts. And don't even get us started on the confusing concept - do we eat 'em together or separate? Our vote: slither away from these confusing confections."
    },
    {
        "title": "Warheads Wally Cubes",
        "data": "Warheads Wally Cubes - they're the sour sleeper hit that never wakes up. With flavors as mild-mannered as a librarian on Ambien and colors that deceive (purple equals grape, not black cherry), these cubes will have your tongue ready to yawn instead of pucker. From jelly-ish orange to apple juice green and predictable blue raspberry, these wannabes couldn't even pass for baby Sour Patch Kids' cousins – it's like Warheads forgot their sass at home!"
    },
    {
        "title": "Sourpatch Kids",
        "data": "Sourpatch Kids be slayin' us with their sassy, sour-coated sassiness. Blue = blue raspberry (yas!), red = red berry (?), yellow = intense lemon realness, green = that classic lime pucker we crave so bad, and orange... honey! That's just a finger-lickin-good flavor fiesta. Each one brings its zesty party game to treat our taste buds right. So what are you waiting for? Savor the sugar rush!"
    },
    {
        "title": "Lifesavers Gummies Neons",
        "data": "Lifesavers Gummies Neons brings novelty with a handful of bizarre flavors, yet falls short on overall punch. Their chewy texture and fresh gummy bounce save the day somewhat, but really could've used a sour kick. Blue raspberry and bold berry steal the show, while others like cherry lime splash feel utterly disappointing. Loved the flashbacks to childhood memories of Hawaiian punches though! So, essentially - good try Lifesavers, but you're no neon superhero in our mouths."
    },
    {
        "title": "Trader Joe's Super Sour Scandinavian Swimmers",
        "data": "Trader Joe's Super Sour Scandinavian Swimmers? More like *Sorta* Sour. These soft, jelly-like candies boast a lot of red in the bag but don't deliver on a \"super sour\" promise. With standard flavors like berry-red, not-so-orangey orange, and pink-strawberry - it's your average sour candy affair. Though blue-huckleberry feels fancy-schmancy with an unusual name, it tastes disappointingly ordinary too. Overall: they're cute'ish and colorful—but nothing to write home about!"
    },
    {
        "title": "Kroger Orange Slices",
        "data": "\"Kroger Orange Slices: expect big, store-brand disappointment with these gross gummies. Not only do they boast a repulsive flavor that'll have your taste buds running for cover, but their texture also feels like gnawing on expired rubber bands. Save your coin and skip this catastrophe!\""
    },
    {
        "title": "Trolli Sour Brite Crawlers",
        "data": "Trolli Sour Brite Crawlers: a chewy yet mealy affair that left my tastebuds torn. The red/blue combo screamed artificial grape, while orange/green shocked with tart sourness. Don't even get me started on the cherry-lemonade madness! The coating might be funky and almost stale-like, but let's face it – we'll still reach for these sassy little worms when no one's looking. Kudos to you Trollithe Candy Makers; welcome to your opening in the world of sassiness!"
    },
    {
        "title": "Albanese Gummi Bears",
        "data": "These Albanese gummy bears delight and confuse with their soft, subtle prettiness. With a fabulously fresh chew and impressive range of flavors, they're almost too good to be true! Highlights include the juicy pineapple (jay-approved), iconic red jell-o strawberry, and mysterious grapefruit. But dear Zach is left feeling blue (& raspberry) when some flavors disappoint or are in short supply. Overall, grab these bouncy beauties if you crave fruity fun but beware - not all colors live up to their hype!"
    },
    {
        "title": "Trolli Sour Crunchy Crawlers",
        "data": "Trolli Sour Crunchy Crawlers are the lovechild of a chewy sweet tart and an underwhelming gummy, with a dried clay appearance that screams \"Memphis School reject.\" Only the red crawler (fruit punch & raspberry) offers redemption in its intense flavor. But purple (lemonade & blueberry) and yellow (blackberry & lemonade), darling, you're simply not invited to our candy soirée - let's leave the bad taste behind!"
    },
    {
        "title": "Sathers Blue Sharks",
        "data": "Sathers Blue Sharks are big marshmallow-like gummies that wreak of playdough. Their rubbery, chewy texture seems to be divisive - while Zach isn't a fan of the white part, Kyle adores these controversial lil' sea creatures. Dive in at your own risk!"
    },
    {
        "title": "Haribo Frogs",
        "data": "These Haribo Frogs ain't your average peachy treat! They are big, marshmallow-y, and rockin' that detailed moldwork. Confusion may strike as they taste like green apple or strawberry (depends who you ask), but trust me honey - these divas deserve a spot in the Haribo hall of fame. Are they Peaches' long-lost twins? That's still a mystery... *snap*"
    },
    {
        "title": "Jolly Rancher Gummies Sours",
        "data": "Oh honey, these Jolly Rancher Gummies Sours? A wild ride! Pink watermelon is a chemical dream. Green apple hits you hard like its candy cousin. Red cherry transports me back to childhood (thanks Matthew). Yellow lemon blinds my taste buds with tartness. Blue raspberry? She's the shy sister after that lemon extravaganza. Faker than their fruity claims, but Jolly Rancher nailed it if you crave sour nostalgia in gummy form!"
    },
    {
        "title": "Trader Joe's Fruity Gummy Candies",
        "data": "Trader Joe's Fruity Gummy Candies are basically the store-brand divas of candyland. With their bright colors and picture-perfect shapes, they sure know how to steal the show. Though their texture might be having an identity crisis (are you a gummy or fruit snack?), their flavors deliver the sass - blueberry twirls in purple, watermelon shimmies in red, grapefruit works it out in pink, pineapple sashays in yellow, and mango struts its stuff in orange. Get ready to serve some serious fruity realness!"
    },
    {
        "title": "Trolli Sour Bursting Crawlers",
        "data": "Trolli Sour Bursting Crawlers: a revolting rendezvous with ludicrously large, gimmicky gummies. Zach's terrified but tolerable reaction clashes with Kyle's disgust at the uneven viscera distribution. Blue? Questionable flavor but extra bursty. Red screams cherry cough syrup yet somehow pleases Kyle, while Hillary remains appalled. Pink and orange - pure mediocrity in fruity disguise! A unanimous consensus: an overall atrocious texture experience that'll make you question whether it was worth trying them at all!"
    },
    {
        "title": "Haribo Starmix",
        "data": "Oh, honey! Haribo Starmix brings the sass with its chewy variety of classic treats like Cola, bears and more. It packs a punch with ring-a-ling mystery flavors in clear/orange and red/green - whatever they are, we're diggin' it! Even if rings ain't plenty, Matthew adores this spice-packed life mixed bag. Live fabulously on the wild side with these tough-textured candies that keep us guessing (and craving!)."
    },
    {
        "title": "Haribo Funtastic Mix",
        "data": "Haribo's Funtastic Mix is a chewy, quirky alternative to classic gummies. If you like variety and surprises, it's the bag for you! With shapes ranging from butterflies to dinosaurs and all sorts of mixed-color marshmallow vehicles—say hello to sugar rush express! Don't expect your favorite robot or perfect packaging accuracy but hey, relish in the random joy this mix brings. It's like stuffing three different gummy bears into your mouth at once (yes, that interesting!) So buckle up and enjoy this sassy sweet treat!"
    },
    {
        "title": "Skittles Gummies Original",
        "data": "Honey, these Skittles Gummies Original are \"good enough,\" but let's be real – they're basically upgraded Welch's fruit snacks. Flavor? More like mild and forgettable! Sure, Matthew enjoys the small size, but most of us would rather stick to our beloved classic Skittles. A quick rundown: lemon tastes fake-sy, orange is weak-sauce, strawberry splits opinions (sorry Keith), grape divides with its extra-skittle-ness, and green apple scrapes by with a not-bad verdict. Overall? Cute attempt but next time I'll just grab that good ol' rainbow bag instead."
    },
    {
        "title": "Skittles Gummies Wild Berry",
        "data": "Mild flavors? More like old people Skittles for when your teeth are falling out! Wild Berry Skittles Gummies boast a concoction of mediocre - from strawberry mildly doing its thing, to cherry tasting like cough syrup. While Berry Punch and Raspberry make an appearance, Melon Berry saves the day (even though it's not even a real flavor). At least Hillary enjoyed them better than regular Skittle gummies. Wake me up when there's something worth sassying about!"
    },
    {
        "title": "Trolli Sour Brite Llamas",
        "data": "\"Trolli's Sour Brite Llamas are a big, coated delight. The weird form factor is divisive; some love the sugar-sour taste while others dislike the mealiness. Featuring unique flavors like yellow/green passion fruit/pineapple (even non-Trolli fans approve) and purple/orange mango berry, there’s something for everyone. Try these sour sassy treats today!\""
    },
    {
        "title": "Trader Joe's Tangy Turtles",
        "data": "Buckle up, honey, because Trader Joe's Tangy Turtles are one hot mess. These not-so-tangy seagull snacks trade marshmallow fluff for a white gummy base that takes ages to reveal any flavor. Although they sassily flaunt vibrant hues and *almost* luxurious looks, their taste falls far from fabulous – grape berry wannabe, bitter orange underachiever and a lemon lime letdown. Oh hunny no, they got stinged by the definition of \"tangy\"! So size does matter in this case - these bulky beauties aren't worth the calories!"
    },
    {
        "title": "Lifesavers Gummies",
        "data": "Lifesavers Gummies: Enticing aroma falls flat- all sass, no impact! The texture's fab but the flavors struggle. Orange takes us to a soap-scented public restroom, while cherry feels like floral cough syrup. Strawberry and watermelon play \"guess who\" in green shades, and pink barely makes an impression. But hey - if you're into artificial Jolly Rancher-esque apple vibes they've got you covered! Get ready for a whirlwind romance of smells over tastes with these sassy little gummies."
    },
    {
        "title": "Kroger Juju Fish Candy",
        "data": "Kroger Juju Fish Candy boasts bright, corn-on-the-cob-holder looks from Mexico yet sadly meets your tongue with a waxy, chapstick-like taste. Each color carries its own little disaster - cherry chapstick red and clay-like lime green, all softened by the uncanny reminiscence of Dots candies. Overall? A sassy mess that not even the most forgiving sweet tooth could excuse."
    },
    {
        "title": "Trolli Sour Gummi Creations",
        "data": "Trolli Sour Gummi Creations: a gimmicky, UFO-shaped bundle of sassy disappointment. The strangely opaque textures seem like car-warmed Play-Doh and the flavors are hit or miss—but hey, at least their peach is better than other peach rings! Strawberry scores points but green apple falls flat for Hillary (sorry girl!) and lemon feels oddly lemon curdy. Pliant yet mealy, feast your eyes on these sour coated misfortunes—just don't expect too much joy in munching them!"
    },
    {
        "title": "Jello Gummies",
        "data": "Jello Gummies? More like chewy, gimmicky nematoads! These gummy leeches are a far cry from their package's promise. The Jello logo-shaped candies may have Haribo-esque texture, but boy, do they fail on flavor – trashy strawberry (red), disappointingly generic berry blue (blue), and meh lime (green). Warning: you might find only one lone green in the mix! Ain't nobody got time for jiggle-less letdowns!"
    },
    {
        "title": "Kasugai Fruitia Gummy Candy",
        "data": "Kasugai Fruitia Gummies are the fancy, fruity candies you never knew you needed. Imported from JP and individually wrapped to savor, these subtle treats aren't meant for binge-eating. Pink Lychee gummy? Simply divine! Orange Mango? Sweetly fragrant with a fruity punch! Red Strawberry? Firm and authentic, but not as loved as that delish Mango moment. Tasteful enough for Keith's approval but still 2nd place in his heart. Trust us, darling—you'll want these sophisticated sweets at your next soirée!"
    },
    {
        "title": "Huer Sour Peach Slices",
        "data": "Oh, darling. Huer's Sour Peach Slices? More like \"Pretend-Sour-Not-Sure\"! While they've got a peachy gradient that'll make you swoon and were supposedly crafted with real fruit juice (big wink), don't expect a scrumptious sour punch. Adorable as they are, these gritty sugar-dusted slices will leave your taste buds craving tangier kicks. Hillary's new candy crush? Puh-leaze!"
    },
    {
        "title": "Essential Everyday Gummi Peach Rings",
        "data": "\"Essential Everyday Gummi Peach Rings: Sashay into City Market and snatch these big, gritty, store-brand divas off the shelf! They've got a pretty peachy gradient lewk, but don't expect that bottom yellow ring to be marshmallow. Nonetheless, their flavor is fab-u-lous even with some sass-tastic grittiness!\""
    },
    {
        "title": "Haribo Hey Kakao!",
        "data": "\"Haribo's Hey Kakao! is simply a German mess. With an import label fumbling calorie info, the overly-soft choco-mallows ain't even gummies, honey - they taste like chocolate flavored protein powder! They might be veggie-friendly but try pairing these babies with cocoa or coffee to make 'em slightly tolerable.\""
    },
    {
        "title": "Haribo Sour S'ghetti",
        "data": "Haribo Sour S'ghetti: a bizarrely delectable treat that keeps you coming back for more. With its tangy twist on gummy spaghetti, it's sure to satisfy those seeking something a bit unconventional. While the vaguely strawberry, unmistakably green apple, and pseudo-blueberry flavors may be stuck in your teeth, they'll also linger in your heart - or at least on Kyle's taste buds. And who wouldn't love snacking from an adorably weird Cthulu-esque creature?"
    },
    {
        "title": "Lifesavers Gummies Sours",
        "data": "Pucker up for Lifesavers Gummies Sours - a fragrant, gritty treat with great flavors and an oh-so-necessary sour kick. Forget those basic gummies as you taste watermelon greens and non-medicinal cherry reds all wrapped in sassy jolly rancher vibes. Sure, the bitter aftertaste may linger (seriously, is black raspberry even a thing?), but these divas of flavor outshine their sidewalk-strutting siblings while leaving your tastebuds begging for more. Conquer your cravings or bring it to a party - either way, life just got sassier!"
    },
    {
        "title": "Haribo Color-Rado",
        "data": "Haribo Color-Rado is an insane, poorly curated hodgepodge of flavors and shapes that'll leave you feeling perplexed. With everything from surprising bat wings to unknowable green frogs, there's something for everyone – except when licorice ruins the party. Black licorice isn't for everyone, Kyle (*ahem* adults only). Some gems shine through with lemony creams and yes, gummy bears! But ultimately it's a chaotic mix that leaves your taste buds unfulfilled. TL;DR: Haribo tried too hard here… but hey, maybe variety ISN'T always a good thing?"
    },
    {
        "title": "Haribo Super Gurken",
        "data": "Oh, honey! Haribo Super Gurken gummies? They're like oversized, coated cucumbers trying to pass as pickles. Between their cement-like stickiness and the vague \"red\" flavor, it's a chewy challenge of candy confusion. Imagine sinking into raspberry reds, strawberry greens, and some mild lemon yellows that Kyle just can't appreciate - but hey, at least they're vegan! Get ready for a sassy mouth-workout with these gigantic gummy oddities."
    },
    {
        "title": "Trader Joe's Scandinavian Tidings",
        "data": "Trader Joe's Scandinavian Tidings are like sassy holiday swimmer gummies, but with fewer flavors. Featuring bizarre white grape vibes and cherry wax hints in ornament form, these soft, chewy treats rock a clear/white sparkling grape star that’ll leave Jay perplexed and Zach uncertain of its champagne potential. The green apple tree won't taste pine, but you'll still question its flavor identity. Overall? Fabulously confusing festive fun!"
    },
    {
        "title": "Haribo Bunte Schnecken",
        "data": "Imported from Germany, Haribo Bunte Schnecken are a chewy, colorful confection that brings the nostalgia of an old-fashioned soda shoppe. A great delight for those who enjoy unwinding their snails and savoring subtle flavors like mild lemon and peachy passionfruit. Be warned: this ain't for joyless candy cynics (yeah, we see you Zach). Though some may find the big size less than perfect or struggle with the occasional mishaps in color consistency – where's my red? – these gimmicky gummy snails sure hit the sweet spot!"
    },
    {
        "title": "Haribo Pinkie & Lilly",
        "data": "Haribo's Pinkie & Lilly marshmallows bring a pop of vaporwave with their pink and purple hues, reminiscing Tamagotchi toys. They're inoffensively foamy with mildly flavored German/Euro gummies. While they may be big on size and tongue-like texture (thanks, Jay), Kyle loves the superior raspberry flavor – unlike its tougher, herbal blackberry cousin!"
    },
    {
        "title": "Starburst Airs",
        "data": "Starburst Airs: bizarrely fleshy-looking, disappointingly small gummies that’ll leave you longing for the OG Starburst. With opaque awkwardness and a suspicious creamsicle scent, these marshmallow wannabes try too hard to mimic classic flavors but end up just creeping us out (looking at you, orange cream). Do yourself a favor - stick to the real deal."
    },
    {
        "title": "Lifesavers Collisions",
        "data": "Lifesavers Collisions? More like a fruity hot mess. Sure, Matthew can taste the rainbow with these soft and gimmicky gummies but for the rest of us, it's just an overwhelming mouthful of confusion. Red/yellow is barely lemony, light green/blue is punch-dominant pineapple, and green/red screams watermelon Jolly Rancher (sorry cherry!). Bottom line: Stick to your regular Lifesavers if you're craving satisfaction-`cause honey, these collisions missed the mark!"
    },
    {
        "title": "Kroger Gummi Bears",
        "data": "Oh, honey! Kroger Gummi Bears are a colorful catastrophe. With their shades-wearing chewy selves giving us off-brand telepathic drama, you'd think they were auditioning for a reality show. While cherry bear struts its intense flavor and reminds us of Cherry Otter Pops, others like lime and pineapple get lost in artificial awkwardness. We're talking tougher-than-expected textures and short-lived memory blooms (or bursts) of flavors - oh my! So, who crowned these Mexican makeovers king? File THIS under \"Questions I never thought I'd ask.\""
    },
    {
        "title": "Haribo The Smurfs",
        "data": "Haribo The Smurfs are disappointingly bland, barely offering a hint of white-strawberry and red-raspberry flavors. It's like chewing on unflavored sugary gummies with hats that dominate the entire head! They might score points with Smurf-lovers like Kyle, but for Hillary and most of us, they're just meh. Stick to better blue bites, huh?"
    },
    {
        "title": "Haribo Pico-Balla",
        "data": "Oh, Haribo Pico-Balla, you colorful little tease! Boasting three colors per piece, you're like a chic nano-balla makeover. Sure, you dress in gummy glam on the outside but don't fool us; your insides are licorice-sassy without even being licorice! Rock that berry red realness and green with fruity zing. Flaunt those citrusy orange and yellow hues. So fab we'll import this fierce German defiance of gumminess. Yas queen!"
    },
    {
        "title": "Trolli Sour Brite Octopus",
        "data": "\"Step aside, Sour Brite Crawlers - Trolli's Sour Brite Octopus is here to steal the show! Rocking a tantalizing sweet and sour balance with gorgeous two-tone colors that tease your taste buds. They're larger-than-life, perfect for nibbling one tentacle at a time. Red-purple cherry-grape deliciousness? Check! Hillary's must-have orange-yellow combo bursting with citrus tanginess? You betcha! And who could forget green-blue strawberry-blue raspberry delight? Dive into Trolli's best work yet!\""
    },
    {
        "title": "Airheads Xtremes Bites",
        "data": "Well, honey, let me tell you – Airheads Xtremes Bites sure are a tease! These tough little rainbow-colored nibbles promise to be sour, but they barely pucker your lips. They taste like a fruit by the foot's red cousin and might give you aesthetics envy with their disordered colors (get it together!). If \"rainbow berry\" is your jam and color chaos doesn't deter you, then snack on! But for this sass master? Hard pass."
    },
    {
        "title": "Trolli Sweet Peachie Os",
        "data": "Trolli Sweet Peachie Os strut into the candy scene with their big, coated, and tough attitude. While they're quite good and sinfully sweet, we crave a stronger flavor punch. They seduce our senses with their chalky charm (thanks Jay) and put up a stinky front - but honey, once you taste 'em... yum! The crazy mascot on the bag? Gritty's peach ring love-child for sure. Get ready to indulge in these sassy sweets!"
    },
    {
        "title": "Albanese Peach Rings",
        "data": "Albanese Peach Rings bring a whole new level of sass with their big, boldly coated selves. Outshining the competition (looking at you, Trolli), these springy delights offer an almost-tempting creamy flavor and diligently smoother texture thanks to pectin. Be warned though: they're inconsistently stamped and so sweet they might just leave your taste buds dancing in confusion. All things considered, Albanese Peach Rings are highly tolerable - one might even say peachy...almost!"
    },
    {
        "title": "Haribo The Smurfs Sour",
        "data": "Get ready for a sassy Smurf-party in your mouth with Haribo The Smurfs Sour gummies! Though these chewy, vibrant blue creatures come in different colors, their flavor remains mysteriously unanimous. But guess what? That sour punch will make you forget the non-sour version even existed. With the right amount of grit and powerful sourness, let these consistent (albeit confusing) flavors take you on a smurf-tastic trip! Oh, and Kyle... try not to break a tooth!"
    },
    {
        "title": "Trolli Evil Twins",
        "data": "Trolli Evil Twins: a sassy, sour surprise that tastes like a scandalous lovechild of Twin Snakes and Sourpatch Kids. These chewy rebels lack the typical Trolli mealy texture and are only mildly sour - but hey, at least they ain't gritty! With titillating combinations like sweet strawberry/rowdy blue raspberry and angelic orange/mischievous lime, you can either pick sides or indulge in their sinful fusion. Overall, unexpected from Trolli but oh-so deliciously evil!"
    },
    {
        "title": "Albanese Gummi Worms",
        "data": "Albanese Gummi Worms: a tantalizing mix of pretty, chewy goodness that might leave you slightly disappointed with forced flavor combos & occasional oddities (blue raspberry oregano?!). With a great texture & well-sized worms, your taste buds embark on an adventurous journey - but beware the confusing dual flavors! Some standouts include lemon/raspberry and grapefruit/pineapple. Ideal for those seeking thrilling snacking experiences, but overall not quite living up to their Albanese hype. Proceed with caution!"
    },
    {
        "title": "Trolli Sour Brite Trees",
        "data": "Trolli Sour Brite Trees: a gimmicky yet tasty treat! These sassy lil' trees sport the usual Trolli flavors and colors but are less mealy than their wiggly worm counterparts. Plus, you'll find a delightful mix of red, yellow, orange & blue in each pack - a veritable forest of sour consistency. Pucker up for some tongue-tingling fun!"
    },
    {
        "title": "Trolli Sour Reindeer Poops",
        "data": "Trolli Sour Reindeer Poops? More like emoji-inspired gimmicks! Sure, these coated sours have a cute resemblance to the infamous poop emoji, but they're basically tree-shaped doppelgangers. Even Kyle and Jay admit they prefer their sour fix in \"poop\" form. So if you're into that sort of sass, give 'em a taste; otherwise, save your bucks - for non-Reindeer poops!"
    },
    {
        "title": "Werner's Raspberry Sour Belts",
        "data": "Werner's Raspberry Sour Belts? More like a blue-toned fruit by the foot jerky. These inconsistently cut, flat treats boast an intense sour coating and nice flavor, but lack fun shapes or variety. Zach might reconsider his score if they were shaped differently or offered in other flavors - until then, it remains basic candy realness."
    },
    {
        "title": "Sour Punch Bites",
        "data": "These Sour Punch Bites are offensively sour, like a slap in the face from your worst enemy. With their puzzling wheat paste texture and questionable pasta shape, they deliver all the candy acids your taste buds never wanted. The green apple's natural green vibe is just a tease; while blue raspberry brings on more sourness, red strawberry sneaks in extra grossness—because why not? In short, it's pure tart torture for your mouth!"
    },
    {
        "title": "Sourpatch Kids Cherries",
        "data": "These limited edition Sourpatch Kids Cherries are tiny little disappointments. Smaller than a gummy bear and lacking that signature sour bite, you'll need to stuff at least two or three in your mouth for any thrill. Oh, and let's not forget the cough medicine cherry flavor - clearly an acquired taste *cough* Kyle *cough*. All sass aside, maybe skip these unless you're into underwhelming experiences."
    },
    {
        "title": "Peko Gummy Purple Grape",
        "data": "Get ready to pucker up, honey! Peko Gummy Purple Grape bursts onto the scene with a scent that'll take you back to sick days and grape cough syrup. Don't get it twisted - these chewy little girl face morsels are wrapped in sweet dust for a flavor thrill ride. Plus, the resealable packaging from Japan keeps things fresh! Overall? Boldly medicinal-yet-sweet vibes, baby!"
    },
    {
        "title": "Kasugai Fruitia Fruits Gummy Candy",
        "data": "Kasugai Fruitia Fruits Gummy Candy - this Japanese import boasts classy pale pastels and adorable diffusion effect. But honey, size matters! These gummies are smaller than the usual individually packaged ones, with a sad sample size of just 7 per bag. Flavors? Apple's strong, muscat grape smells like Bath & Body Works; peach is subtly apricotty while red grape turns it up to Welch's-level yumminess. Enjoy these chic little bites but don't expect much more!"
    },
    {
        "title": "Kasugai Tsubugumi",
        "data": "Kasugai Tsubugumi is a sassy Japanese import that makes American jellybeans taste like child's play. Imagine the lovechild of gummies and jellybeans, with elevated flavors (hello yuzu, mikan, and iyokan!) worthy of a pretty fruit salad soirée. Trust me – you won't mind their candy shell even if Zach does!"
    },
    {
        "title": "UHA Kogumi",
        "data": "These itty-bitty UHA Kogumi gummies pack a punch of colossal flavor! Don't let their teeny size fool you—they've got the perfect mix of chewiness and cuteness. With bold flavors like cola, ramune soda, calpis white soda, and melon soda, they're true to their name but might leave some (ahem, Zach) craving more. Just remember—small gummy equals big taste!"
    },
    {
        "title": "Bandai Wine Gummy Rose of Versailles",
        "data": "Straight from Japan, Bandai Wine Gummy Rose of Versailles tried to be fancy with their red-wine flavor. Unfortunately, they gave us moldy grape vibes and a weird jelly inside that doesn't scream \"wine.\" Instead, we're left gagging on an overly-astringent wannabe alcohol gummy. Hard pass!"
    },
    {
        "title": "Kabaya Tough Gummy NEO",
        "data": "Imported from Japan, Kabaya Tough Gummy NEO brings that energy drink flavor in a caffeinated cube form - toss 'em back with 90g of caffeine per bag! Definitely tough and coated with sour sass, these gummies aren't for the weak. Even Zach finds them too difficult to eat. Up your snack game; get ready to pucker up and power through!"
    },
    {
        "title": "Meiji Yoglet",
        "data": "import, jp\nYoglet by Meiji—\"a yog-tastic experience\"! Delight in its yogurt-esque center and intriguing taste. Texture? A+. Health boost? Extra calcium & collagen say \"yes\"! Channel your inner yocult-enthusiast while sippin' on this dairy delight; but naysayers beware—Zach deems it a \"weird sourish no.\""
    },
    {
        "title": "Kabaya Tough Gummy",
        "data": "Oh, honey. Kabaya Tough Gummies take \"tough\" to a whole new level of sassy! For men? Maybe. With a fab color distribution that puts American gummies to shame, every cube's rockin' a rugged texture and flavors like Red Bull-esque energy drink, delightful cola, and citrusy ramune soda – prepare for one wild ride in your mouth. Just remember - there's tough, but is there *too* tough?"
    },
    {
        "title": "Candemina Super Best",
        "data": "Candemina Super Best: a sassily dense, tougher & thinner sibling to the TOUGH Gummy. Packs a powerfully sour punch and comes in flavors like grape (weirdly red), okayish cola (brown) & ramune-turned-bubblegum blue. Not quite living up to its older counterpart, but still worth a chew for that zesty coating!"
    },
    {
        "title": "UHA Kororo",
        "data": "This Japanese novelty import is a bizarre hot dog-shaped purple ball that tastes like grapes with skin concealing a softer gummy center! The flavor is decent, but the strange skin really steals the show. UHA Kororo: it's unique, very weird, but kinda fun to munch on."
    },
    {
        "title": "UHA Reset",
        "data": "UHA Reset gummies pack a punch with their extremely sour, in-your-face grape flavor. You better pucker up because unlike other candies, these babies don't bother with wimpy coatings. Not to mention they're tough and chewy- making you work for that bold taste explosion! Simply put, this Japanese import is not for the faint of heart. Sassy enough? 😉"
    },
    {
        "title": "Gustaf's Jelly Babies",
        "data": "Gustaf's Jelly Babies: darling, you ain't it! Imported from the UK and sweeter than revenge after a breakup. The mushy texture is about as unsettling as the toddler shapes themselves. Yellow tastes like cleaning product lemon, green is a confused peach dream, while orange barely gets by with its basic citrus vibe. Pink? Raspberry wishes! Honestly hun, these babies deserve to be sent back across the pond."
    },
    {
        "title": "Nippon Eeru Melon Gummy",
        "data": "Straight from JP, these Nippon Eeru Melon Gummies ain't playin'! With their deliciously bold flavor and fabulously fun texture, they'll have your taste buds doing the cha-cha. Honeydew who? It's all about Nippon now! Get yourself some sass in a pack - you won't be disappointed."
    },
    {
        "title": "Bourbon Sharimoni Gummy",
        "data": "Bourbon Sharimoni Gummies bring a whole new meaning to sassy! Imported from Japan, these funky-shaped gummies boast a tart yogurt flavor coated by a strangely satisfying crunch. They truly embody the phrase \"good execution of a bad idea\". So pucker up and try them, but don't be surprised if they leave you just as confused as you are delighted!"
    },
    {
        "title": "Kanro Puré Ring",
        "data": "Straight from JP, Kanro Puré Rings boast unique ring shapes with tasty grape and peach vibes. But honey, don't expect your classic gummy-chew - these babies have a low bounce factor that leans more mushy than springy. Different? Yes. Game-changing? Eh... debatable!"
    },
    {
        "title": "Kanro Puré Grape Gummy",
        "data": "This Japanese-import yumminess, Kanro Puré Grape Gummy, bursts with such mouth-watering grape-finesse it'll make you question reality! Think sun-kissed vineyard sass in every juicy bite. A perfect blend of sweet and tart flavors that makes a muscat blush! Seriously folks, taste-buds have never been so flattered. Don't walk, run to grab these delightful gems!"
    },
    {
        "title": "Haribo Primavera Erdbereen",
        "data": "Oh, honey! Haribo Primavera Erdbereen imported from Deutschland delivers a scrumptiously sweet strawberry and cream affair! It ain't your classic gummy, darling. This soft treat plays up that tantalizing tartness - leaving those taste buds begging 'mehr'!"
    },
    {
        "title": "Trolli Sweet Squiggles",
        "data": "Trolli Sweet Squiggles? More like \"meh\" squiggles. These big worms bring just okay flavor and a disappointing mushy texture - where's the spring, Trolli? They're more sweet than sour, with an identity crisis that leaves me questioning if they're truly part of the Trolli crew. Not my go-to gummy worm pick but satisfying enough in a pinch. The flavors gave their best shot: strawberry-grape (red/white), lemon-lime (green/yellow), cherry-orange (red/orange). Try harder next time!"
    },
    {
        "title": "Haribo Ginger Lemon",
        "data": "Darling, let me tell you - Haribo Ginger Lemon is the addictive guilty pleasure you never knew you needed. It's an exotic tango of ginger spice flirting with sweet and sour citrus vibes. Yes, it's a bit medicinal, but honey, don't we all need healing sometimes? Invest in these little treats and dance your way through flavor town!"
    },
    {
        "title": "Yupo Süpper Jeli",
        "data": "Oh honey, Yupo Süpper Jeli is serving some serious citrus realness! The flavors are satisfyingly orange-y with just a hint of pith - delish. And that gummy texture? Yas, it's all thanks to animal gelatin. But darling, don't you know we're always craving more sour? Anyway, grab these goodies in both fabulous shades of orange and tangerine if you want in on the juicy fun."
    },
    {
        "title": "Okiko Shikuwasa Gumminchu",
        "data": "Just indulged in the oh-so-pretty Okiko Shikuasa Guminchyu from Japan. A bite of this fab green translucent cube unleashed a citrus frenzy with its delish Okinawan shikuasa flavor. The envy-worthy import just begs you to savor it, but trust me darling—they didn't last long!💋"
    },
    {
        "title": "Lotte Little Fit’s Big Gumi",
        "data": "Lotte Little Fit's Big Gumi import from Japan served me a grape-flavored cube of sass! This snack dared to blend kyoho and muscat grapes, giving an audacious duo of firm and soft textures. While some, like Zach, might be over the whole grape craze - this snazzy green delight had my taste buds vogueing for more muscat-liciousness. Embrace the attitude, honey!"
    },
    {
        "title": "UHA Mizugumi",
        "data": "This Japanese import, UHA Mizugumi grape gummy, serves a forgettable performance - clear and nondescript yet graced with the sass of grape. Don't expect any standing ovations; it's enough for a quick sweet fix but leaves you craving that true diva energy."
    },
    {
        "title": "Warheads Galactic Mix Cubes",
        "data": "Warheads Galactic Mix Cubes: a tantalizingly sour adventure through the cosmos. Though your taste buds will rocket to new heights with raspberry and cherry flavors, beware - you'll return to Earth donning blue-stained lips! Did they really need to color-match black holes? Tasty, but questionably fashionable! 💋🚀✨"
    },
    {
        "title": "Puchao Bubbly Soda",
        "data": "Puchao Bubbly Soda? More like a bizarre taffy-gummy combo from Japan that's harder to chew than my sassiness. Visually unappealing with weird tiny gummies trapped in the taffy, it's basically a pill pocket for your pet. And that blue ramune flavor? Honey, you can't sit with us."
    },
    {
        "title": "Kasugai Yuzu Gummy Candy",
        "data": "Straight from Japan and oh-so-pretty, these Kasugai Yuzu Gummy Candies are the talk of the town! With their perfect gummy texture and divine citrus flavor, no wonder we couldn't keep our hands off 'em. Even yuzu-growing skeptic Keith approves! While too subtle for Jay's taste - who needs him anyways? 100% Zach approved, these classy treats guarantee a tasty good time."
    },
    {
        "title": "Haribo Berry Clouds",
        "data": "Haribo Berry Clouds: like taking a bite out of an oddly delicious soap-sushi hybrid. These pretty, nigiri-esque gummies pack three layers of marshmallow heaven in pink (strawberry) or blue (blueberry). Though sizable and lacking tang, they make up for it with a mysterious bath bomb aura—purple gets props for flavor while blue sadly suds out. A berry appealing indulgence!"
    },
    {
        "title": "Welch's Fruit Snacks",
        "data": "Welch's Fruit Snacks: a bite-sized disappointment for adult mouths craving nostalgia. Waxy and far from healthy, these fruity imposters woo us with feel-good packaging but fail to deliver on bold flavors. Barely citrusy orange? No thanks! Save your tastebuds and just eat candy instead. Plus, the intricate molds can't save them from being a subpar American version of Kasugai gummies. Hey Welch's, less sass in the mold & more fruitiness in the punch, please!"
    },
    {
        "title": "Kasugai Kiwi Gummy Candy",
        "data": "Oh honey, let me tell you about these round Japanese imports – Kasugai Kiwi Gummy Candy. They're pretty AF and taste so fierce it'll outshine that basic Yuzu any day. Get ready for your tastebuds to be blown away by Zach's fav Kasugai flavor! These translucent green beauties don't play, they even have real kiwi seeds in them (insane, right?). And with kiwi sauce as an ingredient? You know it’s legit. Bottom line: they're freaking fantastic! 💁💚✨"
    },
    {
        "title": "Gushers Flavor Mixers",
        "data": "Gushers Flavor Mixers? More like flavor dribblers. The flavors pack a punch, but the gummy outside and inconsistent gushing are underwhelming. Raspberry lemonade is juicy (red), strawberry peach is drier yet satisfying (pink), and orange cherry takes you back to high school days in all its nostalgia (orange). But newsflash, Gushers: we'd probably enjoy it more if they didn't \"gush.\" Sorry, not sorry -xoxo Zach"
    },
    {
        "title": "Welch's Island Fruit Snacks",
        "data": "Welch's Island Fruit Snacks are like a tropical vacation for your taste buds, but it's not all sunshine and palm trees. The texture is less chalky and firm, yet you'll be doubling up just to get that flavor burst. Bizarrely dark red pineapple tastes nothing like pineapple, while the cough syrup-esque strawberry has us reminiscing about sick days. On the plus side - enjoy subtle grapefruit without the bitterness! Overall, these island misfits can't quite compete with their classic counterparts. Sorry Welch's, bring back our faves already!"
    },
    {
        "title": "Little Jasmine Fruit Jelly Candies",
        "data": "Oh, honey, Little Jasmine Fruit Jelly Candies sure tried to serve us something fancy! These tiny imported troubles look cute but taste like a confusing lime-flavored jelly drenched in bitterness with a lil' redemption towards the end. Individually wrapped and not too shabby, says Matthew - who do you trust?"
    },
    {
        "title": "Foreign Candy Company Rips",
        "data": "Rips? More like, \"been there, ripped that.\" These Foreign Candy Company gummies are just your typical, standard Haribo sour strip imitators. Though absolutely gummy and flaunting a sugar-sanded exterior for that extra pucker factor, it still falls flat in presentation and depth of flavor - think red strawberry wannabe! Nice try though."
    },
    {
        "title": "UHA Nagano Shine Muscat",
        "data": "Oh honey, UHA Nagano Shine Muscat is like a flirty little import straight from Japan! She's got an attractive look and a deliciously springy texture that I'm all about. Sure, she's got some sour attitude going on but trust me - you'll be wanting more of her sass in no time. Try it and see for yourself!"
    },
    {
        "title": "Kanro Puré Muscat Grape",
        "data": "Oh, honey - Kanro Puré Muscat Grape is like a gourmet sour patch kid straight from Japan. With their fab grape flavor (assuming you're into that), they'll leave your tastebuds begging for more of that sweetness. Treat yourself, darling!"
    },
    {
        "title": "Betty Crocker Minions",
        "data": "Betty Crocker's Minions are less ‘fruit snack’ and more ‘mildly fruity chapstick’. Perfect for fans of Rise of Gru, if you like your snacks shaped like inedible extruded Minions. Seriously though, do these things contain any real fruit? Or are fruit snacks just a front for gummy candy evolution? Nevertheless, they got me wondering - is this what minions actually taste like? Surely not..."
    },
    {
        "title": "Trolli  Sour Duo Crawlers",
        "data": "\"Talk about a trip down zany flavor lane! Trolli Sour Duo Crawlers are as gimmicky and soft as they come. They ingrain quirky combos like pink guava mixed with strawberry (which even Jay didn't despise!). Imagine savoring watermelon and citrus disguised as bubblegum or Jolly Rancher – absolutely the best of the mix, hands down. The mango-pineapple duo is alright - still trying to figure out if that green's meant to be pineapple? All in all, it's an eccentric sour twist rollercoaster ride.\""
    },
    {
        "title": "Mondelez international Swedish Fish Tails",
        "data": "These Mondelez International Swedish Fish Tails have the audacity to disappoint on a monumental scale! Their bland, oversized bodies hold about as much flavor as a melted candle. Three times stronger, at least, and we might've got somewhere! With 'flavors' like blue raspberry/strawberry that taste more synthetic than authentic and watermelon/pineapple just likely renamed wax - eww. Do these fish need aging or Canadian citizenship? Because they definitely do not satiate like their Canuck counterparts."
    },
    {
        "title": "Trader Joe's Cinnadragons",
        "data": "In a blunt bite, Trader Joe's Cinnadragons were utterly revolting. A disappointing blend of soft and sticky, their texture was less gummy perfection, more dental nightmare. Their flavor? Think low-grade Hot Tamale - likewise unimpressive. The supposed ‘dragon’ mold is comically failing; lumpy masses that bear no resemblance to dragons whatsoever. Sweet tooth explorers beware: these are unapologetically terrible – steer clear!"
    },
    {
        "title": "Mondelez international Swedish Fish and Friends",
        "data": "Mondelez International's Swedish Fish & Friends: Big on size, low on anticipation, these are surprisingly superior to their Turkish-made counterparts. Perfect for those who prefer a less strenuous chew. Zach may pass, but Matthew and Hillary cheer for the playful turtle and dolphin shapes. Strawberry pink dolphins? Yes please! Watermelon green turtles with Jolly Rancher vibes? Moreish! But the real star remains our red fish – flavorsome Lingonberry - in its rightful place as top-gilled performer!"
    },
    {
        "title": "Mondelez international Swedish Fish Classic",
        "data": "Mondelez's Swedish Fish Classics are gargantuan! Lingonberry or not, they deliver a sweet indulgence that has Kyle raving. The waxy yet subtle flavor landed less favorably with Matthew due to the cavity-inducing sweetness. They’re a class; nostalgic enough to score them an extra half point but still unlikely to top your candy faves list. Ideal for fans of tooth-sticking gummies!"
    },
    {
        "title": "Mondelez international Sourpatch Peaches",
        "data": "These big, coated Sourpatch Peaches by Mondelez swing and miss with their potent yet artificial flavor. Sure, they start off with an intriguing floral note but quickly nosedive into what can only be described as 'hotel hand soap'. The peaches on the bag flaunt their cleavage suggestively; however, the taste fails to meet high expectations set by their watermelon counterparts. Tread carefully, fruit enthusiasts - better hopes pinned onto their strawberry variant."
    },
    {
        "title": "Ferrara Nerds Gummy Clusters",
        "data": "\"Ferrara Nerds Gummy Clusters are nothing more than sugar-coated childhood nostalgia. They're the goofy, big-hearted kids of the candy world - a sweet and tangy mélange of nerdy crunch on the outside with a soft gummy heart inside, their sizes as varied as our elementary school memories. Yet, in our adult palate, they barely make a mark beyond being obnoxious cavities-in-waiting sugar trash.\""
    },
    {
        "title": "Werner Gummy Sharks",
        "data": "\"Werner Gummy Sharks – A bizarre blue-raspberry blob masquerading as a shark. It's big and squishier than soft-play, smelling like citrus floor cleaner with an identity crisis between jelly and gummy – raw sashimi-style, according to Kyle. The marshmallow part? Indistinct at best. Even Zach can't stand the smell! Here’s hoping for more authentic crustacean candy in the future!\""
    },
    {
        "title": "Promotion in Motion Sour Jacks",
        "data": "Sour Jacks from Promotion in Motion: springiness that makes your jaw work, with bursts of lime, orange, cherry and strawberry flavors. Imagine a rainbow dropkicked your taste buds - but wished it was more sour. Coated in sass not just sugar. Solid choice but we'd dial up the pucker power!"
    },
    {
        "title": "Lily's Gummy Bears",
        "data": "Lily's Gummy Bears may be low on sugar and high in fiber, but they're also low on taste and texture. Their muted earth tones do no justice to their oddly 'so-so' gummy consistency (thanks, lack of gelatin). The flavors are passable, but these \"sweet fruity friends\" leave you yearning for the vibrancy of true gummies; it's a mouthful of underwhelming disappointment – just ask Jay and Zach!"
    },
    {
        "title": "AriZona Fruit Snacks",
        "data": "AriZona Fruit Snacks: bite-sized brilliance or overpriced mediocrity? With delectable drink-inspired flavors, these outshine other fruit snacks we've sampled. A small size and company logo shape make them instinctively branded, but the murky colors leave us guessing which flavor is taking center stage. The waxy texture might not be everyone’s cup of tea and, much like AriZona's signature 99-cent cans; they're definitely more than a dollar a packet! In essence, good but unremarkably average."
    },
    {
        "title": "Sweet Tarts Gummeis Fruity Splitz",
        "data": "Sweet Tarts Gummeis Fruity Splitz - a gummi for our post-capitalist world. With their duality of both transparent and opaque appearances, they are reminiscent of appalling ribbon candy. Lustrously colorful but plagued with an aroma akin to Play-Doh, these soft gummis could shake up a Yankee palate as they lack a typical chewy texture. Their taste? An overtly artificial yet slightly sour rendition of the classic sweet tart flavor we all know (and perhaps don't love). Overall - it's vibrant novelty that doesn't quite hit the mark.\n"
    },
    {
        "title": "Yupo Ayicik",
        "data": "Turkish import, Yupo Ayicik offers a fun rollercoaster of gummy flavors. Each color presents an enigma - vivid yet nebulous tastes that caused frictious tastebud debates amongst us. Lemon stood out as the winner with its fruit-like flavor but orange seemed overeager to impress. Jay nearly lost it due to an odd soap undertone, while Matthew embraced the mystery and Hillary enjoyed their playful ambiguity. Cryptically yummy—if you aren’t fussy about pinpointing your flavors—this quirky spectrum of chewy non-American sweets keeps your palate guessing.\n"
    },
    {
        "title": "Sweet Tarts Gummies Fruity Splitz",
        "data": "Sweet Tarts Gummies Fruity Splitz are the Donna Versace of candies: loud, saucy and unapologetically artificial. With their see-through/opaque dual facade, they're as bi-textural as bad ribbon candy can get. As fragrant as a Play-Doh workshop and sassy-sour like their Sweet Tart cousins, these gummies deliver all chew but no fight - soft enough for even the most dentally-challenged Americans to savour.\n"
    },
    {
        "title": "Annie's Organic Fruit snack Building Blocks",
        "data": "Annie's Organic Fruit Snack Building Blocks: less of a snack, more of a child’s plaything. Yes, you're meant to build with these fruit flavoured Picasso-esque pieces if you can resist their surprisingly tantalizing taste. But alas! The texture is let down by the switch from gelatine to pectin – they’ve lost all their chewiness in the process. So unless your name's Kyle and you've got kids, it might be better sticking with your unembellished Supermarket Gummies."
    },
    {
        "title": "Werner Apple Rings",
        "data": "Werner's Apple Rings pack a scent-driven punch with their robust aroma. They're like peach rings but tailored for apple lovers—although they leave you wondering, 'which apple?' Jay would tell you they're the bee's knees. Enhance your experience by refrigerating them; it seems to add an extra spark. One drawback? They contain beef gelatin, making them unsuitable for some diets. While these bold, green munchies offer an interesting taste adventure, they might leave you appreciating peaches more than apples."
    },
    {
        "title": "Maynard's Wine Gums",
        "data": "Grab a pack of Maynard's Wine Gums from the Real Canadian Superstore, and chow down on these mildly-flavored gummies. Their best flavour? The natural-colored black currant, hands down. But be warned - they may have great texture, but their taste doesn't quite match up to their size. Don't get me started on that waxy bite! If you're all about flavor explosions, this might leave you saying 'oh Canada' in disappointment.\n"
    },
    {
        "title": "Dare Real Fruits Superfruits",
        "data": "Dare Real Fruits Superfruits: a flashy import from Canada. The snack is enticingly shaped and has tasty, albeit indistinguishable flavors of blackberry, blueberry, and blackcurrant. It's vibrant with pinks and purples yet falls flat waning the bouncy appeal of gelatin-textured confections - Vegan substitutes oh where art thou? A decent fruit nibble nonetheless!"
    },
    {
        "title": "Dare RealFruit Superfruits",
        "data": "Dare's RealFruit Superfruits are an okay addition to the fruit snack market. The shapes and flavors, while not very distinct, hold up reasonably well. Albeit a good 'vegan' attempt, the gummy texture is lackluster—c'mon science where's our gelatin-substitute? Featuring two hues of 'I think this was meant to be berry-colored', you'll be treated to vague notions of cranberry, blackberry, blueberry and blackcurrant - if you squint your tastebuds just right. Overall it screams mediocrity dressed in pink and purple packagings!"
    },
    {
        "title": "Twizzlers Gummies Happy Hour Peach Spritzer",
        "data": "Twizzlers' Gummies Happy Hour Peach Spritzer are as branded - more mushy, non-gelatin gummies. They're passable in flavor but lack the promised cocktail vibe which is a disappointing miss. And it's quite unfortunate that they haven't ventured fairly into gelatin territory yet."
    },
    {
        "title": "Dare RealFruit Sours",
        "data": "Dare RealFruit Sours impress with their genuinely tart kick and unmistakable flavors, though the grapefruit variety's bitterness might not be everyone's jam. The sourness tactfully masks their gelatin-free nature while all three—lemon-lime yellow, zingy orange, and pink grapefruit—could do with a size reduction. Enjoy this saucy Canadian import!"
    },
    {
        "title": "Dare RealFruit sours summerfruit ",
        "data": "Dare RealFruit sours summerfruit? More like, dare to disappoint. Cherry presents itself as a radish impersonator and falls short of its citrus siblings. Their colors scream vibrant but their flavors just whisper. Sour cherry is a red hot letdown, peach is well...peachy, and pink watermelon might have been better off staying on the vine! Better luck next time, Dare."
    },
    {
        "title": "Sourpatch Kids Lemonade",
        "data": "Sourpatch Kids Lemonade is like a summer fling with flavor. Just imagine regular Sourpatch Kids having a pool party in Countrytime lemonade powder - that's the kind of bash we're talking about. Yellow nailed the classic lemonade taste, but pink? Kyle says nay to your pink lemonade. As for blue raspberry lemonade, since when was that even a thing? Keep it sassy and traditional folks, this treat slays where it matters most!"
    },
    {
        "title": "Maynard's Fuzzy Peach",
        "data": "Maynard's Fuzzy Peach, straight outta the Canadian import lane. They've got the flavor nailed down but messed up with this non-gelatin recipe. A decent fruit gel masquerading as a gummy just ain't right! But hold up, they’ve got that nice sour bite in there - quite peachy indeed. Nothing life-changing here though, these 'gumm'-phosters are still among the best of their kind I have chewed on."
    },
    {
        "title": "Maynard's Swedish Berries",
        "data": "Canadian import Maynard's Swedish Berries are literally Swedish Fish impersonating berries. This gummy masquerade, although endorsed mildly by Matthew, factually has us tooth-stuck and fearing future diabetes diagnosis. Their petite size is a small comfort but even Jay has called quits on such saccharine chicanery."
    },
    {
        "title": "Dare RealFruit Medley",
        "data": "Dare's RealFruit Medley, an exotic non-gelatin import from Canada, misses the mark on \"bounciness\" but compensates with charming fruit shapes and zingy flavors. While they triumph over Swedish Berries, they might leave your tummy grumbling if you indulge in too many! Expect a colorful variety of strawberry-pinks, raspberry-reds, cherry-dark reds and questionable blueberry purples that tantalize your taste buds albeit temporarily.\n"
    },
    {
        "title": "Trolli Sour Duo Crawlers",
        "data": "Trolli Sour Duo Crawlers: the epitome of gimmicky confections with chewy marshmallow bases and questionable eye features. With uninspired flavor pairings like guava-strawberry and melon-citrus, these candies had me spearheading a lawsuit for taste-bud trauma. However, some found a redeeming spark in the Jolly Rancher-like watermelon mix - 'an interesting twist', per one Jay. Overall verdict? Buy if desperately craving sour gummies; otherwise, stick to standard Brite Crawlers for complete satisfaction sans any drama!"
    },
    {
        "title": "Dare RealFruit Sours Citrus Burst",
        "data": "Dare RealFruit Sours Citrus Burst hailing from Canada pass the test with flying colors. Refreshingly authentic, their genuine sourness keeps you on your toes while distinct flavors delight. Grapefruit's natural bitterness bounces off a sharp zing beautifully, making its non-gelatinous nature a positive twist! Their size however is a tad grandiose for my liking. The colorful trio- lemon-lime (yellow), orange (orange) and pink grapefruit (pink) score points both taste-wise and visually. Beware though: these guys are truly daring in the world of sours!\n"
    },
    {
        "title": "Red Band Wine Gumes",
        "data": "Dutch-made, Red Band Wine Gums give you a mild splash of springiness. The texture is spot-on, making others look waxy in comparison - not everyone's cup of tea though! Mouthfeel scores high but where are the flavors? Expect understated black currant from the purple one; apple doesn't hide behind green. Yellow's sweet lemon hint and orange has that generic 'citrus' vibe happening. As for red? Think strawberry-ish Jello. It's decent overall, just disappointingly bland on flavor-front!"
    },
    {
        "title": "Jolly Rancher Misfit Gummies",
        "data": "Jolly Rancher Misfit Gummies may exude 'gimmick' vibes, and their ambiguous Canadian origins leaves us befuddled. Frankly, they're like the deformed cousins of the Swedish Fish family. Despite having no aroma and an odd presentation (half bear, half fish? really?), they unexpectedly delighted us with orange-pineapple melody - except Jay who equated it to handsoap. Lemon-black raspberry was a hit too! But beware of strawberry-blue punch; unanimously voted as the worst misfit in this gummy circus!"
    },
    {
        "title": "Carnaby Sour Gummy Rings",
        "data": "Carnaby Sour Gummy Rings - they're like unexciting, squishy Froot Loops straight outta China. Most avoid them until peer-pressured in which case, surprisingly, they don't gag. Apparently Jay has low candy standards as he adores these generic blubber loops! They aren't atrocious but are about as exciting as watching paint dry.\n\n"
    },
    {
        "title": "Twizzler Press'd",
        "data": "Twizzler Press'd is a low-sugar, non-gelatin experiment gone wrong. Downright abysmal, their texture could make even Wallace and Gromit cringe - it's like chewing on clay! The watermelon, strawberry, apple and orange flavors all fail to save the day as they don't mask that ghastly taste. If you value your tastebuds at all, avoid this candy calamity!"
    },
    {
        "title": "Black Forest Gummy Bears",
        "data": "Black Forest Gummy Bears strut their stuff with a variety of matte colors and well-defined little legs. They're pleasing to the eyes, uniformly colored, and incessantly chewy. However, they put on more of a visual show than a taste one—with mildly flavored cherry reds to pineapple light yellows. If you crave for uncomplicated gummies that ooze mild flavory whispers rather than in-your-face fruit punches—these bear-y delights are perfect for you!"
    },
    {
        "title": "Dare Real Fruit Tropical",
        "data": "\"Dare Real Fruit Tropical? More like a Canadian fruit extravaganza minus gelatin! With plenty of flavors – Matthew raves about the strawberry kiwi while Kyle may or may not endorse the mango. Passion fruit still remains a mystery. Borderline gummies, more fruity snacks with all-natural colors and matte tones for an authentic experience. Not life-changing but good enough to nosh!\""
    },
    {
        "title": "Airheads Xtremes Sourfuls",
        "data": "Airheads Xtremes Sourfuls are a real misfire, leaving me questioning whether it's déjà vu or just bad candy. The white filling introduces an offputting texture with a taste eerily resembling the universally unloved mystery flavor. Coupled with their tough palate workout and bizarrely old-tasting \"rainbow\" zing despite being recently bought, proves this to be one flavorful journey I wouldn’t recommend– seriously, haven't we done these already? Points for pretty colors. Bittersweet at best; overall, passable!"
    },
    {
        "title": "Sour Punch Rainbow Straws",
        "data": "Sour Punch Rainbow Straws: a gimmicky, crystallized citric acid party with an identity crisis. Sporting a licorice texture, they paradoxically taste stale and waxy - like disappointment dipped in sour goo—and apart from the lemon's tenacity, the flavors are shy on showtime. Yes, you can play straw or bubble blower with them—but would you want to? Red for strawberry; green for apple; blue for…blue raspberry (seriously?); yellow for the dominant lemon!"
    },
    {
        "title": "Trader Joe's Cats and Bats",
        "data": "Trader Joe's Cats and Bats gummies? Just a Halloween gimmick. Shaped like bats -cute I admit-, cats too, puckeringly tart green apple makes your lips curl in delight. Zach says they're oddly jelly-ish though not enough to deter my enjoyment. Kyle won't stop raving about the packaging – just focus on the candies, man! If you're into over-the-top holiday gimmicks with good flavor, these might just be treat!"
    },
    {
        "title": "Kasugai Lipton Peach Tea Gummy",
        "data": "Check out these Kasugai Lipton Peach Tea Gummies, an audacious attempt at cross-branding that surprisingly isn't an epic fail. The classic buttery texture of Kasugai combines strangely well with tea's signature bitter note. Their intense aroma is bewitchingly peachy, and the taste a novel blend of curiously intriguing weirdness and unexpected goodness that even managed to make Keith relent! – certainly not as gimmicky as you'd first assume!"
    },
    {
        "title": "Cocoa Land Industries LOT 100",
        "data": "Cocoa Land Industries must've asked, \"How do we make bad gummies?\" Tough as Clint Eastwood with a peculiar rind-like finish; their flavors like green apple and black currant lost in translation. Bonus: mystery Prop65 warning to amp up your danger diet. Loved by Matthew (for reasons unknown), sourced from Malaysia, halal status still TBD!"
    },
    {
        "title": "Haribo Fruit Salad",
        "data": "Sassy Review: Haribo Fruit Salad is a big, coated excuse of a gummy treat. Most jelly-like and heavy on the gelatin, it showcases an array of fruit shapes that are ironically too soft - not chewy at all! The sweetness is off the charts cloying; enough to make your teeth ache. Matthew can endure them but Hillary wouldn't touch them with a ten-foot pole!"
    },
    {
        "title": "Safeway Signature Select Gummy Sharks",
        "data": "These Safeway Signature Select Gummy Sharks pull you in with their sweet smell and keep you hooked with a great chew and texture. Despite being smaller than the norm, they pack an unidentifiable punch of flavor - perhaps blue raspberry? Originating from Turkey, these gummies make me ponder if all grocery store sharks swim from the same sea. Food for thought!"
    },
    {
        "title": "Meiji Cola Up",
        "data": "Straight from Japan, Meiji Cola Up is all swank and bounce. It's a well-crafted gummy look-alike of Ramune Up with an aromatic spell that hooks you in. But alas! The flavor fumbles - too mild to match its delicious scent. Still, if tough chews light up your candy world, this bouncy treat might just do the trick!"
    },
    {
        "title": "Meiji Ramune Up",
        "data": "Imported from Japan, Meiji Ramune Up really takes gummy toughness to a new level, bouncing right up to 5 on the scale. Undeniably crafted in the same mold as Cola Up!, this treat's texture is undeniably bouncy but doesn't seem as pleasingly palatable with its ramune flavor. Unfortunately, it seems people root more for Team Cola than Team Ramune."
    },
    {
        "title": "Amaguri Sweet Chestnut",
        "data": "Zach's immediate \"AAaaeugh!\" hinted at the terror ahead: peuce, garbanzo-looking sweets, that some mistook for tiny rears. These Japanese-imported Amaguri Sweet Chestnuts gave a hazelnut-coffee vibe - an intriguing gummy flavor novelty. Sadly though, they were deceptively tough and didn't win anyone's palate over. An acquired taste even on their best day!"
    },
    {
        "title": "Kabaya Tough Gummy: Orange Soda",
        "data": "Kabaya's Tough Gummy: Orange Soda imported from Japan promises action hero-level endurance, delivering a delightful Tang-like tanginess in tiny cubes. Loving their audacious attempt to encapsulate an orange soda fizz in a gummy cube! Unimpressed comrade Keith still refuses engaging with the gummies but has succumbed to wasabi peas obsession. A peculiarly fun substitute for your usual sweet treat!\n"
    },
    {
        "title": "IKEA LÖRDAGSGODIS - sweet and sour",
        "data": "IKEA's LÖRDAGSGODIS is a psychedelic spin on the Swedish candy-on-Saturdays tradition. Imported from Finland, it offers an eccentric collection of oddly-shaped sweets in pale hues. Taste-wise, be prepared for a citric acid whirlwind and controversial rhubarb surprises - scored big with Keith, but sent Kyle into meltdown! The cola flavored ones, cosplaying as moose and boats are crowd pleasers though. And with its enormous bag-size offering mouthfuls of joy at every rummage - this intriguingly weird yet satisfying mix is definitely worth carting home from IKEA next time you drop by to buy"
    },
    {
        "title": "Sour Patch Kids",
        "data": "\"Sour Patch Kids gives us a rainbow of flavors, from the mesmerizing blue raspberry- more color than genuine flavor but we dig it. The indefinable 'red berry,' pops with perfect balance while 'yellow lemon' hits intense notes that we worship. Hear us out now - green lime! Not enough in the bag though, highly scandalous, sort it out SPK. Lastly, orange keeps it classic and absolutely shines at full size (half size is not on kids). To sum up? A sour-coated joy ride for your taste buds.\""
    },
    {
        "title": "Sour Patch Cherries",
        "data": "Sour Patch Cherries' limited edition variant barely qualifies as sour. They're more like underwhelmingly tart post-its - smaller than a gummy bear, you'll need a handful to satiate your sour cravings. The flavor? Think cough medicine cherry, an acquired taste only someone like Kyle might appreciate!"
    },
    {
        "title": "Sour Patch Peaches",
        "data": "Managed to chew through Sour Patch Peaches - bigger and bolder but saturated in artificiality. It starts with an alluring floral hint, swiftly morphing into 'hotel hand soap' as Matthew so aptly put it. The enticing peach cleavage on the bag left Jay disappointed following his watermelon high but hopeful for a strawberry redemption. Candy or cleaning product? You decide!"
    },
    {
        "title": "Sour Patch Kids Lemonade",
        "data": "Sour Patch Kids Lemonade: a candy experience akin to dropping your favorite sour gummies in a Countrytime lemonade powder tub. Top-notch classic yellow, divisive pink (sorry Kyle), confusingly non-existent blue raspberry, and pleasant red cherry flavours all wrapped up in the familiar Sour Patch shape and texture. Ultimately, it's an unapologetically sassy summer treat that gets two thumbs up!"
    },
    {
        "title": "Red Band Wine Gums",
        "data": "For those seeking a sprightlier chew, these Dutch-imported Red Band Wine Gums from 'A touch of Dutch' hit the sweet spot. Subtly flavoured with nuanced apple, lemon and ambiguous 'citrus', they lack in flavour fireworks but compensate with texture. Hill finds their wax-level faultless while Zach wishes for more waxy resistance. Their black currant offers the lightest hint while the red screams \"jello\" louder than strawberry. Good feeling in mouth; disheartening taste buds.\n"
    },
    {
        "title": "Sour Patch Kids Tropical",
        "data": "\"Sour Patch Kids Tropical is a sun-drenched, taste bud-tickling delight. A tantalizing twist on the classic candy, it should be fast-tracked to gummy heaven! Each flavor offers an explosion of zest – from powerfully pineapple to subtly sassy passion fruit and romantic tropical twist. The colors are visually appealing but the fake pineapple flavor needs a little tone down. Overall, they're zingy, juicy and wonderfully chewy enough for Zach's approval! Dive into this fruity fiesta now!!\""
    },
    {
        "title": "IKEA LÖRDAGSGODIS sweet and sour mix",
        "data": "Straight from Finland, IKEA's LÖRDAGSGODIS mix is a sensory extravaganza! With pale, strangely shaped candies screaming \"Saturday treats,\" this bag loads up on the citric tang. Pungent rhubarb might be a delight for the Keiths of the world but an upset for Kyles. Moose-shaped colas are undeniably addictive to all though. No scent detected, but who cares with such kaleidoscope flavors? Colossally outsize, it's one purchase you'll want to lunge at next IKEA visit.\n"
    },
    {
        "title": "Kabaya Tough Gummy Orange Vibes",
        "data": "Straight from Japan, Kabaya Tough Gummy's Orange Vibes are small but mighty. These chewy cubes perfectly encapsulate the fizz of orange soda, for a tangy treat that's not for the faint-hearted. They're gummies with grit – perfect for action heroes in training! While Keith sits out crunching wasabi peas, the rest of us can't get enough of these tough little rascals."
    },
    {
        "title": "Sour Patch Kids Cherry",
        "data": "Pint-sized and lacking true tartness, these are the understudies of Sour Patch Kids. You need a handful just for a hint of tanginess. As for 'cherry' flavor? More like cough medicine - not my scene but Kyle seemed to enjoy it! They're passable in pinch. If you like your sweets resembling over-the-counter remedies, these are all yours!"
    },
    {
        "title": "Sour Patch Watermelon ",
        "data": "Zach may poo-poo the lackluster presentation, but let's be real, Sour Patch Watermelon is all about that flirty dance of soft and sour. It provides a light puckering sensation swiftly followed by a tantalizing watermelon taste. Truly Jay's top pick for good reason!"
    },
    {
        "title": "Lifesavers Gummies - Berry",
        "data": "Lifesavers Gummies - Berry, tout six flavors yet I can only decipher five. Engaging much? No! Imagine biting into vaguely-shaped blobs with mute flavors that leave your tastebuds asking \"That's it?\" Attractive in hues of pink, light red, and two shades of purple – but when candies start resembling modern art pieces more than edible treats...Oof! Not worth raving about, yet oddly addicting enough to score a 3. If you fancy chewing mildly flavored gummy bear-ish inner tubes, be my guest!"
    },
    {
        "title": "Shodoshima Olive gummy",
        "data": "Shodoshima Olive gummy leaves much to be desired. A misnomer from Japan, these don't smell or taste like olives! It's more a kiwi-flavored fruit jelly masquerading as a classy candy with its elegant packaging. Plot twist - the olive may just feature like it does in olive leaf beer, purely ornamental, certainly not for flavoring. Disappointing.\n\n"
    },
    {
        "title": "Amos 4D Fruit Gummy Juice Rush",
        "data": "Get ready to retina-gasp at Amos 4D Fruit Gummy Juice Rush. This Japanese import sparkles like the crown jewel of gummies- gorgeously detailed down to tiny green leaves! With the clarity of premium optical glass, their fabulous textures surround a juicy core. The red grape stuns visually, but taste-wise? As Zack infamously said - he hates grape. Meanwhile, the underappreciated white Muscat variant is pure charm! Without a doubt, these are exceptionally beautiful contenders in our gummy reviews.\n"
    },
    {
        "title": "Nobel Kame Kame Sours",
        "data": "Straight from Japan, Nobel Kame Kame Sours are intriguing with a distinct Lipovitan (think Red Bull) flavor. The loose gummy texture hidden within the turtle shell excites yet confuses your palate. My friend Zach is quite taken by its 'energetic' medical taste but honestly? It could definitely use more sizzle in the sour department!"
    },
    {
        "title": "Nobel Chibi Sours",
        "data": "Despite their lofty name, Nobel Chibi Sours lack the sour bang you'd expect. These tough treats - reminiscent of dehydrated fruits or really old gummies - barely manage to hit the flavor mark. The lemon squash? A curious choice yet understated. The cola tastes like a flat, bad soda and the melon soda is forgettable. The Ramune could do with some pep in its step too! Enjoyable for some perhaps (Matthew seemed to fancy them), but I couldn’t find a lot to celebrate here – it's no Nobel prize winner in my books!"
    },
    {
        "title": "Kabaya Peanuts Gummy",
        "data": "If sassy is what you want, then buckle up buttercup! Kabaya's Japanese-import Peanuts Gummy hit just the wrong note with its horrendous symphony of overripe apple and artificial sweetness. Snoopy might grace the packaging, but he can't save this marshmallowy mess from your taste buds' disappointment. Its weirdly spongey texture adds another offense to an already guilty gummy. Smelling it? Dare I say, like huffing candified compost! In a nutshell – fantastically flunked flavors!"
    },
    {
        "title": "Haribo Orangina P!k",
        "data": "Seducing your taste buds straight from France, these Haribo Orangina P!k gummies are as fabulous as they're sturdy. Shaped like adorable mini bottles with a clear exterior filled with vibrant orange color, it's a palatable art piece of orangina captured in candy form. Expect an unexpected touch of toughness wrapped with robust fruitiness that teases you into thinking it's orange sour but serves you orange juice sour instead! Perfectly crafted with flair, this cobranded delight is truly the Creme de la creme of all gummy candies out there."
    },
    {
        "title": "Haribo Kirsch-cola",
        "data": "Imported directly from DE, the Haribo Kirsch-cola is a letdown. Its oversized and bizarre shape coupled with its \"pathetically\" waxy texture leaves you underwhelmed - trust me even Hillary despises it! The absence of gelatin validates this failing. While its sweet cola flavor barely rescues it, that medicinal cherry taste just kicks it down again. An overall disappointment!"
    },
    {
        "title": "Meiji Boost Bites",
        "data": "Meet Meiji's delectable dynamites: Boost Bites. Boasting a 5 on their toughness scale, these Japan-imported gummies have the vim of Mountain Dew but look cheekily like mini batteries. Infused with caffeine, royal jelly and b vitamins—60mg of octane per bag—these springy morsels put Kame Kame sours in the shade. Energy has never tasted so energising!"
    },
    {
        "title": "Nobile Otoko Ume",
        "data": "Experiencing Nobile Otoko Ume was like going on a culinary rollercoaster in Japan. The unique, yet divisive flavor profile certainly jolts the tastebuds with bold salty sourness that amplified with every chew - not everyone's cup of tea but definitely big in Japan! Hillary balked at it though. Highlight: their adorable geodude-like mascot!"
    },
    {
        "title": "Lifesavers Gummies Wild Berries",
        "data": "Lifesavers Gummies Wild Berries pack is a fruit misfire. Although promised six flavors, I found only five- strawberry, red raspberry, black raspberry, blackberry and cherry berry (white grape was an elusive dream). Paltry forms do no justice to their torus claims; colors blur into shades of pinkish-red and purple. Their mild flavor hardly screams \"WILD.\" Disappointing yet somehow addictive - they achieve paradoxical mediocrity. Take it or leave it but don't expect any lifesaving razzmatazz from these gummies! 3/10: Mildly wild? Maybe...just maybe"
    },
    {
        "title": "Amos 4D Juicy Rush",
        "data": "Reflecting Japanese artistry, the Amos 4D Juicy Rush are not plain old gummy candies - but delicate faceted jewels! Unbelievable in detail and sparkle, they woo with their green leaves and high optical grade clarity. Overwhelming credit to this center-filled delight for its lovely texture; red grape or white muscat, take your pick. Though Zach may detest grape, these captivating treats easily reign as beauty queens of our gummy reviews!"
    },
    {
        "title": "Haribo Orangina Pik",
        "data": "A French import, these Haribo Orangina Pik are a delightful twist on your usual sour gummy. Posing as petite bottles with clear and red/orange innards, they’re tougher than expected but explode with a strikingly floral orangina-flavored punch – think orange juice sour, not the typical sharp citrus tang. Their co-branded aesthetic is tight indeed! Voila."
    },
    {
        "title": "Nobel Otoko Ume",
        "data": "A startling import from Japan, Nobel Otoko Ume boasts a unique pickled flavor that's more of an acquired taste - wonderful for those who fancy the salty and sour mix, but crippling for palates like Hillary's. The umeboshi taste amplifies with each chew, however divisive it may be. Also offering a geodude mascot to sweeten (or rather, salt) the deal!"
    },
    {
        "title": "Kabaya Tough Gummy Dual Grape Punch",
        "data": "Straight from Japan, Kabaya's Tough Gummy Dual Grape Punch is as sassy as candy comes. It doesn't shy away with its alpha-beta complex: one a subtly sweet maven and the other refreshing in its acidity. For those less certain of their grape varietals - good luck distinguishing them! But don't worry, the toughness you love still stars. Plus, the cube shape? Adorable. Bright purple for flavorful grape blast while red calms down with a mild-mannered cousin variant.\n"
    },
    {
        "title": "UHA Tsugummi Blocks",
        "data": "UHA Tsugummi Blocks, a weird import from Japan, are gimmicky at best. They're like wet gummy Legos you have to awkwardly separate and with odd earthy colors, their Lego-like molds leave much to desire for. We futilely tried building - total flop! Shimmering appeal? Sure, but that's about it. They ain't your daddy's gummies, as Kyle eloquently phrased it. Compatibility with actual Legos is still up for debate!"
    },
    {
        "title": "Haribo Almedudler",
        "data": "Haribo's Almdudler packs in the tough love with its Austrian soft drink-inspired herbal gummies, sporting two shapes - a bottle and 'drinking buddy' twins with hints of elderflower and raspberry. While Kyle snubs the twin flavor, Jay implores for an amped up taste. Vegans, steer clear!"
    },
    {
        "title": "Sour Patch Kids Berries",
        "data": "Sour Patch Kids Berries - all sass and little class. Coated in foreign intrigue, these fruity foes taste more like a Canadian ruse. Blue raspberry screams forgery while strawberry is just plain scandalous. Cherry and grape do pass the test, but let's face it – original Sour Patch crew trumps these poseurs any day! I can't help but wonder if they’re simply stale spies amidst our candy kingdom.\n"
    },
    {
        "title": "Meiji Fruit Juice Gummy",
        "data": "Straight from Japan, Meiji Fruit Juice Gummy sets the bar with high-quality molding. Ranking a 2 on the toughness scale, these gummies are perfectly squishy and easily chewable. Despite it being a limited seasonal flavor that initially reminds you of your grandma's perfumy house in a not-so-appetizing way, this delicate delicacy manages to snag 4 out of 5 with its unique floral notes. Perfect for when you're feeling adventurous!"
    },
    {
        "title": "Kanro Pure Sparkling Muscat Soda",
        "data": "Kanro Pure Sparkling Muscat Soda, straight from Japan, dresses up like a sour gummy but fools you with its extreme sweetness. It tastes disturbingly reminiscent of overripe grapes with an unsettling gooey center - Zach is, quite frankly, not here for it. With this candy masquerading as soda, there's more than fizz that's flat—consider our taste buds unimpressed!"
    },
    {
        "title": "Bourbon Fettucine Gummi",
        "data": "This Bourbon Fettucine Gummi imported from Japan is another grape nightmare - reminiscent of purple nerds or Bubblicious bubble gum. The sour coating is unique, but otherwise unimpressive and frankly kind of boring. Oh, the fettuccine shape? Not a fan! Seems like we're scraping the bottom of the gummy barrel here."
    },
    {
        "title": "Vidal Sour Kiwi Slices",
        "data": "Vidal Sour Kiwi Slices, an imported sugar-coated nightmare. Rough on the tongue with little fragrance to tease your senses. Its fierce sourness mimics a kiwi, but can you call this artificial imitation flavoursome? Turmeric and Blue 1 provide color distractions from its lackluster experience. These sassy slices are just too abrasive to ravish in joy!"
    },
    {
        "title": "Gurley's Gummy Blue Sharks",
        "data": "Gurley's Gummy Blue Sharks are all bark and no bite! The masterfully moulded mariners may fool you with their detailed gills but don't be deceived. These marshmallow imposters could easily double up as craft store polyurethane! Initially promising, they soon sink into a sea of mediocrity. Think very average gas station buy rather than sweet shop treasure...Even Kyle agrees!\n"
    },
    {
        "title": "Haribo Super Mario",
        "data": "In a snappy, teeth-kicking assault of sour, Haribo Super Mario gummies deliver classic tang with toughness that'll keep your jaw working overtime. Imported from Deutschland and officially Nintendo-approved, these substantial sweets bring vibrant lemon coins and stars, apple-flavored mushrooms with an admittedly fake but popular jolly-rancher taste (Hillary approves!), plus fiery red mushroom and flower shapes. The moldwork rocks multiple amusing forms, perfect for gaming geeks on a sugar quest!\n"
    },
    {
        "title": "Haribo Fruitmania Berry",
        "data": "Haribo Fruitmania Berry is a colorful European import, sporting detailed molds and an array of tones. But don't go berry-hunting by scent - it's as if they forgot to pack in the aroma! Heavy on looks but light on distinct flavors, these non-gelatin treats feel more like dressed-up fruit snacks than gummies. Their redeeming features? A solid chew and 20% fruit juice contributing to their consistent taste. Savvy vegetarians looking for subtly flavored sweets might get along with these nonchalant nibbles.\n"
    },
    {
        "title": "Ferrera Funables Fruit Snacks",
        "data": "Ferrera Funables Fruit Snacks: dazzling in color but dazzles end there. Expect a surprise - they’re more candy than fruit snack! Don't bank on identifying the strawberry from the raspberry, their flavor profiles are indistinguishable twins. With gelatin sneaking its way into the recipe, these snacks step out from the usual fraternity of fruit snacks. Overall verdict: Meh flavors meet average texture!"
    },
    {
        "title": "Huer Sour Brats",
        "data": "Huer Sour Brats, a Canadian import, are adorably shaped yet disappointingly mild on the sour scale. They've got an appealing softness that puts their chewier contender, sour patch kids, to shame. The standout orange draped delight takes the flavor crown here; however green apple and debatably strawberry come as pleasant palate surprises too. Grape? Well…it tried! Overall consensus: Cute but could be 'sour-er'."
    },
    {
        "title": "Haribo Wine Gums",
        "data": "A distinct British nod from Haribo with their imported wine gums - complete with Big Ben, a red bus, and a Beefeater guard clad package. Standard flavors yet poorly distributed - could barely find the raspberry! Decent bite but minimal waxiness - won't quite pass Hillary's 'wine gum' test. Lemon packs zest while pineapple white poses confusion. Overall spongy sweets lack colour-pop perks.\n"
    },
    {
        "title": "Haribo Gruene Krokodile ",
        "data": "In an audacious import from Germany, Haribo hits us with Gruene Krokodile - a two-tone jelly snack masquerading as gummies! Despite its hood of green promising apple delight, the flavor is suspiciously AWOL. Daring to dance in vegan territory (no gelatin here!), these large chaps also hasten to declare a divergence from illicit substances. Despite lacking the sour punch I yearn for and a questionable chew-factor, their overall taste dances sardonically on the pleasant side. The color/flavor duo rampage includes apple/cherry, apple/orange and distinctively praiseworthy, apple/pass"
    }
]