{
    "range": "Sheet1!A1:R1348",
    "majorDimension": "ROWS",
    "values": [
        [
            "Date",
            "Brand",
            "Type",
            "Zach",
            "Keith",
            "Kyle",
            "Matthew",
            "Jay ",
            "Hillary",
            "Guest",
            "Average",
            "Median",
            "Deviation",
            "Keywords",
            "Color",
            "Flavor",
            "Shape",
            "Notes"
        ],
        [
            "08-10-2022",
            "Haribo",
            "Happy Cherries",
            "3",
            "2",
            "4",
            "3",
            "3",
            "3",
            "",
            "3.0",
            "3.0",
            "0.63",
            "tough, big",
            "red, green",
            "cherry",
            "",
            "Medicinal tasting (coughdrop like); strong smell, mild taste; very chewy/tough"
        ],
        [
            "08-11-2022",
            "Haribo",
            "Sour Streamers",
            "2",
            "2",
            "2",
            "4",
            "2",
            "3",
            "",
            "2.5",
            "2.0",
            "0.84",
            "sour, coated, flat",
            "",
            "overall",
            "",
            "Fine, but not great; weak flavors; able to eat them; nice sour taste; too flat, not enough gummy to sour ratio; fake fruit leather"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "sour apple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange"
        ],
        [
            "08-12-2022",
            "Haribo",
            "Goldbears",
            "4",
            "2",
            "3",
            "4",
            "4",
            "5",
            "",
            "3.7",
            "4.0",
            "1.03",
            "tough",
            "",
            "overall",
            "",
            "Too chewy; good but mild flavors"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "strawberry",
            "",
            "actually tastes like strawberry; uncommon choice for green"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "tastes like orange and we like it; citrus flavors are good"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "raspberry",
            "",
            "subdued, does not taste much like raspberry; inoffensive"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "juicy, very lemony"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "clear/white",
            "pineapple",
            "",
            "pineapple flavor is good; vaguely tropical"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue (party hat)",
            "blue raspberry",
            "",
            "looks like tentacle from day of the tentacle, nice crisp mold; tastes blue"
        ],
        [
            "08-15-2022",
            "Haribo",
            "Goldbears Sour",
            "4",
            "2",
            "3",
            "4",
            "3",
            "4",
            "",
            "3.3",
            "3.5",
            "0.82",
            "sour, tough",
            "",
            "overall",
            "",
            "Sourness is pretty light, very sweet; tougher than regular gold bears"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "strawberry",
            "",
            "Only a little sour; but only sour to Jay; didn't taste much of strawebrry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "orange flavor works well with sourness; tougher to chew"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "raspberry",
            "",
            "brings out the raspberry flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "very lemony, works well with the sournesss"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "clear/white",
            "pineapple",
            "",
            "tastes stronger with the sour bears; pina colada fake coconut flavor"
        ],
        [
            "08-16-2022",
            "Haribo",
            "Sour Kicks",
            "4",
            "2",
            "2",
            "4",
            "2",
            "4",
            "4",
            "3.1",
            "4.0",
            "1.07",
            "sour, big",
            "",
            "overall",
            "",
            "Form factor is excessively large; Jay says they are like drinking orange juice after brushing teeth; too sour so they are kinda gross; hillary thinks they are basically perfect but shoe form factor is weird"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Erik)",
            "",
            "",
            "",
            "",
            "white",
            "grape",
            "",
            "doesn't taste like grape at all; just tastes like sour"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "very sour; flavorful; matthew likes sour on the outside, not the inside; good texture and chew (better chew than the cherries); like the flavor, not the sour (Jay); keith doesn't like the texture"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry",
            "",
            "SOUR; consistent flavor all the way through"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple/dark red",
            "raspberry",
            "",
            "it's sour! very good, very sour at first with the raspberry flavor emerging as you chew"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "flavors are like jolly ranchers, esp. this lemon one"
        ],
        [
            "08-17-2022",
            "Haribo",
            "Peaches",
            "2",
            "2",
            "3",
            "3",
            "2",
            "2",
            "",
            "2.3",
            "2.0",
            "0.52",
            "soft, big",
            "pink, orange",
            "peach ",
            "",
            "Smells like peaches; Kyle likes flavor but not the texture; similar to kettle jellies from TJs; gross"
        ],
        [
            "08-19-2022",
            "Snak Club",
            "Tajin Peach Rings",
            "1",
            "X",
            "X",
            "1",
            "1",
            "X",
            "",
            "1.0",
            "1.0",
            "0.00",
            "big, tough, coated",
            "pink, orange",
            "peach ",
            "",
            "Looks terrible; fucked up calamari appearance; keith refuses to eat (as well as Hillary); is this a 1 or 0? bad in every possible way; smells disgusting; tastes like what zach imagine eating dog shit is like"
        ],
        [
            "08-29-2022",
            "Gustaf's",
            "Wine Gums",
            "3",
            "2",
            "2",
            "4",
            "3",
            "3",
            "",
            "2.8",
            "3.0",
            "0.75",
            "tough, waxy, import, uk",
            "",
            "overall",
            "",
            "Tough, hard, chewy; good flavors"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "hillary loves it, but it's kinda rindy; weak lemon, strong front-loaded resistance due to chew requirement - kyle; chewyness is offputting"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "good, mild flavor for an orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "raspberry",
            "",
            "tastes red!"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "black currant",
            "",
            "jay's favorite; kyle's least favorite; not doing it for zach"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "apple",
            "",
            "tough; good flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "white",
            "peach",
            "",
            "also tough; mild, delicious flavor"
        ],
        [
            "08-30-2022",
            "Haribo",
            "Happy Cola",
            "5",
            "2",
            "4",
            "3",
            "3",
            "3",
            "",
            "3.3",
            "3.0",
            "1.03",
            "cola, big",
            "clear, brown",
            "cola",
            "",
            "strong cola scent; smells like peppermint to jay; tastes carbonated; has a spicy kick; slightly medicinal; vanilla and cinnamon; perfect chewiness"
        ],
        [
            "08-31-2022",
            "Sour Patch",
            "Watermelon ",
            "2",
            "2",
            "4",
            "4",
            "5",
            "3",
            "",
            "3.3",
            "3.5",
            "1.21",
            "soft, sour",
            "green, pink",
            "watermelon ",
            "",
            "Zach disappointed by appearance; lightly sour; good watermelon flavor; this is Jay's personal favorite"
        ],
        [
            "09-01-2022",
            "Haribo",
            "Watermelon ",
            "4",
            "2",
            "3",
            "3",
            "2",
            "2",
            "",
            "2.7",
            "2.5",
            "0.82",
            "big, coated",
            "green, pink, white",
            "watermelon ",
            "",
            "Zach likes that they have the rind and melon clearly separated; tastes like watermelon bubblegum; form factor is way too big, should be half the size; matthew really likes the flavor and the texture but the size is too big; there was a forbidden gummy that tasted like green apple but very tough and clearly fell off some factory component (2); very complex gummy where each part tastes different"
        ],
        [
            "09-02-2022",
            "Trolli",
            "Sour Watermelon Sharks",
            "1",
            "1",
            "2",
            "2",
            "2",
            "2",
            "",
            "1.7",
            "2.0",
            "0.52",
            "soft, coated",
            "green, pink",
            "watermelon ",
            "",
            "watermelon bubblegum flavor; too big; more like a taffy than a gummy; 'it's bad' - Zach"
        ],
        [
            "09-06-2022",
            "Haribo",
            "Twin Snakes",
            "3",
            "2",
            "3",
            "4",
            "3",
            "5",
            "",
            "3.3",
            "3.0",
            "1.03",
            "sour, big, gimmicky",
            "",
            "overall",
            "",
            "Jay felt flavors were mediocre; Kyle didn't like them paired, preferred sweeter flavors; Zach concept is confusing"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green/yellow",
            "apple/lemon",
            "",
            "green is sweet apple; yellow is sour lemon; only a few people had this one"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/blue",
            "blueberry/black currant",
            "",
            "blue (blueberry) which is an unusual flavor; red is sour black currant; an unusual combo, but it works"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple/orange",
            "orange/cherry",
            "",
            "sour orange, pixie stix; are we supposed to eat them together? are they better apart?"
        ],
        [
            "09-07-2022",
            "Warheads",
            "Wally Cubes",
            "3",
            "2",
            "3",
            "4",
            "4",
            "4",
            "3",
            "3.3",
            "3.0",
            "0.76",
            "sour, cube",
            "",
            "overall",
            "",
            "bit of a sleeper hit; does not live up to the warheads name as they are not sour at all; very mild flavors"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "purple",
            "black cherry",
            "",
            "doesn't really taste like anything; people thought it was grape; not sour enough and does not live up to the warheads name"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry",
            "",
            "jonathan - pink = salmon? rosewater? "
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "not sour at all; very orangy; not very gummy, more jelly"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "apple",
            "",
            "jonathan - tastes like apple juice; very strong artificial apple flavor; tart and juicy - matthew"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry",
            "",
            "flavors are very predictable; tastes like an airhead"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "watermelon",
            "",
            "not sour at all; well proportioned; very similar to sourpatch kids watermelon"
        ],
        [
            "09-08-2022",
            "Sour Patch",
            "Kids",
            "4",
            "2",
            "5",
            "4",
            "4",
            "5",
            "",
            "4.0",
            "4.0",
            "1.10",
            "sour, coated",
            "",
            "overall"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry",
            "",
            "so sour and we love that for them; tastes more like the colors than the actual flavors; zach's favorite"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "red berry",
            "",
            "wtf is red berry? very good, much like the others"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "very intense lemon flavor; perfect sweet and sour"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "lime",
            "",
            "FINALLY something sour!! - hillary; good classic texture; very limited number of green in the bag"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "much better at the correct size (rather than cut in half); delicious classic flavor"
        ],
        [
            "09-09-2022",
            "Lifesavers",
            "Gummies Neons",
            "3",
            "2",
            "X",
            "3",
            "3",
            "3",
            "3",
            "2.8",
            "3.0",
            "0.41",
            "chewy",
            "",
            "overall",
            "",
            "Flavors are novel, but generally disappointing, but the gummy texture and resistance/bounce is very good; wish it was sour - Zach; blue and red are best flavors - Ethan"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Ethan)",
            "",
            "",
            "",
            "",
            "turquoise",
            "bold berry",
            "",
            "blue raspberry flavor; good; great texture, bad gummy aftertaste; texture is more rubbery in a good way; fresh gummies; not tendony, but a bit rubbery"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "lime green",
            "cherry lime splash",
            "",
            "keith - this is much worse than the rest; jay - i have not tasted something like this before; it's not great; does fit with the unique flavor profile of the whole batch"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange pop",
            "",
            "tastes like orange soda; tastes like crush soda"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "eletric lemonade",
            "",
            "keith - very vivid, but bad flavors; weak flavor vs blue and magenta; weird lime rind taste"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "magenta",
            "pink punch",
            "",
            "hawaiin punch flavor; memories returning for ethan of childhood, same with jay; "
        ],
        [
            "09-12-2022",
            "Trader Joe's",
            "Super Sour Scandinavian Swimmers",
            "2",
            "X",
            "3",
            "4",
            "5",
            "4",
            "",
            "3.6",
            "4.0",
            "1.14",
            "soft, sour",
            "",
            "overall",
            "",
            "lots of red in the bag, soft, more jelly than gummy, sour but not that sour (definitely not \"super sour\")"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "red berry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "not particualrly orangey"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "huckleberry",
            "",
            "unusual flavor name but flavor itself not so unusual"
        ],
        [
            "09-13-2022",
            "Kroger",
            "Orange Slices",
            "1",
            "X",
            "2",
            "2",
            "1",
            "1",
            "",
            "1.4",
            "1.0",
            "0.55",
            "big,generic",
            "orange",
            "orange",
            "",
            "big, gross, bad flavor, bad texture"
        ],
        [
            "09-16-2022",
            "Trolli",
            "Sour Brite Crawlers",
            "4",
            "X",
            "3",
            "4",
            "4",
            "5",
            "",
            "4.0",
            "4.0",
            "0.71",
            "sour, coated, mealy",
            "",
            "overall",
            "",
            "chewy; a little mealy due to the coating/texture; kyle prefers the smoother gummies; seemed a bit stale?"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/blue",
            "strawberry-grape",
            "",
            "you can taste that artificial grape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange/green",
            "orange-lime",
            "",
            "sour, tart"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/yellow",
            "cherry-lemonade",
            "",
            "very good"
        ],
        [
            "09-19-2022",
            "Albanese",
            "Gummi Bears",
            "3",
            "X",
            "2",
            "5",
            "4",
            "4",
            "",
            "3.6",
            "4.0",
            "1.14",
            "soft, subtle, pretty",
            "",
            "overall",
            "",
            "great texture, a proper fresh gummy chew; wide variety of flavors; perfect texture and great flavors; they make Zach sad because they are just gummies at the end of the day and he is confused and letdown by the array and variety of flavors"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "white/light yellow",
            "pineapple",
            "",
            "good, nice strong flavor; jay's a fan; "
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "has a slight bitter aftertaste like a bit of the rind"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry",
            "",
            "tastes just like red jello"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "dark red",
            "cherry",
            "",
            "that was also good, a deep candy cherry flavor- only for matthew since there was only 1"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "grape",
            "",
            "jay is not a fan; kyle: grape. gross."
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry",
            "",
            "good - but only good for matthew bc there was only 1 in the bag"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light sage green",
            "watermelon",
            "",
            "watermelon but it took a while to figure out the greens; matthew likes it (ad infinitum)"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light yellow green",
            "lime",
            "",
            "it's okay, jay says; matthew likes it; kyle says that's lime!"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "dark green",
            "apple",
            "",
            "doesn't taste like anything; definitely not lime or strawberry, so it must be watermelon? no it's fucking apple?!"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "meyer lemon but gross; matthew thinks it's good, I don't disagree"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "peach/pink",
            "grapefruit",
            "",
            "herby and sweet; a bit astringent; it's interesting and good"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "medium yellow",
            "mango",
            "",
            "tastes like peach, but that's not a flavor in the pack"
        ],
        [
            "09-20-2022",
            "Trolli",
            "Sour Crunchy Crawlers",
            "1",
            "X",
            "3",
            "2",
            "2",
            "1",
            "",
            "2.0",
            "2.0",
            "0.8",
            "crunchy",
            "",
            "overall",
            "",
            "like a chewy sweet tart, like spree with a gummy on the inside; looks like dried clay; look like memphis school design - matthew; only the red is any good and the rest are disgusting"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "lemonade (exterior) bluberry (inside)",
            "",
            "bad"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "fruit punch (exterior); raspberry (interior)",
            "",
            "tastes very strongly of red flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "blackberry (exterior) lemondate (interior)",
            "",
            "also bad"
        ],
        [
            "09-21-2022",
            "Sathers",
            "Blue Sharks",
            "1",
            "X",
            "5",
            "2",
            "2",
            "3",
            "",
            "2.6",
            "2.0",
            "1.5",
            "big, marshmallow",
            "blue",
            "",
            "",
            "smells like playdough - zach; very rubbery/chewy; zach doesn't like the white part; one of kyle's favorites in general; a controversial gummi"
        ],
        [
            "10-03-2022",
            "Haribo",
            "Frogs",
            "2",
            "X",
            "4",
            "3",
            "3",
            "3",
            "",
            "3.0",
            "3.0",
            "0.7",
            "big, marshmallow, peachy",
            "green/white",
            "peach",
            "",
            "without knowing, tastes like green apple (kyle) or strawberry (matthew); are these the same as the Haribo Peaches? detailed moldwork"
        ],
        [
            "10-05-2022",
            "Jolly Rancher",
            "Gummies Sours",
            "2",
            "X",
            "3",
            "4",
            "4",
            "4",
            "",
            "3.4",
            "4.0",
            "0.9",
            "sour",
            "",
            "overall"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "watermelon",
            "",
            "strong, chemical and artificial, really tastes like a Jollly Rancher"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "green apple",
            "",
            "again, tastes exactly like a Jolly Rancher hard candy except it comes all at once instead of dissolving in the mouth over time"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry",
            "",
            "very strong artificial cherry, giving Matthew flashbacks to being a kid"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "tart, somewhat astringent lemon flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry",
            "",
            "more subdued, but maybe only seemingly so after the lemon"
        ],
        [
            "10-06-2022",
            "Trader Joe's",
            "Fruity Gummy Candies",
            "3",
            "X",
            "3",
            "4",
            "3",
            "3",
            "3",
            "3.2",
            "3.0",
            "0.4",
            "fruity, generic",
            "",
            "overall",
            "",
            "very well formed and good colors, texture is a little wierd, Kyle thinks they are more like \"fruit snacks\" as opposed to gummies"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "purple",
            "blueberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "watermelon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "grapefruit"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "pineapple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "mango"
        ],
        [
            "10-07-2022",
            "Trolli",
            "Sour Bursting Crawlers",
            "1",
            "X",
            "2",
            "2",
            "2",
            "2",
            "",
            "1.8",
            "2.0",
            "0.4",
            "big, gimmicky",
            "",
            "overall",
            "",
            "Zach was absoutely grossed out by these, but was also afraid gushers would make your head explode when he was a kid; gross but tolerable - kyle; bad, bad texture overall - matthew; not good at all - hillary"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "??",
            "",
            "more bursty than other ones; too much blue"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry",
            "",
            "cough syrup; kinda good, to Kyle; too gross for Hillary"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "??",
            "",
            "it's okay; uneven distribution of viscera - kyle steed"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "apparently there are no words, good or bad"
        ],
        [
            "10-10-2022",
            "Haribo",
            "Starmix",
            "X",
            "2",
            "3",
            "4",
            "Keto",
            "3",
            "",
            "3.0",
            "3.0",
            "0.8",
            "chewy, varied",
            "",
            "",
            "",
            "Contains most Haribo classics like Cola, bears, twin snakes, cherries, etc. though the rings are new; Haribo's have a real chewy, almost tough texture; not a lot of rings; Matthew loves the variety of the starmix, the spice of life"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "clear/orange",
            "???",
            "ring",
            "it's good, whatever it is"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/green",
            "???/ green strawberry",
            "ring",
            "it's good, whatever it is"
        ],
        [
            "10-12-2022",
            "Haribo",
            "Funtastic Mix",
            "X",
            "X",
            "X",
            "3",
            "3",
            "3",
            "",
            "3.0",
            "3.0",
            "0.0",
            "chewy, varied",
            "",
            "",
            "",
            "flavors correspond to classic Haribo gummies; Lots of shapes; shapes with different colors; classic Haribo texture; no robot gummy though it is on the bag; caterpillar is not on the bag but was in the bag; also learned etymology of oodle; this one is really random, like a bad Starmix, which contains all the favorites; jay says it's like putting three different gummy bears in your mouth, but it's only one gummy, plus marshmallow"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Green, with marshmallow",
            "",
            "Turtle"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Red, blue with marshmallow",
            "",
            "Butterfly"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Green with marshmallow",
            "",
            "Racecar"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Two tone, red and blue, orange and red",
            "",
            "Dinosaur (Steg, Rex, Bronto)",
            "it's good..."
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Single color, citrus",
            "",
            "Snakes (single)"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Pink and clear",
            "",
            "Flamingo"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Clear",
            "",
            "Airplane",
            "whatever the flavor of the clear Haribo gummy bear it's this; it's good"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Green with marshmallow",
            "",
            "Frogs"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Rainbow",
            "",
            "Caterpillar",
            "it's good because it's sugar and has that Haribo texture"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Red, white",
            "",
            "Whale"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Green, yellow",
            "",
            "Parrot"
        ],
        [
            "10-17-2022",
            "Skittles",
            "Gummies Original",
            "2",
            "X",
            "3",
            "3",
            "X",
            "3",
            "",
            "2.8",
            "3.0",
            "0.5",
            "mild, round",
            "",
            "",
            "",
            "Good enough, more like a Welch's fruit snack than other gummies; restrained/lacking flavors; Matthew likes the smaller form factor; we all would probably just get a bag of skittles instead"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "Tastes exactly like a yellow Skittle, which is not really like lemon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "Weak flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry",
            "",
            "Generally good, though Keith does not like it and it's the only one he tried"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "grape",
            "",
            "Gross and stronger than the other flavors - Hillary; Kyle likes it because it tastes exactly like a Skittle"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "green apple",
            "",
            "Not bad"
        ],
        [
            "10-18-2022",
            "Skittles",
            "Gummies Wild Berry",
            "2",
            "X",
            "3",
            "3",
            "X",
            "4",
            "",
            "3.0",
            "3.0",
            "0.8",
            "mild, round",
            "",
            "",
            "",
            "mild flavors; like old people skittles for when your teeth are falling out; can't ever recall eating regular wild berry skittles; better than the regular skittles gummies - hillary"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry",
            "",
            "mild but good"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "dark red",
            "cherry",
            "",
            "tastes medicinal, like cough syrup, cough drop"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "berry punch",
            "",
            "better than the regular skittle gummy purple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "teal",
            "raspberry",
            "",
            "keith likes the color; kyle says \"yeah!\""
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "melon berry",
            "",
            "good, not a real flavor, but good"
        ],
        [
            "10-19-2022",
            "Trolli",
            "Sour Brite Llamas",
            "3",
            "X",
            "3",
            "3",
            "X",
            "4",
            "",
            "3.3",
            "3.0",
            "0.5",
            "big, sour, coated",
            "",
            "",
            "",
            "all good, but the form factor is very weird; kyle does not like the sugar sour ones, does not like the mealiness; hillary really likes the passion fruit"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow/green",
            "passion fruit/pineapple",
            "",
            "unique new flavor; kyle likes it and he doesn't like trolli; passionfruit is unusual"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/blue",
            "strawberry/blueberry",
            "",
            "tasty!"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple/orange",
            "mango berry",
            "",
            "all good"
        ],
        [
            "10-20-2022",
            "Trader Joe's",
            "Tangy Turtles",
            "3",
            "X",
            "X",
            "3",
            "2",
            "2",
            "2",
            "2.4",
            "2.0",
            "0.5",
            "big, generic",
            "",
            "",
            "",
            "Form factor is much too big; base is not marshallow but a white gummy, possibly uses milk powder; takes a while to get to an actual taste; look expensive and good; why are they ring shaped? centers pecked out by seagulls or something?"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "purple/pink",
            "grape berry?",
            "",
            "much fruitier, but not tangy"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange/red",
            "orange berry?",
            "",
            "has a bitter orange rind undertone"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow/green",
            "lemon lime",
            "",
            "vaguely lemon limey, but not nearly sour or tangy enough for citrus"
        ],
        [
            "10-21-2022",
            "Lifesavers",
            "Gummies",
            "3",
            "X",
            "X",
            "3",
            "3",
            "2",
            "",
            "2.8",
            "3.0",
            "0.5",
            "mild",
            "",
            "",
            "",
            "smell so much more than they taste; very strong aroma; great texture and form factor; zach dislikes the flavor but would keep eating them"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "tastes like public bathroom soap - hillary; institutional soapy smell"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry",
            "",
            "cough syrup; medicinal smell; notes of floral cough syrup"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "strawberry",
            "",
            "hard to tell if it is strawberry or watermelon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light green",
            "green apple",
            "",
            "very artificial green apple; tastes like a jolly rancher; could be watermelon?"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "watermelon?",
            "",
            "pretty mild for watermelon"
        ],
        [
            "10-24-2022",
            "Kroger",
            "Juju Fish Candy",
            "1",
            "X",
            "2",
            "2",
            "1",
            "1",
            "",
            "1.4",
            "1.0",
            "0.5",
            "waxy, generic",
            "",
            "",
            "",
            "look like corn on the cob holders; very bright; made in mexico, but still taste like shit; chapstick like"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "",
            "",
            "cherry chapstick"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "",
            "",
            "softer than expected; taste likes dots; clay like; lime"
        ],
        [
            "11-01-2022",
            "Trolli",
            "Sour Gummi Creations",
            "3",
            "X",
            "3",
            "3",
            "X",
            "3",
            "2",
            "2.8",
            "3.0",
            "0.4",
            "sour, coated, gimmicky",
            "",
            "overall",
            "not related to flavor",
            "Gimmick: combine into martian UFOs; texture is like they have been in your car; strangely opaque, looks like they are made of playdo"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Iestyn)",
            "",
            "",
            "",
            "",
            "peach",
            "peach",
            "ring",
            "peach, better peach than the peach rings; texture is like they have been in your car"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry",
            "head",
            "flavor is really quite good"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry",
            "body",
            "blue raspberry; pretty good, not the usual mealiness of the trolli's for kyle; too pliant for guest"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "green apple",
            "",
            "least favorite for hillary; meh; mild for guest"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "watermelon",
            "",
            "tastes like mild watermelon bubblegum"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "standard lemon preserves; lemon curdy; too sour, could we have the least sour lemon"
        ],
        [
            "11-02-2022",
            "Jello",
            "Gummies",
            "2",
            "X",
            "2",
            "2",
            "X",
            "2",
            "",
            "2.0",
            "2.0",
            "0.0",
            "chewy, gimmicky",
            "",
            "overall",
            "",
            "Look nothing like the package; like gummy nematoads; gummy leeches;texture is similar to haribo, but flavorwise, it's trash; why is the shape like this - it looks like the o in the jello logo; doesn't jiggle jiggle"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry",
            "",
            "is this supposed to taste like jello? tastes like nothing like strawberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "berry blue",
            "",
            "also disappointing and generic"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "lime",
            "",
            "meh, only a single one in the entire package"
        ],
        [
            "11-03-2022",
            "Kasugai Fruitia",
            "Gummy Candy",
            "4",
            "2",
            "4",
            "5",
            "X",
            "5",
            "4",
            "4.0",
            "4.0",
            "1.1",
            "fancy, fruity, subtle, import, jp, round",
            "",
            "overall",
            "",
            "individually wrapped; meant to be savored; not designed for scarfing and binging; tasteful colors - keith; subtle and tasteful and sophisticated; an admirable gummy, but still a 2 - keith"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "pink",
            "lychee",
            "",
            "not overwhelming, an appropriate amount of lychee smell and flavor; delicious"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "mango",
            "",
            "sweet and fragrant; subtle; perfectly fruity"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry",
            "",
            "like real strawberry, firm; not as well liked as the mango"
        ],
        [
            "11-07-2022",
            "Huer",
            "Sour Peach Slices",
            "3",
            "2",
            "3",
            "3",
            "X",
            "3",
            "",
            "2.8",
            "3.0",
            "0.4",
            "import, ca, pretty, gritty, tough, import",
            "",
            "peach",
            "",
            "made with real fruit juice apparently; has a little tiny line to denote the peachy; not sour, just gritty/sugary; peachy gradient is very nice aesthetic; hillary has really come around to peach flavored candy; should be more sour"
        ],
        [
            "10-31-2022",
            "Essential Everyday",
            "Gummi Peach Rings",
            "2",
            "X",
            "4",
            "3",
            "3",
            "3",
            "",
            "3.0",
            "3.0",
            "0.7",
            "gritty, big, pretty, generic",
            "",
            "peach",
            "",
            "from City Market; nice peachy gradient colors; has a bottom yellow ring but it is not marshmallow, great flavor, but very gritty"
        ],
        [
            "11-08-2022",
            "Haribo",
            "Hey Kakao!",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "import, de, marshmallow, nongummy",
            "",
            "",
            "",
            "German Haribo; import label is incorrect with how many calories are in the candies; these are not gummies; chocolate flavored protein powder flavor; no bite, very soft; vegetarian; maybe good with your cocoa or coffee"
        ],
        [
            "11-08-2022",
            "Haribo",
            "Sour S'ghetti",
            "3",
            "X",
            "3",
            "3",
            "3",
            "2",
            "",
            "2.8",
            "3.0",
            "0.4",
            "coated, sour, tough",
            "",
            "",
            "",
            "Actually a gummy; tough and sticky; generally well liked though we cannot remove them from our teeth; kyle likes the form factor, but it is a bit offputting for others, a bit weird; zach likes the weird cthulu/spaghetti monster on the logo"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry",
            "",
            "vague flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "green apple",
            "",
            "it's green!"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blueberry",
            "",
            "doesn't really taste like blueberry, but good; kyle's favorite"
        ],
        [
            "11-14-2022",
            "Lifesavers",
            "Gummies Sours",
            "4",
            "X",
            "2",
            "3",
            "3",
            "3",
            "",
            "3.0",
            "3.0",
            "0.7",
            "fragrant, gritty",
            "",
            "",
            "",
            "zach has very high hopes; bitter aftertaste; great flavors with sour kick; mch better than the other lifesavers gummies for Hillary; good but not quite a 4 for matthew b/c of weird aftertaste"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "watermelon",
            "",
            "not lime but watermelon; jolly rancher flavor to it"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "great texture, strong flavor brought out by the sourness of the citrus"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry",
            "",
            "strawberry lemonade due to the sourness; strawberry with a kick"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry",
            "",
            "jolly rancherish, less like medicinal "
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "black raspberry",
            "",
            "so sour, delicious; but is black raspberry a thing? is that not just a blackberry?"
        ],
        [
            "11-15-2022",
            "Haribo",
            "Color-Rado",
            "3",
            "X",
            "2",
            "2",
            "2",
            "2",
            "",
            "2.2",
            "2.0",
            "0.4",
            "import, de, varied",
            "",
            "",
            "",
            "insane mix; contains licorice, bat and frog shapes and fruits; turns out we don't really like black licorice; has some good options in there, but is ruined by the black licorice; something for everyone; kyle thinks only adults can enjoy black licorice; has some good stuff but the licorice is bad; hodge podge that is poorly curated"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "frogs",
            "green frog has a strange flavor, unknowable; tinged by the licorice; earthy colors"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "bats",
            "lemony bat wings; body is licorice; gross"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "licorice sandwich",
            "ew, chocolate and licorice?"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "cylinder",
            "it's licorice! like really really licorice!"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "egg",
            "fluffy on the inside? lemony cream; vanilla caramel?"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "fruit",
            "orange and lemon flavors; kinda large,  very tough"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "gummy bear",
            "it's a gummy bear!"
        ],
        [
            "11-18-2022",
            "Haribo",
            "Super Gurken",
            "2",
            "X",
            "3",
            "2",
            "3",
            "3",
            "",
            "2.6",
            "3.0",
            "0.5",
            "import, de, big, coated, tough, flat",
            "",
            "overall",
            "",
            "a flat image of a pickle rendered as a gummy? very weird shape and huge; very sticky; like contact cement - says jay; large size and chewiness make them difficult to eat; gurken - awesome cucumbers, vegan"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "raspberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "strawberry",
            "",
            "no distinct flavor; kyle does not like the flavor, though jay does"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "",
            "",
            "mild lemony flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "vague candy flavor; tastes \"red\""
        ],
        [
            "11-21-2022",
            "Trader Joe's",
            "Scandinavian Tidings",
            "2",
            "X",
            "3",
            "3",
            "3",
            "3",
            "",
            "2.8",
            "3.0",
            "0.4",
            "soft, chewy, generic",
            "",
            "",
            "",
            "scandinavian swimmers but for the holidays; fewer flavors though; weird holiday white grape flavors"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry",
            "ornament",
            "same as the lobster in standard scandinavian swimmers; hint of cherry wax flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "clear/white",
            "sparkling grape",
            "star",
            "champagne? pineapple? Jay doesn't like it, zach doesn't think it's champagne"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "apple",
            "tree",
            "what flavor is this? green apple? not pine"
        ],
        [
            "11-22-2022",
            "Haribo",
            "Bunte Schnecken",
            "3",
            "X",
            "5",
            "3",
            "4",
            "4",
            "",
            "3.8",
            "4.0",
            "0.8",
            "import, de, gimmicky, subtle, big, chewy",
            "",
            "",
            "",
            "resemble an old-fashioned candy you would go to the soda shoppe for; kyle likes the format, unrolling it; colorful snails; not for a candy cynic who cannot see or feel joy anymore like zach; kinda fun to unwind; candies are too big when you only get half of the flavors"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "",
            "",
            "none in bag"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "",
            "",
            "none in bag"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "mild lemon flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "brown",
            "cola",
            "",
            "kinda like the cola gummies, but the string texture is bizarre"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "peach, passionfruit",
            "",
            "definitely peachy, but also pretty mild"
        ],
        [
            "11-16-2022",
            "Haribo",
            "Pinkie & Lilly",
            "2",
            "X",
            "4",
            "2",
            "4",
            "3",
            "",
            "3.0",
            "3.0",
            "1.0",
            "import, big, marshmallow, pretty, chewy",
            "",
            "",
            "",
            "pink and purple colors remind us of vaporwave; very vibrant colors and shape remind of us tamagotchi toys; foam is actually quite inoffensive and pretty; mild flavors; german/euro gummies seem to have a much more subtle and real flavor; form factor is too big; texture \"feels like a tongue\" according to Jay"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "raspberry",
            "",
            "kyle thinks this is very good; everyone agrees this is the superior flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "blackberry",
            "",
            "tough and chewy, tastes a bit herbal"
        ],
        [
            "11-17-2022",
            "Starburst",
            "Airs",
            "2",
            "X",
            "2",
            "3",
            "3",
            "2",
            "",
            "2.4",
            "2.0",
            "0.5",
            "soft, marshmallow",
            "",
            "",
            "",
            "bizarre and disturbingly fleshy looking; based on the original starburst flavors; 'actual size on package is smaller than the gummies; opaque, not translucent/jewel-like like most gummies; creamsicle smell and flavors; would much rather just buy original starburst"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry",
            "",
            "very similar taste to the red starburst"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "",
            "tastes very similar to the lemon original starburst, but with a creamy undertone"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry",
            "",
            "exact taste of the pinks starburst"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "looks like flesh; orange cream flavor; creepy"
        ],
        [
            "11-23-2022",
            "Lifesavers",
            "Collisions",
            "3",
            "X",
            "3",
            "4",
            "3",
            "3",
            "",
            "3.2",
            "3.0",
            "0.4",
            "fragrant, gimmicky, soft, round",
            "",
            "",
            "",
            "matthew can taste the rainbow, but the rest of use are a bit confused and underwhelmed by the flavors; same texture and form as the other lifesavers; a safe gummy bet, with a gimmicky twist"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/yellow",
            "raspberry/lemon",
            "",
            "tastes a bit lemony; but not a lot of flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light green/blue",
            "pineapple/punch",
            "",
            "tastes like fruit punch more than pineapple, except to matthew who has superior taste buds; if you eat just the pineapple half, you can taste that flavor, bu the punch is overwhelming"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green/red",
            "watermelon/cherry",
            "",
            "flavors are distinct; tastes like a watermelon jolly rancher to jay"
        ],
        [
            "11-28-2022",
            "Kroger",
            "Gummi Bears",
            "3",
            "X",
            "2",
            "3",
            "3",
            "3",
            "",
            "2.8",
            "3.0",
            "0.4",
            "subtle, generic",
            "",
            "",
            "",
            "Who makes these generic grocery store gummies? Product of Mexico, reminds Zach of the Albinese based on the texture and flavors; many different colors/flavors; kronenberg like melti multi gummi; gummy bears are wearing sunglasses; flavors are very short-lived and dissipate quickly; blooms, but fades, like a memory; texture is much firmer than expected; safeway select also seem to have the same shape and flavors/colors and ingredient generics"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light lemon yellow",
            "lime",
            "",
            "kinda gross lime"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "golden yellow",
            "lemon",
            "",
            "fine, nothing to write home about"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "it's orange! the most common one"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry",
            "",
            "\"whoa, that's cherry\" - the only intense flavor; cherry soap - zach; reminds kyle of cherry otter pop"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light green",
            "watermelon",
            "",
            "sharp taste"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "dark green",
            "apple",
            "",
            "pretty strong apple flavor, though it is subtle for kyle; tougher than expected"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry?",
            "",
            "mildest blue raspberry we've ever tried; doesn't stick with you"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "grapefruit",
            "",
            "only 2; like grape soda"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light pink yellow",
            "pineapple",
            "",
            "flavor sticks around, tastes very artificial; but matthew and hillary like it; pina colada"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light pink",
            "grapefruit",
            "",
            "is this grapefruit? has a slight bitter/citrus taste"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "dark pink",
            "strawberry",
            "",
            "it's strawberry but jammy and very fake tasting"
        ],
        [
            "11-29-2022",
            "Haribo",
            "The Smurfs",
            "2",
            "X",
            "3",
            "3",
            "",
            "2",
            "",
            "2.5",
            "2.5",
            "0.6",
            "chewy",
            "",
            "",
            "",
            "hat color extends over the entire head; doesn't really taste like anything, other than sugar; smurf theme give them 1/2 more for kyle; hillary doesn't like smurfs so meh; closest we've ever had to unflavored gummies"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "white",
            "strawberry",
            "",
            "resin color, we guess it's strawberry, sure"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "raspberry",
            "",
            "can't tell flavor very much"
        ],
        [
            "12-01-2022",
            "Haribo",
            "Pico-Balla",
            "2",
            "X",
            "3",
            "3",
            "2",
            "2",
            "3",
            "2.5",
            "2.5",
            "0.5",
            "import, de, nongummy",
            "",
            "",
            "",
            "Three colors including the exterior shell per candy piece; nano-balla would be 10k times larger; generally favorable; outside is a gummy, but the interior is definitely not; like licorice without being licorice"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "red",
            "berry",
            "",
            "berry, red fruit by the foot"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "fruity",
            "",
            "fruity, weird texture"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "citrusy",
            "",
            "generally fruity"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "citrusy",
            "",
            "generally fruity"
        ],
        [
            "12-02-2022",
            "Trolli",
            "Sour Brite Octopus",
            "3",
            "X",
            "4",
            "4",
            "X",
            "4",
            "",
            "3.8",
            "4.0",
            "0.5",
            "import, sour, coated, big",
            "",
            "",
            "",
            "very big form factor; a lot all at once; good sweet/sour ratio; keith likes the two tone color scheme; harder than the sour brite crawlers and less mealy; Trolli at its best; eat a single tentacle one at a time"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/purple",
            "cherry/grape",
            "",
            "very good"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange/yellow",
            "orange/lemon",
            "",
            "hillary's favorite"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green/blue",
            "strawberry/blue raspberry",
            "",
            "nice flavor"
        ],
        [
            "12-05-2022",
            "Airheads",
            "Xtremes Bites",
            "3",
            "X",
            "3",
            "3",
            "4",
            "3",
            "",
            "3.2",
            "3.0",
            "0.4",
            "coated, tough",
            "",
            "",
            "",
            "one flavor, rainbow berry; small form factor; has a rainbow of colors; very slightly sour, not as sour as we had hoped; fruit by the foot red flavor notes; kyle hates that the colors of the rainbow are in the wrong order, the colors are in the right order on the package, but not on the candy itself"
        ],
        [
            "12-06-2022",
            "Trolli",
            "Sweet Peachie Os",
            "2",
            "X",
            "4",
            "2",
            "4",
            "3",
            "",
            "3.0",
            "3.0",
            "1.0",
            "big, coated, tough",
            "",
            "",
            "",
            "quite good, could be stronger flavored; very sweet; a bit chalky - jay; smells bad, tastes good; crazy mascot on bag is a big draw, like the Gritty of peach rings"
        ],
        [
            "12-06-2022",
            "Albanese",
            "Peach Rings",
            "2",
            "X",
            "2",
            "3",
            "4",
            "3",
            "",
            "2.8",
            "3.0",
            "0.8",
            "big, coated",
            "",
            "",
            "",
            "also smells weird, but better than the trolli; springy; kinda creamy flavor; highly tolerable, almost maybe good; very sweet; inconsistently stamped (some are just disks or have off-center shapes); smoother than the trolli; gummier texture with pectin"
        ],
        [
            "12-07-2022",
            "Haribo",
            "The Smurfs Sour",
            "4",
            "X",
            "4",
            "3",
            "2",
            "4",
            "",
            "3.4",
            "4.0",
            "0.9",
            "chewy, sour",
            "",
            "",
            "",
            "all are the same flavor with different colors for the gummy; tough and chewy; sour flavor drastically improves these over the non-sour version; the right amount of grit; we are still confused if these are supposed to all be the same flavor or different flavors; nice sourness to them and are actually sour!"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "white",
            "",
            "",
            "no noticeable flavor difference"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "",
            "",
            "no noticeable flavor difference"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blond",
            "",
            "",
            "whoa that's tough, whoa- kyle; "
        ],
        [
            "11-30-2022",
            "Trolli",
            "Evil Twins",
            "4",
            "X",
            "3",
            "4",
            "4",
            "4",
            "",
            "3.8",
            "4.0",
            "0.4",
            "chewy, sour",
            "",
            "",
            "",
            "a double rip-off cross-breed of Twin Snakes and Sourpatch Kids; Made by Trolli but lack the typical mealy texture and sour coating; one half opaque and one half transparent; are you supposed to eat the halves separately or all together?; overall the flavors match their names and are quite good; only mildly sour; not gritty at all; overall very good, but unexpected from Trolli"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/blue",
            "sweet strawberry/rowdy blue raspberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/yellow",
            "charming cherry/wicked lemon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green/orange",
            "angelic orange/mischievious lime"
        ],
        [
            "12-12-2022",
            "Albanese",
            "Gummi Worms",
            "3",
            "X",
            "3",
            "4",
            "X",
            "4",
            "",
            "3.5",
            "3.5",
            "0.6",
            "pretty, chewy",
            "",
            "",
            "",
            "very fragrant; two flavors at once which is adventurous for albanese; purple looks gray; a bit of a letdown after such a strong reception of Albanese in the past; great texture; well sized; the dual flavors isn't great and the flavors get lost; flavor combos feel forced and random; good caustics"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow/pink",
            "grapefruit/pineapple",
            "",
            "can't really tell what the flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green/purple",
            "grape/apple",
            "",
            "weird flavor combo"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange/white",
            "orange/white grape?",
            "",
            "white grape? pineapple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/yellow",
            "lemon/raspberry",
            "",
            "cherry lemonade; raspberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/green",
            "cherry/lime?"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light green/sea green",
            "blue raspberry",
            "",
            "that's weird, what the hell is that - kyle; blue raspberry and oregano - zach"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "sky blue/blue",
            "blue raspberry",
            "",
            "only one in the package; difficult to discern flavor"
        ],
        [
            "12-09-2022",
            "Trolli",
            "Sour Brite Trees",
            "3",
            "X",
            "3",
            "3",
            "3",
            "3",
            "",
            "3.0",
            "3.0",
            "0.0",
            "gimmicky, coated",
            "",
            "",
            "tree",
            "standard trolli flavors and colors; less mealy than the worms; good distribution of colors/flavors in the package; red, yellow, orange, red, blue colors; flavors consistent and on-target"
        ],
        [
            "12-09-2022",
            "Trolli",
            "Sour Reindeer Poops",
            "3",
            "X",
            "3",
            "3",
            "3",
            "3",
            "",
            "3.0",
            "3.0",
            "0.0",
            "gimmicky, coated",
            "",
            "",
            "poop",
            "poops look like poop emoji; mold shape is pretty similar to the tree; kyle and jay prefer the poop form factor over the trees"
        ],
        [
            "12-14-2022",
            "Werner's",
            "Raspberry Sour Belts",
            "3",
            "X",
            "3",
            "5",
            "5",
            "3",
            "",
            "3.8",
            "3.0",
            "1.1",
            "coated, flat",
            "",
            "",
            "belt",
            "blue despite being raspberry; cut to varying lengths; very coated; very sour; it's fruit by the foot jerky; good flavor, sour; a continuous length of gummy without any fun shapes; if there was another flavor or different shape, Zach might upgrade the score"
        ],
        [
            "12-15-2022",
            "Sour Punch",
            "Bites",
            "2",
            "X",
            "3",
            "3",
            "X",
            "2",
            "",
            "2.5",
            "2.5",
            "0.6",
            "coated, sour",
            "",
            "",
            "",
            "strong artificial candy flavor; very sour; weird texture like wheat paste - matthew; shaped like weird bad pasta; citric, malic, tartaric acid, it's all the candy acids!"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "green apple",
            "",
            "a more natural green color"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry",
            "",
            "sour"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry",
            "",
            "gross"
        ],
        [
            "1-4-2023",
            "Sour Patch",
            "Kids Cherry",
            "2",
            "X",
            "4",
            "3",
            "X",
            "3",
            "",
            "3.0",
            "3.0",
            "0.8",
            "small, sour, round",
            "red",
            "cherry",
            "round",
            "new limited edition varietal; quite small in size, smaller than a gummy bear; not very sour; if you want that sour bite, you need to eat at least two or three at once; cough medicine cherry taste, which kyle apparently likes or cannot taste"
        ],
        [
            "1-5-2023",
            "Peko Gummy",
            "Purple Grape",
            "2",
            "X",
            "3",
            "4",
            "X",
            "3",
            "",
            "3.0",
            "3.0",
            "0.8",
            "import, jp, chewy",
            "purple",
            "grape",
            "",
            "very pungent aroma like medicinal grape cough syrup or cold medicine; grape must; shape like little girl faces, highly detailed molds; very chewy; covered with a light sweet dust; nice resealable packaging"
        ],
        [
            "1-9-2023",
            "Kasugai Fruitia",
            "Fruits Gummy Candy",
            "3",
            "X",
            "3",
            "4",
            "X",
            "4",
            "",
            "3.5",
            "3.5",
            "0.6",
            "import, jp, pretty, small",
            "",
            "",
            "",
            "pale pastels are very classy with a lovely diffusion effect; gummy size is smaller than the usual individuall packaged versions in a larger bag; sample size is quite small, each bag has may be 7 gummies in it; the larger form factor is preferred"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "apple",
            "",
            "very strong aroma; slightly more natural flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "muscat grape",
            "",
            "smells like Bath and Body Works muscat grape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "peach",
            "",
            "subtle aroma; tastes like dried apricots or nectarine"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "red grape",
            "",
            "akin to welch's grape fruit snack"
        ],
        [
            "01-20-2023",
            "Kasugai",
            "Tsubugumi",
            "2",
            "3",
            "3",
            "4",
            "4",
            "4",
            "4",
            "3.4",
            "4.0",
            "0.8",
            "import, jp, pretty",
            "",
            "",
            "",
            "like a gummy inside a jellybean; excellent flavors that actually taste like the fruit they represent; zach doesn't care for the candy shell, but it is much better than an American jelly bean"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan",
            "",
            "",
            "",
            "",
            "white",
            "yuzu",
            "",
            "yuzu flavored; citrus"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "mikan",
            "",
            "tangerine, very fruity"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "iyokan",
            "",
            "kinda grapefruity, a little elderflower"
        ],
        [
            "01-20-2023",
            "UHA",
            "Kogumi",
            "2",
            "3",
            "3",
            "5",
            "3",
            "4",
            "3",
            "3.3",
            "3.0",
            "1.0",
            "import, jp, small, cola",
            "",
            "",
            "",
            "very small gummies; small gummy, big flavor; good chewiness; so cute and taste great; \"much too small\" - zach, he is annoyed by how small they are"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan",
            "",
            "",
            "",
            "",
            "red/brown",
            "cola",
            "",
            "strong cola flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "soda",
            "",
            "ramune flavor concentrated"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "white",
            "white soda",
            "",
            "calpis flavored"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "melon soda",
            "",
            "what it says on the gummy tin; very good"
        ],
        [
            "01-20-2023",
            "Bandai",
            "Wine Gummy Rose of Versailles",
            "2",
            "X",
            "2",
            "2",
            "1",
            "1",
            "2",
            "1.7",
            "2.0",
            "0.5",
            "import, jp, jelly",
            "dark red",
            "\"wine\"",
            "",
            "flavored like red wine; have a weird jelly inside and taste like moldy grapes; don't taste like wine; tried to make it astringent like alcohol without alcohol and therefore it's gross"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan"
        ],
        [
            "01-20-2023",
            "Kabaya",
            "Tough Gummy NEO",
            "3",
            "X",
            "3",
            "4",
            "3",
            "4",
            "3",
            "3.3",
            "3.0",
            "0.5",
            "import, jp, tough, cube",
            "orange",
            "energy drink",
            "",
            "energy drink flavor; caffienated; definitely tough; coated and sour; 90g of caffiene per bag; cube form is very fun; zach finds them too difficult to eat"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan"
        ],
        [
            "01-20-2023",
            "Meiji",
            "Yoglet",
            "2",
            "X",
            "2",
            "3",
            "2",
            "2",
            "3",
            "2.3",
            "2.0",
            "0.5",
            "import, jp",
            "white",
            "yogurt",
            "",
            "yogurt flavor with a yogurt center; good texture; interesting flavor; contains extra calcium and collagen; tastes like drinking yogurt; yocult; weird dairy sourish; \"No\" - Zach"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan"
        ],
        [
            "01-26-2023",
            "Kabaya",
            "Tough Gummy",
            "5",
            "X",
            "4",
            "4",
            "4",
            "4",
            "4",
            "4.2",
            "4.0",
            "0.4",
            "import, jp, tough, cube, cola",
            "",
            "",
            "",
            "good distribution of colors, unlike American gummies; at what point is a gummy too tough?; too rugged \"are these for men?\"; gummies for men, "
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan",
            "",
            "",
            "",
            "",
            "orange",
            "energy drink",
            "",
            "tastes kinda like red bull, pretty good"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "brown",
            "cola",
            "",
            "cola flavor, quite good"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "soda",
            "",
            "ramune soda flavor; a lightly citrusy sprite type of flavor"
        ],
        [
            "01-26-2023",
            "Candemina",
            "Super Best",
            "3",
            "X",
            "4",
            "3",
            "3",
            "3",
            "3",
            "3.2",
            "3.0",
            "0.4",
            "import, jp, tough, cola",
            "",
            "",
            "",
            "dense; tougher and thinner than the TOUGH Gummy; very sour); sour has the power - zach; similar to the tough gummy but not as good; not good without the coating"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan",
            "",
            "",
            "",
            "",
            "red",
            "grape",
            "",
            "strange "
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "brown",
            "cola",
            "",
            "not as good as the tough gummy cola flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "ramune",
            "",
            "tastes like bubblegum once coating is gone"
        ],
        [
            "01-26-2023",
            "UHA",
            "Kororo",
            "2",
            "X",
            "2",
            "2",
            "2",
            "2",
            "3",
            "2.2",
            "2.0",
            "0.4",
            "import, jp, gimmicky",
            "purple",
            "grape",
            "",
            "grapes with skin and a gummy center; a softer gummy; very weird; flavor is fine, but the skin is strange; visually looks like a hot dog that is so short that it's a ball, but purple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan"
        ],
        [
            "01-26-2023",
            "UHA",
            "Reset",
            "2",
            "X",
            "3",
            "4",
            "3",
            "3",
            "4",
            "3.2",
            "3.0",
            "0.8",
            "import, jp",
            "purple",
            "grape",
            "",
            "very sour without a coating; extremely strong grape flavor; tough gummy"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan"
        ],
        [
            "02-07-2023",
            "Gustaf's",
            "Jelly Babies",
            "2",
            "X",
            "2",
            "2",
            "1",
            "X",
            "",
            "1.8",
            "2.0",
            "0.5",
            "import, uk",
            "purple",
            "blueberry",
            "",
            "overly sweet, mushy texture, unsettling baby shape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "peach"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "citrus"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "raspberry"
        ],
        [
            "02-08-2023",
            "Nippon Eeru",
            "Melon Gummy",
            "3",
            "X",
            "4",
            "4",
            "4",
            "X",
            "4",
            "3.8",
            "4.0",
            "0.4",
            "import, jp",
            "orange",
            "yubari canteloupe",
            "",
            "good flavor, good texture"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan"
        ],
        [
            "02-08-2023",
            "Bourbon",
            "Sharimoni Gummy",
            "1",
            "X",
            "2",
            "4",
            "2",
            "X",
            "4",
            "2.6",
            "2.0",
            "1.3",
            "import, jp, coated",
            "white",
            "yogurt",
            "",
            "tart yogurt flavor with crunchy coating, strange shape, \"good execution of a bad idea\""
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan"
        ],
        [
            "02-08-2023",
            "Kanro",
            "Puré Ring",
            "3",
            "X",
            "3",
            "3",
            "3",
            "X",
            "3",
            "3.0",
            "3.0",
            "0.0",
            "import, jp",
            "green",
            "apple",
            "",
            "different ring shapes, good flavor but low springiness and more of a mushy texture than a proper gummy"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan",
            "",
            "",
            "",
            "",
            "purple",
            "grape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "peach"
        ],
        [
            "02-09-2023",
            "Kanro",
            "Puré Grape Gummy",
            "",
            "X",
            "5",
            "4",
            "5",
            "X",
            "5",
            "4.8",
            "5.0",
            "0.5",
            "import, jp",
            "purple",
            "grape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan"
        ],
        [
            "02-10-2023",
            "Haribo",
            "Primavera Erdbereen",
            "3",
            "X",
            "3",
            "3",
            "3",
            "2",
            "",
            "2.8",
            "3.0",
            "0.4",
            "import, de, nongummy",
            "pink",
            "strawberry",
            "",
            "strawberry and cream, very sweet, not a gummy, tartness works"
        ],
        [
            "02-13-2023",
            "Trolli",
            "Sweet Squiggles",
            "3",
            "X",
            "3",
            "3",
            "3",
            "X",
            "",
            "3.0",
            "3.0",
            "0.0",
            "big, mealy",
            "",
            "",
            "",
            "okay flavor, texture mushy and not springy enough, sweet not sour, doesn't seem like a Trolli, fine but not a first gummy worm choice"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/white",
            "strawberry/grape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green/yellow",
            "lemon/lime"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/orange",
            "cherry/orange"
        ],
        [
            "02-15-2023",
            "Haribo",
            "Ginger Lemon",
            "4",
            "X",
            "3",
            "4",
            "2",
            "X",
            "",
            "3.3",
            "3.5",
            "1.0",
            "import, de",
            "yellow",
            "ginger lemon",
            "",
            "ginger spice along with sweet and sour, a little bit medicinal but not in a bad way, addictive"
        ],
        [
            "02-21-2023",
            "Yupo",
            "Süpper Jeli",
            "4",
            "X",
            "4",
            "4",
            "4",
            "X",
            "",
            "4.0",
            "4.0",
            "0.0",
            "import, tr",
            "yellow",
            "lemon",
            "",
            "good flavor, can taste a little bit of pith, good gummy feel (due to animal gelatin), Zach could have used more sour"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "dark orange",
            "tangerine"
        ],
        [
            "02-24-2023",
            "Okiko",
            "Shikuwasa Gumminchu",
            "4",
            "X",
            "X",
            "5",
            "4",
            "X",
            "5",
            "4.5",
            "4.5",
            "0.6",
            "import, jp, pretty, cube",
            "bright green",
            "shikuasa (citrus)",
            "",
            "very pretty green translucent cube form factor, okinawan shikuasa citrus flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan"
        ],
        [
            "02-24-2023",
            "Lotte",
            "Little Fit’s Big Gumi",
            "2",
            "X",
            "X",
            "4",
            "4",
            "4",
            "4",
            "3.6",
            "4.0",
            "0.9",
            "import, jp, cube",
            "purple",
            "kyoho grape",
            "",
            "two kinds of grape, the kyoho is hard and the muscat is soft, Zach is tired of grape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan",
            "",
            "",
            "",
            "",
            "green",
            "muscat grape"
        ],
        [
            "02-24-2023",
            "UHA",
            "Mizugumi",
            "X",
            "X",
            "X",
            "3",
            "3",
            "4",
            "3",
            "3.3",
            "3.0",
            "0.5",
            "import, jp",
            "clear",
            "grape",
            "",
            "somewhat nondescript clear gummy with a grape flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Jonathan"
        ],
        [
            "02-27-2023",
            "Warheads",
            "Galactic Mix Cubes",
            "4",
            "X",
            "2",
            "3",
            "3",
            "4",
            "",
            "3.2",
            "3.0",
            "0.8",
            "cube, sour",
            "black/red",
            "cosmic punch",
            "",
            "tasty but it will turn your entire mouth and lips blue, is it worth it??"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "black/blue",
            "rocket raspberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "black/purple",
            "black hole cherry"
        ],
        [
            "03-03-2023",
            "Puchao",
            "Bubbly Soda",
            "2",
            "X",
            "3",
            "3",
            "3",
            "3",
            "",
            "2.8",
            "3.0",
            "0.4",
            "import, nongummy, jp, cola",
            "brown",
            "cola",
            "",
            "not a gummy; bizarre taffy gummy combo; very hard to chew; not visually appealing and contain weird tiny gummies in the taffy; like a pill pocket for your pet"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "ramune"
        ],
        [
            "03-03-2023",
            "Kasugai",
            "Yuzu Gummy Candy",
            "5",
            "X",
            "4",
            "5",
            "4",
            "4",
            "",
            "4.4",
            "4.0",
            "0.5",
            "import, jp, pretty, round",
            "yellow",
            "yuzu",
            "",
            "yuzu is delicious; gummy is perfect, per usual with kasugai; we dipped into these early; wonder if we can grow yuzu here; zach likes the most of the citrus flavors; too classy and subtle for jay; even keith had one of these, so you know it's good"
        ],
        [
            "03-06-2023",
            "Haribo",
            "Berry Clouds",
            "3",
            "X",
            "3",
            "4",
            "3",
            "3",
            "",
            "3.2",
            "3.0",
            "0.4",
            "big, pretty, marshmallow",
            "purple",
            "wildberry",
            "",
            "look like little nigiris, which is weird cuz that's fish; three layers, gummy, marshmallow, more marshmallow; pretty good for a marshmallow one; a bit too big; lightly coated, but not sour; bit if a bathbomb vibe from the purple one; looks like soap you can eat, but you can eat it; blue one tastes like soap"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blueberry"
        ],
        [
            "03-08-2023",
            "Welch's",
            "Fruit Snacks",
            "4",
            "2",
            "3",
            "3",
            "3",
            "4",
            "",
            "3.2",
            "3.0",
            "0.8",
            "small",
            "",
            "",
            "",
            "too small for Zach's giant mouth; fruit snacks are not healthy; small bc they are for children; waxy flavor to them;all are pretty much par for the course with the flavors; packaging makes you feel good about eating these rather than candy; we remember these being amazing as children, but not so much as an adult; just eat candy; molds are very detailed; bad american version of kasugai gummies"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light orange",
            "peach"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "grape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange",
            "",
            "barely citrusy; only a bit on the aftertaste"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "raspberry"
        ],
        [
            "03-08-2023",
            "Kasugai",
            "Kiwi Gummy Candy",
            "5",
            "X",
            "4",
            "4",
            "5",
            "5",
            "X",
            "4.6",
            "5.0",
            "0.5",
            "round, import, jp, pretty",
            "green",
            "kiwi",
            "",
            "\"Yeah these are fucking great\"; stronger flavor than the Yuzu; Zach's favorite of Kasugai; translucent and green; contain real kiwi seeds, which is insane; made with kiwi sauce ingredient"
        ],
        [
            "03-10-2023",
            "Gushers",
            "Flavor Mixers",
            "2",
            "X",
            "4",
            "3",
            "X",
            "3",
            "2",
            "",
            "",
            "",
            " sour, jelly",
            "",
            "",
            "",
            "some of us got a gush and some got a dribble; flavors are strong and pretty good, but the exterior texture is not pleasant and more like a gummy; it's fruit roll-up with liquid inside; \"it would be better if they didn't gush\" - zach"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Nelson)",
            "",
            "",
            "",
            "",
            "red",
            "raspberry lemonade",
            "",
            "good juicy flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry peach",
            "",
            "dryer, less gush; the comobo is much nicer"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange cherry",
            "",
            "tastes just like it did in high school"
        ],
        [
            "03-13-2023",
            "Welch's",
            "Island Fruit Snacks",
            "3",
            "X",
            "2",
            "3",
            "X",
            "3",
            "",
            "2.8",
            "3.0",
            "0.5",
            "small",
            "",
            "",
            "",
            "less chalky, less firm; eating two at a time to get the maximum flavor effect; it's work to eat these - kyle; slightly fresher but flavors are not as good as the original"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "mango",
            "",
            "a bit chewier; kyle does not like flavor; vaguely mango"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "grapefruit",
            "",
            "looks like the orange slice, very subtle grapefruit; not bitter"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry",
            "",
            "cough syrupy"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "dark red",
            "pineapple",
            "",
            "why is this dark red; doesn't taste like pineapple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "passion fruit",
            "",
            "weird flavor on the aftertaste"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "island kiwi",
            "",
            "way too soft"
        ],
        [
            "03-14-2023",
            "Little Jasmine",
            "Fruit Jelly Candies",
            "2",
            "X",
            "3",
            "3",
            "X",
            "2",
            "",
            "2.5",
            "2.5",
            "0.6",
            "small, import, tw",
            "",
            "",
            "",
            "look pretty; more of a jelly than a gummy; lime flavored; individually packaged; weirdly bitter at first with a nicer flavor later on; not so bad - matthew"
        ],
        [
            "03-14-2023",
            "Foreign Candy Company",
            "Rips",
            "3",
            "X",
            "3",
            "4",
            "X",
            "4",
            "",
            "3.5",
            "3.5",
            "0.6",
            "flat, sour, coated",
            "",
            "",
            "",
            "pretty standard run-of-the-mill flavor; very similar to haribo sour strips; heavy coated and very flat; absolutely a gummy; describes itself as 'sugar sanded' with coarse sugar; nice and sour"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry"
        ],
        [
            "03-17-2023",
            "UHA",
            "Nagano Shine Muscat",
            "X",
            "X",
            "4",
            "4",
            "4",
            "X",
            "4.0",
            "4.0",
            "4.0",
            "0.0",
            "import, jp, sour",
            "green",
            "muscat grape",
            "",
            "attractive, good texture, springy"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "03-17-2023",
            "Kanro",
            "Puré Muscat Grape",
            "X",
            "X",
            "4",
            "3",
            "5",
            "X",
            "5.0",
            "4.3",
            "4.5",
            "1.0",
            "import, jp",
            "green",
            "muscat grape",
            "",
            "\"Like a gourmet sour patch kid,\" great flavor (if you like grape)"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "04-02-2023",
            "Betty Crocker",
            "Minions",
            "2",
            "X",
            "3",
            "2",
            "X",
            "3",
            "",
            "2.5",
            "2.5",
            "0.6",
            "fruity",
            "",
            "",
            "",
            "Generic fruit snacks themed for Minions: Rise of Gru; mild chapstick-like taste; shaped like little extruded minions which do not look like food; we wonder  \"is this what minions are made of?\"; contains less fruit than other \"fruit snacks\" and once again reinforces the conspiracy that fruit snacks are just gummies"
        ],
        [
            "04-14-2023",
            "Trolli",
            "Sour Duo Crawlers",
            "2",
            "X",
            "3",
            "3",
            "3",
            "3",
            "2",
            "2.7",
            "3.0",
            "0.5",
            "gimmicky, soft",
            "",
            "",
            "",
            "matthew thinks it's unimaginative and creatively bankrupt, poorly done concepts; matthew is starting a class action lawsuit about it; they have eyes, why; has a chewy marshmallow bottom; if less big and had the standard brite crawlers texture, they would be fine"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "pink/purple",
            "guava/strawberry",
            "",
            "jay doesn't hate it!; interesting"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/yellow",
            "watermelon/citrus",
            "",
            "bubblegum; jolly rancher watermelon; best flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "lime green/orange",
            "mango/pineapple",
            "",
            "is the green pineapple? it's okay"
        ],
        [
            "04-14-2023",
            "Mondelez international",
            "Swedish Fish Tails",
            "1",
            "X",
            "2",
            "2",
            "2",
            "1",
            "2",
            "1.7",
            "2.0",
            "0.5",
            "big",
            "",
            "",
            "",
            "super waxy, subdued; these kinda suck; huge and oversized; like an explosion of disappointment, not strong; wish flavors were at least three times as strong; texture too fresh for kyle, swedish fish need to age; do canadian swedish fish just taste worse than the ones made in canada"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "blue/red",
            "blue raspberry/strawberry",
            "",
            "ew, doesn't taste anything like flavors"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange/red",
            "raspberry/mango",
            "",
            "nope"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green/pink",
            "watermelon/pineapple",
            "",
            "barely tastes like anything other than wax"
        ],
        [
            "04-17-2023",
            "Trader Joe's",
            "Cinnadragons",
            "1",
            "1",
            "2",
            "2",
            "2",
            "1",
            "",
            "1.5",
            "1.5",
            "0.5",
            "",
            "red",
            "cinnamon",
            "",
            "gross; texture is more soft than gummy, really sticks to teeth; hot tamale esque flavor; just plain bad/gross; mold quality is lacking, very lumpy, not like a dragon"
        ],
        [
            "04-18-2023",
            "Mondelez international",
            "Swedish Fish and Friends",
            "2",
            "",
            "3",
            "3",
            "",
            "3",
            "",
            "2.8",
            "3.0",
            "0.5",
            "big",
            "",
            "",
            "",
            "Zach doesn't want any and everyone has overall low expectations after the Swedish Fish Tails; made in Turkey; much better than the other ones; Matthew and Hillary like the turtle and dolphin; are not super chewy"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry",
            "dolphin",
            "Hillary's favorite"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "watermelon",
            "turtle",
            "jolly rancher like watermelon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "lingonberry",
            "fish"
        ],
        [
            "04-24-2023",
            "Mondelez international",
            "Swedish Fish Classic",
            "2",
            "",
            "4",
            "3",
            "3",
            "2",
            "",
            "2.8",
            "3.0",
            "0.8",
            "big",
            "red",
            "lingonberry",
            "fish",
            "so big! were they always this big?; flavor is not lingonberry, but it is totally lingonberry; kyle says delicious; flavor is waxy and not overpowering; too sweet for Matthew; a class, but not a fave; sticks to your teeth; nostalgia probably adds 1/2 a point"
        ],
        [
            "04-25-2023",
            "Sour Patch",
            "Peaches",
            "3",
            "",
            "3",
            "3",
            "2",
            "3",
            "",
            "2.8",
            "3.0",
            "0.4",
            "big, coated",
            "orange",
            "peach",
            "peach",
            "strong flavor; artificial peach flavor; starts with a nice floral, soapy note that turned artificial; hotel hand soap says Matthew; the peaches on the bag are quite suggestive with their cleavage; jay had high expectations since he likes the watermelons so much, but is looking forward to the strawberry"
        ],
        [
            "04-27-2023",
            "Ferrara",
            "Nerds Gummy Clusters",
            "2",
            "",
            "3",
            "2",
            "",
            "2",
            "",
            "2.3",
            "2.0",
            "0.5",
            "coated, big",
            "rainbow",
            "nerds",
            "cluster",
            "nerds on the outside, gummy on the inside; varying sizes; this is definitely for little kids, not an adult gummy; this is just sugar trash candy; sweet, tangy and goofy; we think we'd love these as stupid kids, but less so as adults"
        ],
        [
            "05-08-2023",
            "Werner",
            "Gummy Sharks",
            "2",
            "",
            "3",
            "3",
            "2",
            "3",
            "",
            "2.6",
            "3.0",
            "0.5",
            "big",
            "blue",
            "blue raspberry?",
            "shark",
            "blue raspberry like flavor, weird profile mold that is not typical of the shark gummy; very squishy with a strong smell that is akin to citrus floor cleaner; very soft, much more than usual gummy sharks; more like a raw gummy sashimi shark says kyle; marshmallow part is not distinct; more like a jelly than a gummy; zach hates the smell"
        ],
        [
            "05-23-2023",
            "Promotion in Motion",
            "Sour Jacks",
            "4",
            "",
            "4",
            "4",
            "4",
            "4",
            "",
            "4.0",
            "4.0",
            "0.0",
            "sour, coated",
            "",
            "",
            "wedge",
            "springy and tough in a good way, good flavors, could be more sour, but a solid choice"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemonade"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "lime"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry"
        ],
        [
            "06-09-2023",
            "Lily's",
            "Gummy Bears",
            "2",
            "",
            "",
            "1",
            "2",
            "2",
            "",
            "1.8",
            "2.0",
            "0.5",
            "low sugar, non-gelatin",
            "overall",
            "overall",
            "bear",
            "tag line is \"sweet fruity friends\"; less sugar, contain allulose; very earthy, non-vibrant colors; 1 pouch has 25g of fiber; no gelatin, so not really a gummy; flavor is okay, texture leaves Jay speechless in a bad way; Zach is disappointed; texture is just so so wrong"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "amber",
            "lemon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "dark red",
            "raspberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry"
        ],
        [
            "06-19-2023",
            "AriZona",
            "Fruit Snacks",
            "3",
            "",
            "3",
            "3",
            "3",
            "3",
            "",
            "3.0",
            "3.0",
            "0.0",
            "small, branded",
            "various",
            "drink flavors",
            "can",
            "based on fruity drink flavors; more fruit snack than gummy candy; better than other fruit snakcs we're tried; hard to differentiate the dark colors; ; more than 99 cents a package; very waxy; overall good but unremarkable"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "logo"
        ],
        [
            "07-07-2023",
            "Sweet Tarts Gummies",
            "Fruity Splitz",
            "2",
            "",
            "4",
            "3",
            "3",
            "3",
            "2",
            "2.8",
            "3.0",
            "0.8",
            "branded",
            "various",
            "various",
            "round",
            "dual texture, half translucent and half opaque; looks like bad old ribbon candy; very fragrant and colorful, like playdo; very soft; slightly sour, very much like sweet tarts; highly artificual; no chewy; a soft gummy for Americans; gummies for a post-capitalist world"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "07-11-2023",
            "Yupo",
            "Ayicik",
            "3",
            "",
            "3",
            "4",
            "2",
            "4",
            "",
            "3.2",
            "3.0",
            "0.8",
            "import, tr",
            "",
            "",
            "bear",
            "From Turkey; chewy non-American gummies; excellent chew; weird soap taste to Jay, though Matthew and Hillary liked the overall taste, though it was hard to discern what the flavors actually were; orange was trying to hard; lemon was the most similar to fruit; matthew likes the astrigency; overall a pretty good gummy"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light yellow",
            "pineapple?"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange?"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry??"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "??? cherry???"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "green apple?"
        ],
        [
            "07-18-2023",
            "Annie's Organic",
            "Fruit snack Building Blocks",
            "2",
            "",
            "3",
            "3",
            "3",
            "3",
            "2",
            "2.7",
            "3.0",
            "0.5",
            "gimmicky",
            "",
            "",
            "",
            "fruit snacks, not gummies; you're supposed to build with them, because you're supposed to be a kid if you're eating these, but we're all embarrassing adults; not chewy, instead of gelatin they use pectin; great taste, sad texture; maybe more appealing to kyle since he's a dad"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "07-19-2023",
            "Werner",
            "Apple Rings",
            "2",
            "",
            "2",
            "3",
            "5",
            "2",
            "",
            "2.8",
            "2.0",
            "1.3",
            "large",
            "green",
            "apple",
            "ring",
            "have beef gelatin; very strong aroma and green color; smell more than they taste; basically are just like a peach ring but... apple flavored. what kind of apple though?; one of jay's favorites; better when refrigerated"
        ],
        [
            "07-27-2023",
            "Maynard's",
            "Wine Gums",
            "3",
            "",
            "3",
            "3",
            "4",
            "4",
            "",
            "3.4",
            "3.0",
            "0.5",
            "import, ca",
            "green",
            "lime",
            "",
            "Canadian import from Real Canadian Superstore where you can shop like a mother; very natural colors; best flavor is black currant; overall flavors are quite mild; contain gelatin, mineral oil and wax, and definitely have a waxy bite; great texture, but need more flavor for the size"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "black",
            "black currant"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "??"
        ],
        [
            "07-31-2023",
            "Dare",
            "RealFruit Superfruits",
            "3",
            "",
            "3",
            "3",
            "3",
            "4",
            "",
            "3.2",
            "3.0",
            "0.4",
            "import, ca",
            "overall",
            "",
            "",
            "fruit snack, well-shaped, flavors good but not very distinct, texture would be better if they used gelatin, why isn't there a good vegan substitute for gelatin already"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "cranberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "blackberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "blueberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "blackcurrant"
        ],
        [
            "08-01-2023",
            "Twizzlers Gummies",
            "Happy Hour Peach Spritzer",
            "3",
            "",
            "3",
            "3",
            "3",
            "3",
            "",
            "3.0",
            "3.0",
            "0.0",
            "branded, non-gelatin",
            "orange",
            "peach",
            "",
            "more mushy non-gelatin gummies, okay flavor, not really getting a cocktail element, wish this existed but with gelatin"
        ],
        [
            "08-02-2023",
            "Dare",
            "RealFruit Sours Citrus Burst",
            "4",
            "",
            "4",
            "4",
            "5",
            "5",
            "",
            "4.4",
            "4.0",
            "0.5",
            "import, ca, non-gelatin",
            "overall",
            "",
            "",
            "good, genuinely sour, distinct flavors, grapefruit is a little bitter like real grapefruit, the sour helps the no gelatin thing, could be a little bit smaller"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon-lime"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "pink grapefruit"
        ],
        [
            "08-04-2023",
            "Dare",
            "RealFruit sours summerfruit ",
            "3",
            "",
            "",
            "4",
            "3",
            "4",
            "3",
            "",
            "",
            "",
            "import, ca, non-gelatin",
            "",
            "",
            "",
            "Cherry looks like a radish; not as good as the citrus; "
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Red",
            "Sour cherry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "Peach",
            "Peach"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Pink",
            "Watermelon"
        ],
        [
            "08-07-2023",
            "Sour Patch",
            "Kids Lemonade",
            "4",
            "",
            "4",
            "4",
            "",
            "4",
            "4",
            "",
            "",
            "",
            "import, ca",
            "",
            "",
            "",
            "like sourpatch kids rolled in Countrytime lemonade powder; classic yellow lemonade flavor is top notch; kyle doesn't like pink lemonade; blue raspberry lemonade is not a thing; classic sourpatch kids form and texture; a nice summer treat and a winner"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemonade"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "pink",
            "pink lemonade"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry lemonade"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry lemonade"
        ],
        [],
        [
            "08-16-2023",
            "Maynard's",
            "Fuzzy Peach",
            "3",
            "",
            "",
            "3",
            "",
            "4",
            "",
            "",
            "",
            "",
            "import, ca",
            "peach",
            "peach",
            "",
            "non-gelatin, don't have the right gummy texture; good flavor; fruit gel; nice sour note to them; nothing to write home about; one of the better peach gummies we have tried"
        ],
        [
            "08-17-2023",
            "Maynard's",
            "Swedish Berries",
            "2",
            "",
            "",
            "3",
            "",
            "2",
            "",
            "",
            "",
            "",
            "import, ca",
            "red",
            "lingonberry",
            "berry",
            "A canadian import that taste just like Swedish Fish but shaped like a berry; exactly what it says on the tin; Matthew doesn't mind them (damning with faint praise); these gummies are a more manageable size than Swedish Fish; stick to our teeth; are we all going to get diabetes from doing this for over a year now?; Jay is officially out of the gummy game at this point"
        ],
        [
            "08-18-2023",
            "Dare",
            "RealFruit Medley",
            "3",
            "",
            "",
            "4",
            "",
            "3",
            "",
            "",
            "",
            "",
            "import, ca, non-gelatin",
            "",
            "",
            "",
            "another non-gelatin offering from Dare; more of a fruit snack; nice fruity shapes and flaors, but the lack of gelatin bounciness is disappointing; better than the Swedish Berries; we've all got tummy aches from too many gummies"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry",
            "strawberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "raspberry",
            "berry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "dark red",
            "cherry",
            "cherry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "blueberry?",
            "blueberries"
        ],
        [
            "08-22-2023",
            "Red Band",
            "Wine Gums",
            "3",
            "",
            "",
            "4",
            "2",
            "4",
            "3",
            "",
            "",
            "",
            "import, nl",
            "",
            "",
            "",
            "dutch; from 'a touch of dutch' on Whidbey Island; gelatin makes these very springy; flavors are super mild; good texture, not as tough and waxy as other wine gums; zach prefers a waxier wine gum but hillary thinks the wax level is appropriate; overall good texture and mouthfeel, but lacking in flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Alan)",
            "",
            "",
            "",
            "",
            "purple",
            "black currant",
            "",
            "very mild"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "apple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "citrus",
            "",
            "very generic \"citrus\""
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "strawberry?",
            "",
            "tastes like red jello"
        ],
        [
            "08-29-2023",
            "Jolly Rancher",
            "Misfit Gummies",
            "3",
            "",
            "4",
            "3",
            "3",
            "3",
            "2",
            "",
            "",
            "",
            "gimmicky, import, ca",
            "",
            "",
            "",
            "not sure these are actually a canadian import or just where zach and hillary picked them up; are these just swedish fish but with deformities?; supposed to be a bear on top half and a fish on bottom; no aroma; actually much better than expected; hillary and kyle like them despite the weird presentation"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "",
            "orange/pineapple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow/purple",
            "lemon/black raspberry",
            "",
            "tastes like handsoap according to jay?"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red/blue",
            "strawberry/blue punch",
            "",
            "worst flavor"
        ],
        [
            "08-29-2023",
            "Carnaby",
            "Sour Gummy Rings",
            "2",
            "",
            "2",
            "3",
            "4",
            "2",
            "",
            "",
            "",
            "",
            "import, china",
            "",
            "",
            "",
            "made in China; look like soft froot loops; have a soft trolli like texture; no one really wants to try them, but upon doing so, they are not the worst; Jay really likes these and we don't know what to think. about that; generic, boring, not horrible but not good"
        ],
        [
            "08-29-2023",
            "Twizzler",
            "Press'd",
            "2",
            "",
            "1",
            "2",
            "1",
            "1",
            "1",
            "",
            "",
            "",
            "low-sugar, non-gelatin",
            "",
            "",
            "",
            "low sugar; taste terrible; clay like texture like eating wallace and gromit"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "",
            "watermelon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "strawberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "apple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange"
        ],
        [
            "09-07-2023",
            "Black Forest",
            "Gummy Bears",
            "3",
            "",
            "3",
            "4",
            "3",
            "3",
            "3",
            "",
            "",
            "",
            " ",
            "",
            "",
            "",
            "natural colors; good mold quality, we can see their little legs; equal distribution of colors in the bag, which is rarely the case; very chewy; don't tate like much; tried and true gummy with mild flavors; flat/matte colors"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "light yellow",
            "pineapple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink/red",
            "strawberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "apple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry"
        ],
        [
            "09-12-2023",
            "Dare",
            "Real Fruit Tropical",
            "3",
            "",
            "3",
            "3",
            "3",
            "4",
            "",
            "",
            "",
            "",
            "ca, import, non-gelatin",
            "",
            "",
            "",
            "non-gelatin canadian delight; shaped like their respective fruits; if kyle liked mango, he thinks this would be a good flavor (???); matthew is fond of the strawberry kiwi; what does a passion fruit even look like?; these are fruit snacks more than gummies but we like the flavors; natural colors and flavors; matte colors; a good enough snack, but not amazing"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry kiwi"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "dark red",
            "passion fruit"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "apricot"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light orange",
            "mango"
        ],
        [
            "09-13-2023",
            "Airheads",
            "Xtremes Sourfuls",
            "2",
            "",
            "4",
            "3",
            "",
            "2",
            "",
            "",
            "",
            "",
            "tough",
            "",
            "",
            "",
            "didn't we already do these?; white filling makes them different and gross from previous Airheads gummy; tough; \"rainbow\" flavor; taste old but were purchased recently; tastes like the white mystery airhead that no one likes; nice colors; seriously didn't we already do these"
        ],
        [
            "09-14-2023",
            "Sour Punch",
            "Rainbow Straws",
            "2",
            "",
            "2",
            "2",
            "3",
            "3",
            "2",
            "",
            "",
            "",
            "gimmicky, coated",
            "",
            "",
            "",
            "have a licorice texture; have a stale taste, waxy and sour; absolutely coated with critic acid; only the lemon has a strong flavor; it is possible to use this as a straw, but it's not great; can also blow bubbles through the straw"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "red",
            "strawberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "apple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon"
        ],
        [
            "09-25-2023",
            "Trader Joe's",
            "Cats and Bats",
            "3",
            "",
            "4",
            "4",
            "4",
            "4",
            "",
            "",
            "",
            "",
            "gimmicky, coated",
            "",
            "",
            "",
            "a variation on the Scandinavian Swimmers for Halloween featuring gummies shaped like bats and cats; nice and sour; Zach says they are jelly-ish; Kyle likes the packaging; green apple flavor is a fan favorite for its tartness"
        ],
        [
            "09-26-2023",
            "Kasugai",
            "Lipton Peach Tea Gummy",
            "4",
            "",
            "4",
            "4",
            "4",
            "4",
            "",
            "",
            "",
            "",
            "gimmicky, import, jp, branded",
            "peach tea",
            "",
            "",
            "Classic Kasugai texture; Kasugai x Lipton, has cross branding gone too far?; very aromatic, weird but good and novel with a nice tea taste; even Keith ate one"
        ],
        [
            "09-27-23",
            "Cocoa Land Industries",
            "LOT 100",
            "3",
            "",
            "3",
            "3",
            "3",
            "2",
            "",
            "",
            "",
            "",
            "imported, malaysia",
            "",
            "",
            "",
            "Malaysian origin; individually packaged; flavors include green apple, strawberry, grape, mango, orange, black currant and strawberry; contain actual gelatin; halal?; what if kasugai made bad gummies?; tough and seem to have a \"rind\" on them; why is there a prop65 warning on these? Should we stop eating these?; Matthew likes the texture"
        ],
        [
            "10-25-2023",
            "Haribo",
            "Fruit Salad",
            "",
            "",
            "2",
            "2",
            "",
            "2",
            "",
            "",
            "",
            "",
            "big, coated",
            "",
            "",
            "",
            "shaped like their respective fruits; big and coated; more of a jelly than a gummy, though it contains gelatin; too soft, not chewy; cloyingly sweet; Matthew finds them tolerable, hillary does not"
        ],
        [
            "10-30-2023",
            "Safeway Signature Select",
            "Gummy Sharks",
            "",
            "",
            "",
            "3",
            "",
            "4",
            "",
            "",
            "",
            "",
            "generic, marshmallow",
            "",
            "",
            "",
            "sweet smell with great chew and texture; smaller than regular gummy sharks; product of Turkey; what is this flavor anyway? blue raspberry?; wonder if all grovery store gummy sharks are from the same place"
        ],
        [
            "11-10-2023",
            "Meiji",
            "Cola Up",
            "4",
            "",
            "4",
            "4",
            "3",
            "4",
            "4",
            "",
            "",
            "",
            "imported, jp",
            "brown",
            "cola",
            "cola bottle",
            "well-formed casting mold for this gummy; shares the same shape and texture as the ramune up!; strong and great aroma, smells delicious, but has a pretty mild flavor in comparison to this fragrant smell; very tough and \"bouncy\" chew"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "11-10-2023",
            "Meiji",
            "Ramune Up",
            "3",
            "",
            "3",
            "4",
            "3",
            "4",
            "4",
            "",
            "",
            "",
            "imported, jp",
            "blue",
            "ramune",
            "bottle",
            "same mold as Cola Up!; very tough gummy that rates a 5 on some toughness scale; \"bouncy\" texture; people are less of a fan of ramune than cola"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "11-14-2023",
            "Sour Patch",
            "Kids Tropical",
            "4",
            "",
            "3",
            "4",
            "4",
            "4",
            "",
            "",
            "",
            "",
            "sour, coated",
            "",
            "",
            "",
            "a fun twist on the classic sour patch kids; zach loves the juicy flavor explosion; perfect chew and freshness; would this one belong in gummy valhalla? what should we even call the top tier club for good gummies that score greater than a 4 from everyone?"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "whitish",
            "pineapple",
            "",
            "too strong fake pineapple flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "passion fruit",
            "",
            "starts with an astringent flavor before getting fruity"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "tropical twist",
            "",
            "nice, flowery"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "paradise punch"
        ],
        [],
        [
            "11-20-2023",
            "Amaguri",
            "Sweet Chestnut",
            "2",
            "",
            "2",
            "3",
            "",
            "3",
            "3",
            "",
            "",
            "",
            "imported, jp, tough",
            "tan",
            "chestnut",
            "chestnut",
            "Zach smelled these and immediately let out a \"AAaaeugh!\"; opaque and very much look like garbanzo beans; peuce; others said they looked like little butts; not so bad, very interesting and unlike any other gummy flavor we have had so far; remind Hillary of hazelnut coffee flavor; deceptively tough; not disguting but not anyone's jam"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "11-20-2023",
            "Kabaya",
            "Tough Gummy Orange Vibes",
            "5",
            "",
            "4",
            "4",
            "4",
            "",
            "4",
            "",
            "",
            "",
            "imported, jp, tough, coated",
            "orange",
            "orange soda",
            "cube",
            "orangevibes forever; So tough!! We love these tough little gummy cubes and these did not disappoint; tangy like Tang, these capture the fizz of orange soda in gummy form; this is a gummy for action stars; Keith continues to abstain from gummies but has picked up a wasabi pea habit while we do these taste tests"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "11-21-2023",
            "IKEA",
            "LÖRDAGSGODIS sweet and sour mix",
            "4",
            "",
            "4",
            "4",
            "",
            "4",
            "",
            "",
            "",
            "",
            "imported, finland, coated, sour, sweet",
            "",
            "",
            "",
            "LÖRDAGSGODIS refers to the Swedish tradition of eating candy on Saturdays; pale colors with strange assortment of hapes; no smell; lots of citric acid coating these; strange flavor with rhubarb, but this was intriguing for Keith who apparently loves rhubarb (not as much as wasabi peas though); Kyle hates the rhubarb but loves everything else; the colas are a definite winner; the bag is huge; definitely worth picking up at IKEA"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "brown/white",
            "cola",
            "moose"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "brown/white",
            "sour cola",
            "boat"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "white",
            "lime",
            "round"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "round"
        ],
        [
            "11-28-2023",
            "Lifesavers",
            "Gummies Wild Berries",
            "3",
            "",
            "3",
            "3",
            "3",
            "",
            "",
            "",
            "",
            "",
            "sweet, fruity",
            "",
            "",
            "",
            "package claims 6 flavors but only found 5, shapes are more malformed than not, flavor is mild and not pronounced; nothing to recommend them and they are \"disappointing\" but they are also easy to keep eating so they get a 3"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry",
            "torus"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "light red",
            "red raspberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "black raspberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "blackberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry berry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "white",
            "white grape"
        ],
        [
            "11-27-2023",
            "Shodoshima",
            "Olive gummy",
            "3",
            "",
            "3",
            "4",
            "3",
            "3",
            "3",
            "",
            "",
            "",
            "import, jp",
            "green",
            "olive",
            "round",
            "barely has any scent and is very much not olive flavored; more of a fruit jelly than a gummy; tastes like a kiwi and has grape juice; maybe it's like olive leaf beer where it is not really used for flavoring; packaging is very classy and elevated for candy"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "11-27-2023",
            "Amos",
            "4D Juicy Rush",
            "3",
            "",
            "4",
            "5",
            "5",
            "5",
            "5",
            "",
            "",
            "",
            "import, jp, pretty",
            "",
            "",
            "grape",
            "highly detailed, meticulously beautiful gummy treat; they even have tiny green leaves; look like glass or games with clear, high optical grade; sparkle like faceted gems on a ring; fantastic gummy texture; Zach hates grape; probably the most beautiful gummy we've reviewed; has a nucleus, juicy center"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "red",
            "red grape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "white",
            "muscat"
        ],
        [
            "11-27-2023",
            "Nobel",
            "Kame Kame Sours",
            "2",
            "",
            "3",
            "4",
            "3",
            "2",
            "4",
            "",
            "",
            "",
            "import, jp, sour",
            "",
            "energy drink?",
            "turtle",
            "Lipovitan flavor? kind of like red bull; loose gummy/jelly in the turtle shell; Zach likes the \"energy drink\" / medicina flavor; barely sour"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "12-4-2023",
            "Nobel",
            "Chibi Sours",
            "2",
            "",
            "3",
            "4",
            "3",
            "2",
            "3",
            "",
            "",
            "",
            "import, jp, sour",
            "",
            "",
            "cube, star, pyramid",
            "overall okay flavors that taste like they were dehydrated; tough little gummy with a lot of chew; has a bit of a rind to it; not very sour despite its name; tastes like a failed TOUGH gummy or maybe just really old; Matthew found these to be quite good"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon squash",
            "",
            "never seen this flavor before"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "brown",
            "cola",
            "",
            "like bad soda; flat Tab"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "melon soda"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "ramune"
        ],
        [
            "12-4-2023",
            "Kabaya",
            "Peanuts Gummy",
            "1",
            "",
            "3",
            "3",
            "2",
            "2",
            "3",
            "",
            "",
            "",
            "import, jp, marshmallow",
            "green",
            "apple",
            "",
            "Has Snoopy on the packaging, Peanuts themed; apple flavored, weirdly spongey gummy; strong artificial apple smell and taste; very sweet; marshmallowy; overripe fruit"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "12-5-2023",
            "Haribo",
            "Orangina Pik",
            "4",
            "",
            "3",
            "4",
            "",
            "4",
            "",
            "",
            "",
            "",
            "import, fr, sour, branded",
            "",
            "",
            "",
            "shaped like little bottles with clear/white gummy and a red/orange interior; made in France; delicious; looked like they were going to be soft/jelly like, but surprisingly tough; nice strong, fruity flavor with a floral hint; not an orange sour, but an orange juice sour; we all like the cobranding"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orangina",
            "orange"
        ],
        [
            "12-7-2023",
            "Haribo",
            "Kirsch-cola",
            "2",
            "",
            "3",
            "3",
            "2",
            "1",
            "2",
            "",
            "",
            "",
            "import, de, sweet, cola",
            "red-brown",
            "cherry cola",
            "bottle",
            "big, weird shape, no gelatin makes it waxy in the mouth, texture is \"pathetic,\" flavor isn't bad but everything else makes this a miss; hillary hates the medicinal cherry taste and waxy texture, what a disappointment"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "12-8-2023",
            "Meiji",
            "Boost Bites",
            "4",
            "",
            "4",
            "4",
            "3",
            "3",
            "4",
            "",
            "",
            "",
            "import, jp",
            "green",
            "energy drink",
            "battery",
            "rated a 5 on Meiji's proprietary toughness scale for 'super concentration'; we like how they are shaped like little batteries; acid green evokes mountain dew; contain caffiene, royal jelly and some b vitamins; 60 mg of caffiene in the whole bag; springy like it's a vulcanized gummy; similar to other energy drink gummies like the Kame Kame sours"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "12-8-2023",
            "Nobel",
            "Otoko Ume",
            "1",
            "",
            "1",
            "3",
            "2",
            "1",
            "3",
            "",
            "",
            "",
            "import, jp, salty, sour",
            "purple",
            "pickled plum",
            "rectangular",
            "unique flavor we have not tried before, but is divisive with its salty and sour pickled taste; has a geodude like mascot we can all appreciated; very salty; the more you chew, the more it tastes like umeboshi; flavor is very popular in japan, not so much for some of us; hillary couldn't handle it"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "12-11-2023",
            "Kabaya",
            "Tough Gummy Dual Grape Punch",
            "4",
            "",
            "",
            "5",
            "",
            "4",
            "5",
            "",
            "",
            "",
            "import, jp, sour, tough",
            "",
            "",
            "cube",
            "Alpha and beta, alpha with moderate sweetness and beta with a refreshing acidity; hard to tell difference between grape flavors if you have an uneducated palette; tough, just as we have come to love and expect from this crowd favorite gummy family; adore the cube shape as well"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "purple",
            "grape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "milder grape"
        ],
        [
            "12-15-2023",
            "UHA",
            "Tsugummi Blocks",
            "4",
            "",
            "3",
            "4",
            "",
            "2",
            "2",
            "",
            "",
            "",
            "import, jp, gimmicky",
            "various",
            "various",
            "lego-like",
            "like wet gummy legos; we had to \"palpate the bag\" to get the gummies separated; weird earthy colors andd poorly formed lego-like mold shapes; Zach insisted we try to build with them, but had little success; not like your daddy's gummies?? - probably Kyle; Zach and Matthew like them for how shiny they are; we wonder if they are compatible with legos"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "12-21-2023",
            "Haribo",
            "Almedudler",
            "3",
            "",
            "3",
            "3",
            "4",
            "4",
            "3",
            "",
            "",
            "",
            "import, de",
            "red and green",
            "alderflower, raspberry",
            "twins, bottle",
            "herbal; based on an Austrian soft drink; two shapes and flavors, a bottle with 'herbal extracts' and the 'drinking buddies' twins tasting like elderflower and raspberry; tough like we have come to expect from haribo; not for vegans; Kyle doesn't like the taste of the twins, while Jay wishes the flavor was stronger"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jason)"
        ],
        [
            "12-20-2023",
            "Sour Patch",
            "Kids Berries",
            "2",
            "",
            "3",
            "3",
            "",
            "3",
            "",
            "",
            "",
            "",
            "sour, coated, import, ca",
            "",
            "",
            "kid",
            "made for the canadian market; blue raspberry tastes strikingly artificial; cherry and grape are good, strawberry is mid; standard Sour Patch kids are preferable; strawberry is gross; we wonder if these are stale"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "cherry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "grape"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "blue",
            "blue raspberry"
        ],
        [
            "1-2-2024",
            "Meiji",
            "Fruit Juice Gummy",
            "2",
            "",
            "3",
            "3",
            "2",
            "3",
            "4",
            "",
            "",
            "",
            "import, jp, fruity",
            "peuce?",
            "japanese pear",
            "pear",
            "nice mold quality; rated a 2 on the Meiji gummy toughness scale, so a nice relaxing gummy; a limited seasonal flavor; kyle says it tastes like his grandma's house; perfumy and floral and off-putting at first; a 4, despite the smell, says our guest; delicate flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "1-2-2024",
            "Kanro",
            "Pure Sparkling Muscat Soda",
            "-",
            "",
            "3",
            "4",
            "3",
            "2",
            "4",
            "",
            "",
            "",
            "import, jp, coated",
            "green",
            "muscat grape soda",
            "",
            "have a gooey center, which is a no-go for Zach; coated like a sour gummy, but is extremely sweet; tastes like overripe grapes; unsettling texture with the goo"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "1-2-2024",
            "Bourbon",
            "Fettucine Gummi",
            "3",
            "",
            "2",
            "3",
            "3",
            "3",
            "3",
            "",
            "",
            "",
            "import, jp, sour, coated",
            "purple",
            "grape",
            "noodle",
            "more grape, ugh, says Zach; tastes like purple nerds or bubblicious bubble gum; fine, but not really notable; wondering if we are reaching our wit's ends with these gummy tastings; fettucine form factor is not a fave"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "01-08-2024",
            "Vidal",
            "Sour Kiwi Slices",
            "3",
            "",
            "2",
            "4",
            "5",
            "3",
            "3",
            "",
            "",
            "",
            "import, sour, coated, es",
            "green",
            "kiwi",
            "round",
            "tough and abrasive on your tongue; don't have much of a smell; sourness makes it similar to eating a kiwi; colored with turmeric and blue 1"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "01-08-2024",
            "Gurley's",
            "Gummy Blue Sharks",
            "2",
            "",
            "3",
            "2",
            "3",
            "3",
            "2",
            "",
            "",
            "",
            "marshmallow",
            "blue",
            "",
            "shark",
            "mold on top is really well defined, we can see the gills; bad marshmallow foam, looks like polyeurythane; purchased at a craft store but look like you'd buy them at a weird gas station; starts off promising, but gets less good; very average gummy shark according to Kyle"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)"
        ],
        [
            "01-09-2024",
            "Haribo",
            "Super Mario",
            "2",
            "",
            "4",
            "4",
            "2",
            "4",
            "",
            "",
            "",
            "",
            "import, de",
            "",
            "",
            "",
            "sour kicks non-crystalline sour taste; classic haribo tangy gummies and toughness; size and thickness of pieces mean you have to work on it; while they are large, they are the perfect amount of tough; officially licensed by Nintendo; good, solid moldwork with multiple shapes"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "yellow",
            "lemon",
            "coin, star"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "apple",
            "mushroom, shell",
            "fake sour apple taste, but hillary likes it; jolly rancher"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "",
            "mushroom, fireflower"
        ],
        [
            "01-10-2024",
            "Haribo",
            "Fruitmania Berry",
            "3",
            "",
            "3",
            "3",
            "",
            "3",
            "",
            "",
            "",
            "",
            "import, eu",
            "reds, purples",
            "strawberry, currant, raspberry, black raspberry, blueberry",
            "berries",
            "many shades of similar colors; detailed moldwork, e.g. you can see the seeds on the strawberry; vegetarian (non-gelatin); more like a fruit snack in texture and chew; 20% fruit juice; no real smell; flavors are consistent, taste only a hint of the distinct berries"
        ],
        [
            "01-10-2024",
            "Ferrera",
            "Funables Fruit Snacks",
            "3",
            "",
            "2",
            "2",
            "",
            "3",
            "",
            "",
            "",
            "",
            "fruit snack",
            "red, purple",
            "strawberry, raspberry",
            "berries",
            "bright colors; can't distinguish between strawberry and raspberry flavors; not super fruity, more like a candy than a fruit snack; have gelatin in them, unlike usual fruit snacks; falvor meh, texture is okay"
        ],
        [
            "01-12-2024",
            "Huer",
            "Sour Brats",
            "2",
            "",
            "2",
            "3",
            "",
            "3",
            "2",
            "",
            "",
            "",
            "import, ca",
            "",
            "",
            "kid",
            "soft, not that sour, like shape better than sour patch kid, not chewey, orange is the best flavor"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Jonathan)",
            "",
            "",
            "",
            "",
            "orange",
            "orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "green apple"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pink",
            "strawberry (?)"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "purple",
            "grape"
        ],
        [
            "01-16-2024",
            "Haribo",
            "Wine Gums",
            "3",
            "",
            "2",
            "4",
            "",
            "3",
            "",
            "",
            "",
            "",
            "import, eu",
            "",
            "",
            "",
            "the packaging features Big Ben, a red double decker bus, and a British Beefeater guard; very natural colors; have the expected sour, waxy wine gum smell; standard flavors, shapes and colors; flavors are poorly distributed though; only 1 red (raspberry) gummy in the whole package; spongey bite with very little waxy flavor; not enough wax for Hillary to consider it a wine gum; lemon has a zesty edge to it"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "pineapple",
            "white"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "lemon",
            "yellow"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "orange",
            "orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green",
            "lime"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "black",
            "black currant"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "red",
            "raspberry"
        ],
        [
            "01-19-2024",
            "Haribo",
            "Gruene Krokodile ",
            "3",
            "",
            "2",
            "3",
            "",
            "3",
            "4",
            "",
            "",
            "",
            "import, de, large, coated",
            "",
            "",
            "",
            "two-tone snacks where the heads are all green/apple-flavored; even distribution of the flavors/colors in the package; vegan (no gelatin); more like a fruit jelly than a true gummy; can't really taste the apple; not related to the street drug!!!; wish it was more sour, not chewy enough, but the overall flavor is pretty good"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "(Chad)",
            "",
            "",
            "",
            "",
            "green/red",
            "apple/cherry"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green/orange",
            "apple/orange"
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "green/yellow",
            "apple/passionfruit",
            "",
            "best flavor"
        ]
    ]
}