.GummyCards {
  margin-top: calc(var(--headerHeight) * 2);
  display: flex;
  flex-wrap: wrap;
}

.GummyCards h2 {
  width: 100%;
}
.TagFilters {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.TagFilter {
  border: 1px solid var(--blue);
  margin: 0.5rem;
  padding: 0.25rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  line-height: 1;
}

.TagFilter:hover {
  background-color: var(--blue);
  color: var(--white);
}

.TagValue {
  font-size: 0.8rem;
}

.TagCount {
  font-size: 0.6rem;
}

.Active {
  background-color: var(--blue);
  color: var(--white);
}
