export function randomColor(): string {
  const randomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  var h = randomInt(0, 360);
  var s = randomInt(42, 98);
  var l = randomInt(40, 90);
  return `hsla(${h},${s}%,${l}%, 0.5)`;
}

export const COLORS = [
  "hsla(325,87%,46%, 0.5)",
  "hsla(80,50%,58%, 0.5)",
  "hsla(293,79%,59%, 0.5)",
  "hsla(81,92%,60%, 0.5)",
  "hsla(156,90%,59%, 0.5)",
  "hsla(113,56%,80%, 0.5)",
  "hsla(107,51%,41%, 0.5)",
  "hsla(121,94%,79%, 0.5)",
  "hsla(171,54%,85%, 0.5)",
  "hsla(126,84%,71%, 0.5)",
  "hsla(15,85%,48%, 0.5)",
  "hsla(185,91%,87%, 0.5)",
  "hsla(208,70%,61%, 0.5)",
  "hsla(324,81%,63%, 0.5)",
  "hsla(48,46%,53%, 0.5)",
  "hsla(134,74%,46%, 0.5)",
  "hsla(94,85%,45%, 0.5)",
  "hsla(344,78%,56%, 0.5)",
  "hsla(106,55%,61%, 0.5)",
  "hsla(184,61%,42%, 0.5)",
  "hsla(204,51%,56%, 0.5)",
  "hsla(197,97%,86%, 0.5)",
  "hsla(288,90%,42%, 0.5)",
  "hsla(0,73%,87%, 0.5)",
  "hsla(359,50%,45%, 0.5)",
  "hsla(80,92%,53%, 0.5)",
  "hsla(351,87%,50%, 0.5)",
  "hsla(90,93%,60%, 0.5)",
  "hsla(332,44%,56%, 0.5)",
  "hsla(1,46%,55%, 0.5)",
  "hsla(324,56%,74%, 0.5)",
  "hsla(249,53%,80%, 0.5)",
  "hsla(185,78%,89%, 0.5)",
  "hsla(33,67%,56%, 0.5)",
  "hsla(244,63%,71%, 0.5)",
  "hsla(277,70%,73%, 0.5)",
  "hsla(240,50%,61%, 0.5)",
  "hsla(340,72%,52%, 0.5)",
  "hsla(273,49%,42%, 0.5)",
  "hsla(202,97%,88%, 0.5)",
  "hsla(266,59%,67%, 0.5)",
  "hsla(226,80%,76%, 0.5)",
  "hsla(61,78%,74%, 0.5)",
  "hsla(143,84%,67%, 0.5)",
  "hsla(37,82%,84%, 0.5)",
  "hsla(212,89%,48%, 0.5)",
  "hsla(275,87%,50%, 0.5)",
  "hsla(222,85%,44%, 0.5)",
  "hsla(16,53%,41%, 0.5)",
  "hsla(335,71%,62%, 0.5)",
  "hsla(338,46%,73%, 0.5)",
  "hsla(94,79%,83%, 0.5)",
  "hsla(342,58%,47%, 0.5)",
  "hsla(246,88%,80%, 0.5)",
  "hsla(9,73%,80%, 0.5)",
  "hsla(281,69%,58%, 0.5)",
  "hsla(306,84%,89%, 0.5)",
  "hsla(206,69%,65%, 0.5)",
  "hsla(353,42%,48%, 0.5)",
  "hsla(154,55%,79%, 0.5)",
  "hsla(97,91%,75%, 0.5)",
  "hsla(201,77%,76%, 0.5)",
  "hsla(202,65%,82%, 0.5)",
  "hsla(126,84%,49%, 0.5)",
  "hsla(7,71%,64%, 0.5)",
  "hsla(349,80%,65%, 0.5)",
  "hsla(206,42%,60%, 0.5)",
  "hsla(63,96%,60%, 0.5)",
  "hsla(337,75%,83%, 0.5)",
  "hsla(64,76%,48%, 0.5)",
  "hsla(2,87%,70%, 0.5)",
  "hsla(111,61%,87%, 0.5)",
  "hsla(174,58%,49%, 0.5)",
  "hsla(307,85%,89%, 0.5)",
  "hsla(110,87%,74%, 0.5)",
  "hsla(272,65%,74%, 0.5)",
  "hsla(200,97%,56%, 0.5)",
  "hsla(110,62%,68%, 0.5)",
  "hsla(230,57%,79%, 0.5)",
  "hsla(321,88%,60%, 0.5)",
  "hsla(307,72%,83%, 0.5)",
  "hsla(332,49%,72%, 0.5)",
  "hsla(281,85%,42%, 0.5)",
  "hsla(120,55%,88%, 0.5)",
  "hsla(60,87%,56%, 0.5)",
  "hsla(319,71%,74%, 0.5)",
  "hsla(241,53%,81%, 0.5)",
  "hsla(160,51%,83%, 0.5)",
  "hsla(43,61%,55%, 0.5)",
  "hsla(60,97%,45%, 0.5)",
  "hsla(94,62%,58%, 0.5)",
  "hsla(247,92%,87%, 0.5)",
  "hsla(96,54%,40%, 0.5)",
  "hsla(185,59%,46%, 0.5)",
  "hsla(283,71%,58%, 0.5)",
  "hsla(35,87%,48%, 0.5)",
  "hsla(303,50%,44%, 0.5)",
  "hsla(21,45%,51%, 0.5)",
  "hsla(274,91%,72%, 0.5)",
  "hsla(120,46%,79%, 0.5)",
];
