.GummyCard {
  text-align: center;
  margin: 0.5rem;
  padding: 0.25rem;
  border-radius: 0.05rem;
  border: 1px solid var(--gray-lightest);
  background-color: var(--white);
  transition: all 250ms;
  width: 375px;
  max-width: 375px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  font-size: 0.8rem;
}
.Title {
  margin: 0.5rem;
}
.Content {
  display: flex;
  flex-direction: row;
  align-items: middle;
  justify-content: center;
}

.GummyCard:hover {
  border-color: var(--gray-lighter);
}

.GummyCard table {
  font-size: 0.7rem;
  text-align: center;
  border: 1px var(--gray-lighter) solid;
  margin: 0.5rem auto 0;
}

.GummyCard th,
.GummyCard td {
  padding: 0.2rem 0.4rem;
  /* border-top: 1px solid var(--gray-lightest); */
  border-bottom: 1px solid var(--gray-lighter);
}

.Image {
  position: relative;
  padding: 0.25rem;
}

.Placeholder {
  height: 150px;
  background-color: var(--gray-lightest);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-lighter);
  font-weight: bold;
}

.NonGummy {
  filter: grayscale();
}

.NonGummyIcon {
  display: block;
  position: absolute;
  width: 150px;
  padding: 0.5rem 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ(-25deg);
}

.NonGummyIcon p {
  margin-bottom: 0;
  color: #e0e0e0;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  background: rgba(200, 0, 0, 0.75);
  user-select: none;
}

.Review {
  padding: 1rem 0.5rem 0;
  background-color: var(--white);
}

.Review h6 {
  margin-bottom: 0.5rem;
}

.Review p {
  text-align: left;
  transition: height 250ms;
}
.Review p:last-of-type {
  margin-bottom: 1rem;
}

.Tags {
  padding-bottom: 0.5rem;
}

.Tag {
  border-radius: 0.25rem;
  background-color: var(--gray-lightest);
  padding: 0.25rem 0.5rem;
  margin: 0 0.25rem;
}
