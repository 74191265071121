.Table tr:first-child {
  position: sticky;
  /* top: var(--headerHeight); */
  top: 0;
}

.Table tr:hover:first-child {
  background-color: transparent;
}

.Table {
  /* top: 4rem; */
  position: relative;
}

.Table table {
  border: 1px solid var(--gray-light);
  font-size: 0.8rem;
}

.Table th,
.Table td {
  text-align: center;
}

.Table tr:hover {
  background-color: moccasin;
  transition: all 250ms;
}
.Table tr:first-child {
  outline: red;
}
.Table tr td:first-child {
  min-width: 100px;
}

.Table span {
  white-space: nowrap;
}

.SortOrder {
  font-size: 12px;
  color: #ccc;
  vertical-align: middle;
  padding: 0.2rem;
  cursor: pointer;
}
