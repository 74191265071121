import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";

import { Gummy, Tag } from "../sheet";
import GummyCard from "./GummyCard";

import * as classes from "./GummyCards.module.css";

function TagFilter({
  tag,
  count,
  onClick,
  isActive,
}: {
  tag: string;
  count: number;
  onClick: (e: React.SyntheticEvent) => void;
  isActive: boolean;
}) {
  const classList = [classes.TagFilter, isActive ? classes.Active : ""];
  return (
    <div className={classList.join(" ")} onPointerDown={onClick}>
      <span className={classes.TagValue}>{tag}</span>{" "}
      <span className={classes.TagCount}>{count}</span>
    </div>
  );
}

export const GummyCards = ({
  gummies,
  tags,
}: {
  gummies: Gummy[];
  tags: Tag[];
}) => {
  const [filters, setFilters] = useState<string[]>([]);
  const [filteredGummies, setFilteredGummies] = useState<Gummy[]>(gummies);

  const onFilterClick = (e: SyntheticEvent, tag: Tag) => {
    e.preventDefault();

    if (filters.includes(tag.value)) {
      setFilters(filters.filter((t) => t !== tag.value));
    } else {
      setFilters([...filters, tag.value]);
    }
  };

  useEffect(() => {
    const filtered = gummies.filter((g) => {
      for (let i = 0; i < filters.length; i++) {
        const tag = filters[i];
        if (g.keywords?.includes(tag)) {
          return true;
        }
      }
      return false;
    });
    setFilteredGummies(filtered.length === 0 ? gummies : filtered);
  }, [filters]);

  const tagsList = tags.map((tag, i) => {
    return (
      <TagFilter
        key={i}
        tag={tag.value}
        count={tag.count}
        onClick={(e) => onFilterClick(e, tag)}
        isActive={filters.includes(tag.value)}
      />
    );
  });

  const gummyCards: any = filteredGummies.map((gummy, i) => {
    return <GummyCard i={i} key={i} gummy={gummy} />;
  });

  return (
    <section className={classes.GummyCards}>
      <h3>Tasting Notes and Stats</h3>
      <div className="u-full-width">
        <h5>
          Sassy reviews generated using our tasting notes and{" "}
          <a href="https://openai.com/product/gpt-4" target={"_blank"}>
            GPT4
          </a>
        </h5>
      </div>
      <div className={classes.TagFilters}>{tagsList}</div>
      {gummyCards}
    </section>
  );
};
