import React from "react";
import { DataTable } from "./components/DataTable";
import { getData } from "./sheet";

import * as classes from "./App.module.css";

import { GummyCards } from "./components/GummyCards";
import { Overview } from "./components/Overview";
export function App() {
  const { headers, gummies, tags } = getData();
  return (
    <main className="container">
      <h1 className={classes.Header}>Coincidence Gummy Evaluation</h1>
      <Overview />
      <DataTable headers={headers} gummies={gummies} />
      <GummyCards gummies={gummies} tags={tags} />
    </main>
  );
}
