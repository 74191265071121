import React from "react";
import { COLORS } from "../utils";

import { Gummy } from "../sheet";

import * as classes from "./GummyCard.module.css";

import { IMAGES } from "../images";
import { RadarChart } from "./Charts";

const NAMES = ["zach", "keith", "kyle", "matthew", "jay", "hillary", "guest"];

export default function GummyCard({ i, gummy }: { i: number; gummy: Gummy }) {
  const { brand, type, variants, average, median, deviation } = gummy;
  const participants: string[] = [];
  const color = i > COLORS.length - 1 ? COLORS[i - COLORS.length] : COLORS[i];

  // Extract data and labels for scoring
  const data = Object.keys(gummy)
    .filter((k) => {
      return (
        NAMES.includes(k) &&
        gummy[k as keyof Gummy] !== "" &&
        gummy[k as keyof Gummy] !== "-"
      );
    })
    .map((k) => {
      participants.push(k);
      return parseInt(gummy[k as keyof Gummy] + "");
    });

  const datasetItem = {
    label: brand + " " + type,
    data: data,
    backgroundColor: color,
    borderWidth: 0,
  };

  const title = `${brand} ${type}`.trim().replace("!", "");
  const imageName: string = `${getImageName(title)}.png`;
  const imageUrl: URL = IMAGES[imageName];

  return (
    <div className={classes.GummyCard}>
      <h5 className={classes.Title}>
        #{i + 1} {title}
      </h5>
      <div className={classes.Content}>
        <div>
          <div className={classes.Image}>
            {imageUrl && imageUrl !== undefined ? (
              <img
                src={imageUrl.toString()}
                alt={title}
                className={
                  gummy.keywords?.includes("nongummy") ? classes.NonGummy : ""
                }
              />
            ) : (
              <div className={classes.Placeholder}> image placeholder </div>
            )}

            {gummy.keywords?.includes("nongummy") && <NonGummyIcon />}
          </div>
          {average !== undefined &&
            !isNaN(average) &&
            median !== undefined &&
            !isNaN(average) &&
            deviation !== undefined &&
            !isNaN(deviation) && (
              <GummyStatsTable
                median={median}
                average={average}
                deviation={deviation}
              />
            )}
        </div>
        {/* Radar Chart */}
        {participants.length > 0 && datasetItem.data.length && (
          <div className="GummyRadarChart">
            <RadarChart
              labels={participants}
              datasets={[datasetItem]}
              key={Math.round(Math.random() * 10000)}
            />
          </div>
        )}
      </div>
      <div className={classes.Review}>
        <p>{gummy.review}</p>
      </div>
      <div className={classes.Tags}>
        {gummy.keywords &&
          gummy.keywords.map((tag, j) => {
            return <Tag tag={tag} key={`${i}-tag-${j}`} />;
          })}
      </div>
    </div>
  );
}

export function GummyStatsTable({
  average,
  median,
  deviation,
}: {
  average: string | number;
  median: string | number;
  deviation: string | number;
}) {
  return (
    <div className="GummyStatsTable">
      <table>
        <tr>
          <th>average</th>
          <td>{average}</td>
        </tr>
        <tr>
          <th>median</th>
          <td>{median}</td>
        </tr>
        <tr>
          <th>deviation</th>
          <td>{deviation}</td>
        </tr>
      </table>
    </div>
  );
}

export function NonGummyIcon() {
  return (
    <div className={classes.NonGummyIcon}>
      <p>not a gummy</p>
    </div>
  );
}

function Tag({ tag }: { tag: string }) {
  return <span className={classes.Tag}>{tag}</span>;
}

function getImageName(title: string) {
  const x = encodeURI(
    title
      .toLowerCase()
      .replace("’", "'")
      .replace("é", "e")
      .replace("ü", "u")
      .replace("ö", "o")
      .split(" ")
      .join("_")
  );
  return x;
}
