// const SHEET_ID = "1SQMDYMSJR6C3IwQqtc8wJ2ZVsig2qMwgcKvXs6j4goA";
// const API_KEY = "AIzaSyATWTPwl9VKUltMRA2QsieYzYW7Fxj77KQ";
// const RANGE = "A1:R154";
// const base = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${RANGE}?key=${API_KEY}`;

import data from "./data/data.json";

import * as reviews from "./data/reviews.json";

export function getData() {
  // WHen you want to do it live uncomment these lines
  // console.log(`Fetching data from ${base}`);
  // fetch(base)
  //   .then((res) => res.json())
  //   .then((data) => {
  //     processData(data);
  //   });
  return processData(data);
}

export type Gummy = {
  date?: Date;
  brand?: string;
  type?: string;
  // scores: r.slice(3, 13),
  zach?: string | number | undefined;
  keith?: string | number | undefined;
  kyle?: string | number | undefined;
  matthew?: string | number | undefined;
  jay?: string | number | undefined;
  hillary?: string | number | undefined;
  guest?: string | number | undefined;
  guestName?: string | undefined;
  average?: number;
  median?: number;
  deviation?: number;
  keywords?: string[];
  variants?: Variant[];
  element?: HTMLElement;
  review?: string;
  // review?: any;
};

export type Variant = {
  color?: string;
  flavor?: string;
  shape?: string;
  notes?: string[];
  overall?: boolean;
};

export type Header = {
  value?: string;
  element?: HTMLElement | undefined;
  sortElement?: HTMLElement | undefined;
};

export type Tag = {
  value: string;
  count: number;
};

// A: Date [0]
// B: Brand [1]
// C: Type [2]
// D-I - Coincidence ratings [3...9]
// J: Guest rating [10]
// K-M: Average, median, Deviation [0]
// N: Keywords [13]
// O: Color [14]
// P: Flavor (pairs with color) [15]
// Q :Shape (pairs with color if applicable) [16]
// Q: Notes (if in same row as brand/type/, [17]
//    then belongs to overall impressions, otherwise goes with color/flavor)
function processData(data: object) {
  function formatScore(value: string) {
    const parsed = parseInt(value);
    return isNaN(parsed) ? "" : parsed;
  }
  //@ts-ignore
  const rows = data.values;
  let headers: Header[] = [];
  const headerRow = rows[0];
  headerRow.forEach((h: string) => {
    if (h.toLowerCase() !== "keith") {
      headers.push({
        value: h.trim(),
      });
    }
  });

  const gummies: Gummy[] = [];
  let inGummy = false;
  let newGummy: Gummy = {};

  let tagsCount: { [key: string]: number } = {};

  for (let i = 1; i < rows.length; i++) {
    const r = rows[i];
    if (
      inGummy &&
      ((r[0] !== undefined && r[0] !== "") || i == rows.length - 1)
    ) {
      gummies.push(newGummy);
      inGummy = false;
    }

    // new gummy
    if (r[0] !== undefined && r[0] !== "" && !inGummy) {
      // console.log(i, r[9] !== "" ? rows[i + 1][9] : "");
      newGummy = {
        date: r[0],
        brand: r[1],
        type: r[2],
        // scores: r.slice(3, 13),
        zach: formatScore(r[3]),
        // keith: formatScore(r[4]),
        kyle: formatScore(r[5]),
        matthew: formatScore(r[6]),
        jay: formatScore(r[7]),
        hillary: formatScore(r[8]),
        guest: formatScore(r[9]),
        guestName: r[9] !== "" ? rows[i + 1][9] : "",
        average: r[10],
        median: r[11],
        deviation: r[12],
        keywords: r[13].split(",").map((el: string) => el.trim()),
        variants: [],
      };

      newGummy.keywords?.forEach((k) => {
        k = k.trim();
        if (k.length > 0) {
          if (tagsCount[k] === undefined) {
            tagsCount[k] = 0;
          }
          tagsCount[k] += 1;
        }
      });

      const scores = [r[3], ...r.slice(5, 10)]
        .map((v) => formatScore(v))
        .filter((v) => typeof v === "number");

      newGummy.average = average(scores as number[]);
      newGummy.median = median(scores as number[]);
      newGummy.deviation = standardDeviation(scores as number[]);

      const notes =
        r[17] !== undefined
          ? r[17].split(";").map((el: string) => el.trim())
          : "";

      const overall: Variant = {
        overall: true,
        notes: notes,
      };

      newGummy.variants?.push(overall);

      const review =
        reviews.length > 0
          ? reviews.find(
              (i) => i.title === `${newGummy.brand} ${newGummy.type}`
            )?.data
          : undefined;
      newGummy.review = review;
      inGummy = true;
    } else {
      const variant = {
        color: r[14],
        flavor: r[15],
        shape: r[16],
        notes:
          r[17] !== undefined
            ? r[17].split(";").map((el: string) => el.trim())
            : "",
      };

      newGummy.variants?.push(variant);
    }
  }

  let tags: Tag[] = Object.entries(tagsCount)
    .map((v) => {
      return {
        value: v[0],
        count: v[1],
      };
    })
    .sort((a, b) => b.count - a.count);

  return {
    headers,
    gummies,
    tags,
  };
}

function sum(arr: number[]) {
  let sum = 0;
  arr.forEach((v) => {
    if (typeof v === "number") {
      sum += v;
    }
  });
  return sum;
}

function average(arr: number[]) {
  return +(sum(arr) / arr.length).toFixed(1);
}

function median(arr: number[]) {
  const sorted = Array.from(arr).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

function standardDeviation(arr: number[]) {
  let mean =
    arr.reduce((acc, curr) => {
      return acc + curr;
    }, 0) / arr.length;

  arr = arr.map((el) => {
    return (el - mean) ** 2;
  });

  let total = arr.reduce((acc, curr) => acc + curr, 0);

  return +Math.sqrt(total / arr.length).toFixed(2);
}
